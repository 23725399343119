import React,{useState, useEffect} from "react";
import Config from "../../Config.json";
import { ThreeDots } from  'react-loader-spinner';
import { useNavigate } from "react-router";

const Preference = () => {
    const navigate = useNavigate();
    const [pageLoading, setPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [stage, setStage] = useState("Skip");
    const [msg, setMsg] = useState('')
    const [preferenceList, setPreferenceList] = useState([])
    const preference = [];
    
    const selectFeed = (item) =>{
      setStage("Finish")
      if(preference.includes(item)){
        preference.splice(preference.indexOf(item), 1);
        console.log(preference);
        return;
      }
      preference.push(item);
      console.log(preference);
    }
  
    const handleClick = (item) => {
      setLoading(true)
      setTimeout(() => {
        setLoading(false);
        navigate(`${item}`)
      }, 4000);
    }

  
    const addPreference = () => {
      setTimeout(() => {
  
        setLoading(true);
                  
      return fetch(`${Config.SERVER_URL}/users/add-interests`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem("token")
        },
          body: JSON.stringify({
            categoryIds:preference
          })
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if(responseJson.status ==="success"){
            setLoading(false);
            window.location.href="/user/Events";
          }
          if(responseJson.status ==="error"){
            setLoading(false);
            setMsg(responseJson.errors)
            console.error(responseJson.errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
  
      }, 2000);
    }
  
  
    const getPreferences = async()=>{
  
      setPageLoading(false);
      return fetch(`${Config.SERVER_URL}/categories`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":  sessionStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            setPreferenceList(responseJson.data.categories);
            setPageLoading(false);
              console.log(responseJson);
          })
          .catch((error) => {
            console.error(error);
            setPageLoading(false);
          });
  
  }
  
  useEffect(() =>{
       getPreferences();
  },[])
  








    return (
        <>




                        <div className="icons-div">

                        {pageLoading === true ?
                                <>
                                    <div className="text-center mt-5">
                                            
                                            <ThreeDots 
                                                height="60" 
                                                width="60" 
                                                radius="9"
                                                color="#003399" 
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                                                visible={true}/>
                                    </div>
                                </> 
                                : <>



                        <h4>Want Personalized Feeds?</h4>
                        <p className="text-c">Choose your interests and get personalized event feeds and suggestions.</p>

                        {msg !== "" ?   
                          <span style={{color:"#D42323"}}>
                          <strong>{msg}</strong> <br/>
                          </span> 
                                : null}

                        <div  id="checkboxes">

                            

                        {preferenceList.map(preference => {
                                return(<>

                                <input onClick={()=> selectFeed(preference._id)} type="checkbox" name={preference.name} value={preference._id} id={preference.name} />
                                <label key={preference._id} className="activeCard" for={preference.name}>{preference.name}</label>

                               </> )
                                })}
                            
                        </div>
                        {loading ===true ? 
                              <ThreeDots 
                                    height="60" 
                                    width="60" 
                                    radius="9"
                                    color="#003399" 
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                                    visible={true}/> :
                            <>
                            <div className="btn-div">
                            {/* <button className="light-btn" onClick={()=>handleClick("/welcome")}>Back</button> */}
                            {stage === "Finish" ? 
                                <button className="btn btn-primary float-end mt-4 btn-elrio" onClick={() => addPreference()}>Finish</button> :
                                <button className="btn btn-primary float-end mt-4 btn-elrio" onClick={() =>handleClick("/user/Events")}>{stage}</button>
                            }
                            
                            </div>
                            </>
                        }

                        </>}
                        </div>

                                
                                    
        </>
    );
};

export default Preference;