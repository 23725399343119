import React from 'react';
import Layout from './Layout';
import { Bar } from 'react-chartjs-2';



const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Pending',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(0, 51, 153, 1)',
        ],
        borderColor: [
          
        'rgba(0, 51, 153, 1)',
        ],
        borderWidth: 1,
      },
  
      {
        label: 'Occured',
        data: [7, 9, 13, 15, 8, 13],
        backgroundColor: [
          '#DFC9EF',
        ],
        borderColor: [
          '#DFC9EF'
        ],
        borderWidth: 1,
      }
    ],
  };
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  

const Analytics = () => {
    return (
        
        <Layout>
                
      <div className="bodyCarrier">
        
        <div className="container"> 
            <div className="row">
                <div className="col-lg-4">
                    <h1 className='title'>Analysis</h1>
                </div>
            </div>
        </div>
        {/* <div className="container mt-3"> */}
          <div className="row">
                    <div className="col-lg-6">
                        <div className='box-parent'>
                            <div className="box boxOne">
                            
                            <p >300</p>
                            <p className="view">Views</p>
                            
                            </div>
                            
                            <div className="box boxTwo">
                            <p>100</p>
                            <p className="view">Event</p>
                            </div>
                            
                            <div className="box boxThree">
                            <p>200</p>
                            <p className="view">Follower</p>
                            </div>

                        </div>

                    </div>
                  

                    <div className="col-lg-6">
                        <div className='colorbg'>
                            
                            <div className="col-12 mt-1">
                            <p className="allTime text-center">All time</p>
                            </div>
                                <div className="row">
                                <div className="col-4 text-center">
                                        <p className="Review">300</p>
                                        <p className="alignTextOne">Registered</p>
                                </div>
                                <div className="col-4 text-center">
                                            <p className="Review">250</p>
                                            <p className="alignTextOne">Attended</p>
                                </div>
                                <div className="col-4 text-center">
                                            <p className="Review">150</p>
                                            <p className="alignText">Reviews</p>
                                </div>
                                </div>
                            
                            </div>
                    </div>
          </div>
        {/* </div> */}
        <div className="mt-4">
           <div className="row">
            <div className="col-lg-6 ">
               {/* <div className="chart"> */}


                 <Bar className="chart" data={data} options={options} />
  
               {/* </div> */}
            </div>
            <div className="col-lg-6 ">




                <div className="chatBox">
                <h5>Reviews</h5>

                    <div className='chat'>
                        <div className='imgBox'>
                            <img src='../images/prof.png' className='img-fluid d-block'/>
                        </div>
                        <div className='textBox'>
                            <div className='heading'>
                                <h5>Emma Williams</h5>
                                <h6>6 minutes ago</h6>
                            </div>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</p>
                        </div>
                    </div>



                    <div className='chat'>
                        <div className='imgBox'>
                            <img src='../images/prof.png' className='img-fluid d-block'/>
                        </div>
                        <div className='textBox'>
                            <div className='heading'>
                                <h5>Emma Williams</h5>
                                <h6>6 minutes ago</h6>
                            </div>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</p>
                        </div>
                    </div>

                    <div className='chat'>
                        <div className='imgBox'>
                            <img src='../images/prof.png' className='img-fluid d-block'/>
                        </div>
                        <div className='textBox'>
                            <div className='heading'>
                                <h5>Emma Williams</h5>
                                <h6>6 minutes ago</h6>
                            </div>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</p>
                        </div>
                    </div>




  
                </div>
              
            </div>
           </div>
        </div>
  
        </div>
  


         </Layout>
    );
};

export default Analytics;