import React, {useState, useEffect} from 'react';
import Config from "../../../../Config.json";
import { ThreeDots } from  'react-loader-spinner';
import { AiFillDelete } from "react-icons/ai";
import {Modal, Row, Col } from 'react-bootstrap';
import { BsPencilSquare, BsFillCloudUploadFill } from "react-icons/bs";
import axios from "axios";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Swal from 'sweetalert2';

const Board = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [msg, setMsg] = useState("");
    const [id, setId] = useState();
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [typeContent, setTypeContent] = useState('');
    const [content, setContent] = useState('');

    const {data, setShowBoard} = props;
    const [boards, setBoards] = useState([]);
    const [loading, setLoading] = useState(true);

    const [isEdit, setIsEdit] = useState(true)

    const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
    );
    
    const [createdBoards, setCreatedBoards] = useState([]);


    const [pageLoading, setPageLoading] = useState(true);

   
    const _id = sessionStorage.getItem("id");

    const getBoards = async () => {


        return fetch(`${Config.SERVER_URL}/events/${data?._id}/boards`, {
            method: "get",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("token")
            },
           })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                // if(responseJson.status === "success") {
                    setBoards(responseJson?.eventBoards);
                // }
                setLoading(false)
            })
            .catch((error) => {
            console.error(error);
            setLoading(false)
            });

        
        }

    useEffect(()=>{
        // const interval = setInterval(()=>{
          getBoards();
        //  },3000);
        //  return () => clearInterval(interval);
    },[data])

        const editOpen = (board)=> {
            setId(board._id);
            setName(board.name);
            setType(board.type);
            setTypeContent(board.content);
            setContent(board.content);
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(board.content))));
            console.log(EditorState.createWithContent(convertFromRaw(JSON.parse(board.content))))
            setModalShow(true);
        }

          
    const config = {
        headers: { 
          'token': `${sessionStorage.getItem('token')}`, 
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`, 
          // 'Content-Type': 'application/json'
        },
      };
   

    const updateBoard =  async()=> {
          const data = editorState.getCurrentContent();
          var contentNew = JSON.stringify(convertToRaw(data));
          
            setLoading(true);
    
          const formData = new FormData()
          formData.append('name', name);
          formData.append("type", type);
          if(type !== "note") formData.append(type, typeContent);
          if(type === "note") formData.append('content', contentNew);
        
    
          await axios.put(`${Config.SERVER_URL}/events/${_id}/boards/${id}`, formData, config)
          .then((response) => {
            console.log(response);
            if(response.data.status === "success"){
                Swal.fire({
                  icon:'success',
                  text: 'Board update Successfully',
                  position:'top-right',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                })
                setMsg("");
                setLoading(false);
                getBoards();
                setModalShow(false);
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }
            if(response.data.status === "error"){
              setLoading(false);
              Swal.fire({
                icon:'error',
                text: response.data.error,
                position:'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              })
            }
          })
          .catch(err => {
            console.log(err)

            if(err){
              console.log(err.response.data.error[0]);
              // const {errors} = data;
              setMsg(`Error: ${err.response.data.errors}`)
              setLoading(false);
            }
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          });
        }

    const deleteBoard = (boardId) => {
        if (!window.confirm("are you sure you want to delete this event?")) {
          return
        }

        return fetch(`${Config.SERVER_URL}/events/${_id}/boards/${boardId}`, {
          method: "delete",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "token":  sessionStorage.getItem("token") 
              },
        })
          .then((response) => response.json())
          .then((responseJson) => {
              console.log(responseJson)
              if(responseJson.status === "success") {
                    getBoards();
                    Swal.fire({
                      icon:'success',
                      text: 'Board deleted Successfully',
                      position:'top-right',
                      showConfirmButton: false,
                      timer: 2000,
                      timerProgressBar: true,
                    })
                }
              if(responseJson.status === "error") {
                Swal.fire({
                  icon:'success',
                  text: 'Something went wrong please try again',
                  position:'top-right',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                })
                }
          })
          .catch((error) => {
              console.error(error);
          });
  
      }
  
    const createModal = () =>{
        setIsEdit(false);
        setModalShow(true)
    }
           
  const addBoard = async()=> {
    if(type === "note"){
      const data = editorState.getCurrentContent();
      var contentNew = JSON.stringify(convertToRaw(data));
    }

    let boardItem ={
      name: name,
      type: type,
      note:contentNew,
      dataContent: typeContent
    }
    createdBoards.push(boardItem);
  }

  const createBoard = async ()=>{
    setLoading(true);
    const formData = new FormData()
    formData.append('name', name);
    formData.append("type", type);
    if(type !== "note") formData.append(type, typeContent);
    if(type === "note") formData.append('content', 'contentNew');
  

    await axios.post(`${Config.SERVER_URL}/events/${id}/boards`, formData, config)
    .then((response) => {
      console.log(response);
      if(response.data.status === "success"){
        alert("Board Created Successfully.");
        setMsg("");
        setName('')
        setType('')
        setTypeContent('')
        setContent('')
        setLoading(false);
        setModalShow(false);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
      if(response.data.status === "error"){
        setLoading(false);
        alert(response.data.error);
      }
    })
    .catch(err => {
      console.log(err)
      if(err){
        console.log(err.response.data.error[0]);
        // const {errors} = data;
        setMsg(`Error: ${err.response.data.errors}`)
        setLoading(false);
      }
      
      setTimeout(() => {
        
        setLoading(false);
      }, 1000);
    });

  }



    return (<>
 
        {loading ?  <>
                  <div style={{height:"50vh"}}>
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>

            </>
        
          : 


    <div className="accordion accordion-flush" id="accordionFlushExample">



    {boards && boards.length > 0
                           ? boards.map((board, index) => {
                            // const contentState = convertFromRaw(JSON.parse(board.content));
                            // const editorState = EditorState.createWithContent(contentState);
           return(<>
           
                 <div className="accordion-item mb-4">
                   <h2 className="accordion-header" id={board._id}>
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls="flush-collapse">
                         {board.name}
                     </button>
                   </h2>
                   <div id={`flush-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={board._id} data-bs-parent="#accordionFlushExample">
                     <div className="accordion-body">

                        {board.type === "image" ?   
                        
                           <img  src={board.content}  className="img-fluid d-block eventImg"   alt="..."/> 
                        
                          : null}
                          {board.type === "note" ?   
                              <Editor 
                              toolbarHidden
                              editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(board.content)))} readOnly={true}/>
                          
                            : null}
                            {board.type === "document" ?   
                             <>
                             <iframe src={`https://docs.google.com/viewerng/viewer?url=${board.content}&embedded=true `} frameborder="0" height="300px" width="100%"></iframe>
                              {/* <DocViewer documents={board.content} /> */}
                             
                             </>
                            
                              : null}
                              {board.type === "audio" ?  <> 
                                    <audio controls>
                                        <source src={board.content} type="audio/mpeg"/>
                                         Your browser does not support the audio tag.
                                    </audio>
                              
                                    </>: null}
                                {board.type === "video" ?   
                                    <video width="100%" height="240" controls>
                                        <source src={board.content} type="video/mp4"/>
                                         Your browser does not support the video tag.
                                    </video>
                                
                                  : null}


                     </div>
                    <div className="">
                        <BsPencilSquare onClick={()=> editOpen(board)} className='delPenIcon' style={{color:"#003399"}}/>
                        <AiFillDelete onClick={()=> deleteBoard(board._id)} className='delPenIcon' style={{color:"#D42323"}}/>
                    </div>
                   </div>
                 </div>
               </>)
           })
               :
               <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="text-center">
                  <div className="chat">
                  <p>No board created yet.</p> 

                  <p>Boards are files, documents, images, & videos that you wish to share with your event attendees to enhance participation.</p>

                  <p>Start by Creating your board <span style={{color:"blue", cursor: "pointer"}} onClick={()=> setShowBoard(true)}>here.</span></p>
                  

                  {/* <span onClick={() => createModal()} className="createIcon">Create board</span> */}

                    
                  </div>
              </div>}


     </div>
          
          }
   

   <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <Row>
            
           <Col lg="1"/>
                <Col lg="10" style={{paddingBottom:"120px"}}>

                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {isEdit ?  "Update Board" : "Create Boar" } 
                    </Modal.Title>
                 </Modal.Header>

                    <div className="form-floating mt-3">
                        <input placeholder="Event title" type="text" className="h-auto form-control" id="floatingInput" name="name"
                        value={name} 
                        onChange={(e) => setName(e.target.value)}
                        />
                        <label for="floatingInput">Board Title</label>
                    </div>


                    <div className="form-floating mt-4 mb-4">
                      <select className="form-select" id="floatingSelect" aria-label="select category" name="type" onChange={(e)=> setType(e.target.value)}>
                          <option selected>Current Type: {type}</option>
                                <option value={"note"}>Note</option>
                                <option value={"image"}>Image</option>
                                <option value={"document"}>Document</option>
                                <option value={"audio"}>Audio</option>
                                <option value={"video"}>Video</option>
                          </select>
                        <label for="floatingSelect">Choose Event Category</label>
                    </div>
                
                {type === "image" &&

                  <div style={{marginTop:"20px", textAlign:'center'}}>
                    <div  id="event-banner" style={{backgroundColor:"#5d75b9", padding:"40px 20px", borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",}}>
                        <BsFillCloudUploadFill style={{fontSize:"25px", color:"#fff"}}/>
                        <p style={{fontSize:"18px", color:"#fff", fontWeight:"bold"}}>Browse File</p>

                        <input type="file" className="form-control" id="customFile" name="content" onChange={(e)=> setTypeContent(e.target.files[0])}/>
                    </div>  
                  </div>
                }   
                {type === "document" &&

                <div style={{marginTop:"20px", textAlign:'center'}}>
                  <div  id="event-banner" style={{backgroundColor:"#5d75b9", padding:"40px 20px", borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",}}>
                    <BsFillCloudUploadFill style={{fontSize:"25px", color:"#fff"}}/>
                    <p style={{fontSize:"18px", color:"#fff", fontWeight:"bold"}}>Browse File</p>

                    <input type="file" className="form-control" id="customFile" name="content" onChange={(e)=> setTypeContent(e.target.files[0])}/>
                  </div>  

                </div>  }
                {type === "video" &&

                <div style={{marginTop:"20px", textAlign:'center'}}>
                  <div  id="event-banner" style={{backgroundColor:"#5d75b9", padding:"40px 20px", borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",}}>
                    <BsFillCloudUploadFill style={{fontSize:"25px", color:"#fff"}}/>
                    <p style={{fontSize:"18px", color:"#fff", fontWeight:"bold"}}>Browse File</p>

                    <input type="file" className="form-control" id="customFile" name="content" onChange={(e)=> setTypeContent(e.target.files[0])}/>
                  </div>  

                </div>  }


                {type === "audio" &&

                <div style={{marginTop:"20px", textAlign:'center'}}>
                  <div  id="event-banner" style={{backgroundColor:"#5d75b9", padding:"40px 20px", borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center",}}>
                      <BsFillCloudUploadFill style={{fontSize:"25px", color:"#fff"}}/>
                      <p style={{fontSize:"18px", color:"#fff", fontWeight:"bold"}}>Browse File</p>

                      <input type="file" className="form-control" id="customFile" name="content" onChange={(e)=> setTypeContent(e.target.files[0])}/>
                  </div>  

                </div>  }

                {type === "note" &&<>
                            <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            editorClassName="editor-class" />

                         </> }

                        {msg !== "" ?   
                          <span style={{color:"#D42323"}}>
                              <strong>{msg}</strong> 
                          </span> 
                            : null}

                  {
                    createdBoards.length>0 && <>
                      <h6>Bord List</h6>
                      {createdBoards.map((board,index) =>{
                        return<tr key={index}>
                          <td>{board.name}</td>
                          <td>{board.type}</td>
                        </tr>
                      })}
                    </>
                  }   

                  <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                       {loading === false ? <> 
                          {isEdit ?   <>  
                               <button onClick={()=> updateBoard()} type="button" className="mt-4 float-end botton btn btn-primary btn-elrio btn-md" >update</button>
                               </> :  createBoard.length>0?
                               <button onClick={()=> createBoard()} type="button" className="mt-4 float-end botton btn btn-primary btn-elrio btn-md" >Create Board</button>
                               :<button onClick={()=> addBoard()} type="button" className="mt-4 float-end botton btn btn-primary btn-elrio btn-md" >Add Board</button>
                            
                              }
                              </>: <>

                                <ThreeDots 
                                    height="50" 
                                    width="50" 
                                    radius="9"
                                    color="#003399" 
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    visible={true}/>
                                    
                                </>}

                  </div>
                           

                        {/* </form> */}



                    
                </Col>
           <Col lg="1"/>
 



        </Row>
      </Modal.Body>
    </Modal>

     
            

        
   </> );
};

export default Board;