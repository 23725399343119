import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import { GiCancel } from "react-icons/gi";

const Sidebar = () => {
    const location = useLocation();

    const [btn, setBtn] = useState(false)

    const logout = ()=> {
        sessionStorage.clear("token");
        sessionStorage.clear("firstName");
        sessionStorage.clear("lastName");
        sessionStorage.clear("userId");
        window.location.href = "/"
    }

    const openNav = () =>{ 
        setBtn(!btn)
        document.getElementById('sidebar').style.left = "0";
        document.getElementById('toggleBtn').style.left = '30vw';
        if(window.innerWidth < 768){
            document.getElementById('toggleBtn').style.left = '50vw';
        }
       }
   
       const closeNav = () => { 
        setBtn(!btn)
        document.getElementById('sidebar').style.left = "-70vw";
        document.getElementById('toggleBtn').style.left = '0';
        }
    return (<>

     {btn === false ? 
        <span onClick={()=> openNav()} id="toggleBtn">
                  <HiMenuAlt3/>
               </span>
       : 
       <span onClick={()=> closeNav()} id="toggleBtn">
                 <GiCancel/>
              </span>
       }

        <div id="sidebar">

            <Link to="/user/Events" className='logo-top'>
                <img src='/images/logo.png' className={`${'img-fluid'} ${"logo"}`}/>
            </Link>
            
            <div className={"list-nav"}>
            <ul className="nav flex-column">
                <li className={"navItem"}>
                   <Link to="/user/Events" className={`${"navLink"} ${location.pathname === "/user/Events" && "activeNav"}`}>
                    <span className="menuIcon">
                        <i className="fas fa-th-large"></i>
                        </span>
                        <span className='menuText'>Events</span>
                     </Link>
                  </li>
                {/* <li className={"navItem"}>
                    <Link className={"navLink"} to="/user/Analytics">
                        <span className="menuIcon">
                        <i className="fas fa-chart-line"></i>
                        </span>
                            <span className='menuText'>Analytics</span>
                     </Link>
                </li> */}
                <li className={"navItem"}>
                    <Link to="/user/My-Event"   className={`${"navLink"} ${location.pathname === "/user/My-Event" && "activeNav"}`}>
                        <span className="menuIcon">
                        <i className="fas fa-calendar-day"></i>
                        </span>
                            <span className='menuText'>My Events</span>
                     </Link>
                </li>
                <li className={"navItem"}>
                    <Link to="/user/Profile" className={`${"navLink"} ${location.pathname === "/user/Profile" && "activeNav"}`}>
                            <span className="menuIcon">
                            <i className="fas fa-user"></i>
                                </span>
                                <span className='menuText'>Profile</span>
                    </Link>
                </li> 
                <li className={"navItem logout-li"}>
                    <Link className={"navLink"} onClick={()=> logout()}>
                        <span className="menuIcon">
                            <i className="fas fa-sign-out-alt mr-3"></i>
                        </span>
                        <span className='menuText'>Logout</span>
                    </Link>
                </li>
                </ul>

            </div>



          

        </div>
   </> );
};

export default Sidebar;