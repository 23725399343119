import React, { useEffect, useState}from 'react';
import Config from "../../../../Config.json";
import { ThreeDots } from  'react-loader-spinner'

const Participants = (props) => {
    const {data} = props;
    const [participant, setParticipant] = useState([]);
    const [loading, setLoading] = useState(true);
    const [profilePhotoUrl, setprofilePhotoUrl] = useState('');
    const [name, setName] = useState('')

    // const _id = sessionStorage.getItem("id");
    // console.log(data._id)

    const getParticipant = async () => {
        
        return fetch(`${Config.SERVER_URL}/events/${data._id}/attenders`, {
            method: "get",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("token")
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if(responseJson.status === "success") {
                    setParticipant(responseJson.data.events);
                    setprofilePhotoUrl(responseJson.data.events.userId.profilePhotoUrl);
                    setName(responseJson.data.events.userId.firstName + " " + responseJson.data.events.userId.lastName);
                }
                setLoading(false)
            })
            .catch((error) => {
            console.error(error);
            setLoading(false)
            });

        
        }


        useEffect(()=>{
            getParticipant();
        },[])


    return (
        <div>

{participant && participant.length > 0
                           ? participant.map((p, index) => {

           return( <>
            <div key={index} className='part-wrap'> 
                <img src={profilePhotoUrl === "" ? "../../../images/user.png" : profilePhotoUrl} className="img-fluid d-block part-img" alt="..."/>
                    <p className='part-name'>{name === ""  ? p?.name : name}</p>
            </div>

               </>)
           })
               :
               <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="text-center">
                       <div className="chat">
                           No Particpant Found
                       </div>
                   </div>}
            
        </div>
    );
};

export default Participants;