import AgoraRTC from "agora-rtc-sdk-ng";
import React, {useState, useEffect} from 'react';
import Board from './components/Board'
import Comments from './components/Comments';
import Participants from './components/Participants';
import { useParams } from "react-router-dom";
import {format} from 'date-fns';
import Config from "../../../Config.json";
import { ThreeDots } from  'react-loader-spinner';
import { AiFillAudio, AiFillHeart, AiFillStar } from "react-icons/ai";
import { IoPeopleCircle, IoExitSharp } from "react-icons/io5";
import { BsPencilSquare, BsFillCameraVideoOffFill, BsFillCameraVideoFill } from "react-icons/bs";
import { GoReport } from "react-icons/go";
import Note from './components/Note';
import Report from './components/Report';
import Review from './components/Review';
import { useNavigate, useLocation } from "react-router-dom";
import { useUnMount } from "../Host/streaming/utils/hooks";
import AgoraVideoPlayer from "../Host/streaming/VideoPlayer";
import Swal from 'sweetalert2';

import { BsArrowLeftCircleFill } from "react-icons/bs";


const client = AgoraRTC.createClient({
  mode: "live",
  codec: "vp8"
});
const DEFAULT_LATENCY = '1';
const latencyItems = [{
  label: 'Interactive Live Streaming Standard',
  key: "1"
}, {
  label: 'Interactive Live Streaming Premium',
  key: "2"
}];
let role = 'audience';
let audienceLatency = DEFAULT_LATENCY;

const Streaming = (props) => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const { data } = state;
  let { id, name } = useParams();

  console.log(data)

  const APP_Id = "0ec363408e7343a396b1d0a72c649777";
  const Token ="007eJxTYPjLOXHDotDzLBo5ofcdZkeXXvTizwy3u7Nm1+W66PPtauEKDAapycZmxiYGFqnmxibGicaWZkmGKQaJ5kbJZiaW5ubm2WG+aQ2BjAwHBMOYGRkgEMTnYkjNKcrMjy9JLS5hYAAAxCYgPQ==";
  const channelName = "elrio_test";
  

    const [page, setPage] = useState('board');


    const [pageLoading, setPageLoading] = useState(false);
    const [event, setEvent] = useState([]);

    // Player states
    const [audioOnly, setAudioOnly] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [report, setReport] = useState(false);
    const [isEventLiked, setIsEventLiked] = useState(false);
    const [isFollowed, setIsFollowed] = useState(false);
    const [streamError, setStreamError] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [joined, setJoined] = useState(false);
    const [videoTrack, setVideoTrack] = useState(null);
    const [audioTrack, setAudioTrack] = useState(null);
    const [remoteUsers, setRemoteUsers] = useState({});
    const [localUid, setLocalUid] = useState("");

    
    // useUnMount(() => {
    //   if (joined) {
    //     leave();
    //   }
    // });
    
  const subscribe = async (user, mediaType) => {
    await client.subscribe(user, mediaType);
    if (mediaType === "video") {
      // ...
    }
    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  };


  const handleUserPublished = async (user, mediaType) => {
    const id = user.uid;
    await subscribe(user, mediaType);
    setRemoteUsers(prev => ({
      ...prev,
      [id]: user
    }));
  };


  const handleUserUnpublished = (user, mediaType) => {
    if (mediaType === 'video') {
      const id = user.uid;
      setRemoteUsers(pre => {
        delete pre[id];
        return {
          ...pre
        };
      });
    }
  };
  
  const [appId, setAppId] = useState("c059eb20f7464f66b6eac51beccb9782");
  const [token, setToken] = useState(data?.token);
  const [channel, setChannel] = useState(data?.channelName);
  // const [UID, setUID] = useState(data?.uid);
  const [UID, setUID] = useState(1);
  // console.log(token, channel, UID )

  const myEvents = async () => {
    setPageLoading(true);
          
    return fetch(`${Config.SERVER_URL}/events/${id}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":   sessionStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if(responseJson.status === "success") {
              console.log("Events", responseJson.data.event)
               setEvent(responseJson.data.event);
               console.log(responseJson.data.event);
               setEvent(responseJson.data.event);
              //  setToken(responseJson?.data?.event?.token);
              //  setChannel(responseJson?.data?.event?.channelName);
              //  setUID(responseJson?.data?.event?.uid);
               if(responseJson?.data?.event?.isLive) {
                  await join();
                }else{
                  setStreamError(true)
                }
                setPageLoading(false)
            }
        })
        .catch((error) => {
          console.error(error);
        });

   
    }

  const join = async () => {
    try {
        setBtnLoading(true);
        client.setClientRole('audience', {
          level: Number(audienceLatency)
        });
        // Add event listeners to the client.
        client.on("user-published", handleUserPublished);
        client.on("user-unpublished", handleUserUnpublished);
        // Join a channel
          const uid = await client.join(appId, channel, token || null);
        setLocalUid(uid);
        setJoined(true);
        setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      setStreamError(true);
      console.error("error", error);
      Swal.fire({
        timer: 3000,
        text:"Something went wrong please try again",
        toast:true,
        position:"top-right",
        icon:"error",
        showConfirmButton:false,
        timerProgressBar: true,
      })
    }
  };

    const attend = async() => {

      return fetch(`${Config.SERVER_URL}/events/${id}/attended`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem("token") 
        }
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson);
            if(responseJson.status === "success"){ 
              Swal.fire({
                timer: 3000,
                text:"Event attended",
                toast:true,
                position:"top-right",
                icon:"success",
                showConfirmButton:false,
                timerProgressBar: true,
              })
            }
            if(responseJson.status === "error"){
              console.log(responseJson);
            }
        })
        .catch((error) => {
          console.error(error);
        });

    }


  const leave = async () => {
    if (!window.confirm("are you sure you want to exit this event?")) {
      return
    }
    try{
          setPageLoading(true);
          audioTrack?.close();
          setAudioTrack(null);
          videoTrack?.close();
          setVideoTrack(null);
          setRemoteUsers({});
          await client?.leave();
          setJoined(false);
          const msg = "You have successfully left the channel!";
          setPageLoading(false);
          Swal.fire({
            timer: 3000,
            text:msg,
            toast:true,
            position:"top-right",
            icon:"success",
            showConfirmButton:false,
            timerProgressBar: true,
          })
          // navigate(`/user/Events`);
  }catch (error) {
      setBtnLoading(false)
      // console.error(error.message);
      Swal.fire({
        timer: 3000,
        text:"Something went wrong please try again",
        toast:true,
        position:"top-right",
        icon:"error",
        showConfirmButton:false,
        timerProgressBar: true,
      })
    }
  };


  const [show, setShow] = useState(true);

  const handleClose = () => {
           setPage('board');
           setShow(true);
          }
  
  const handleShow = (page) => {
      setPage(page);
      setShow(false);
    }


    useEffect(() => {
        myEvents();
        // attend();
    },[])

      const likeEvent = async() => {

        setIsEventLiked(true);
        let method = "post";

        if(isEventLiked){
            method = "delete"
            setIsEventLiked(false);
        }

      return fetch(`${Config.SERVER_URL}/events/${id}/likers`, {
        method: method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":  sessionStorage.getItem("token") 
            },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            if(responseJson.status === "success") {
                if(method === "post"){
                    setIsEventLiked(true);
                    return
                }
                if(method === "delete"){
                    setIsEventLiked(false);
                    return
                }
            }
            if(responseJson.status === "error") {
                setIsEventLiked(false);
                console.log(responseJson.error);
            }
        })
        .catch((error) => {
            console.error(error);
        });

        }

      const followHost = async() => {


         if(!isFollowed){
              setIsFollowed(true);
              
              return fetch(`${Config.SERVER_URL}/users/${event.userId}/follow`, {
              method: "post",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "token":   sessionStorage.getItem("token") 
              }
              })
              .then((response) => response.json())
              .then((responseJson) => {
                  // alert(responseJson.status);
                  if(responseJson.status === "success"){
                      setIsFollowed(true);
                      // alert(responseJson.status);
                      return
                  }
                  if(responseJson.status === "error"){
                      setIsFollowed(false);
                      // alert(responseJson.error);
                      return
                  }
              })
              .catch((error) => {
                  setIsFollowed(false);
                  alert(error);
              });
              
            }else{

                    setIsFollowed(false);
                    
                    return fetch(`${Config.SERVER_URL}/users/${event.userId}/unfollow`, {
                    method: "delete",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "token":  sessionStorage.getItem("token") 
                    }
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        console.log(responseJson);
                        if(responseJson.status === "success"){
                            setIsFollowed(false);
                            console.log(responseJson.data.message);
                            return
                        }
                        if(responseJson.status === "error"){
                            setIsFollowed(true);
                            console.log(responseJson.data.message);
                            return
                        }
                    })
                    .catch((error) => {
                        setIsFollowed(true);
                        console.error(error);
                    });
                    

            }

        }

    return (
        
        <div className='streamContent'>

                    
      <div className="container mt-4"> 
        
        {pageLoading ? <>
                  <div style={{height:"50vh"}}>
                    <ThreeDots 
                      height="40" 
                      width="40" 
                      radius="10"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>
            </>

            :<>


            <div className="row">
                    <div className="col-lg-8">
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:"10px"}}>
                          <div style={{display:"flex", alignItems:"center"}}>
                            <BsArrowLeftCircleFill onClick={()=> navigate(`/user/Events`)} className='arrowIcon' style={{color:"#003399"}}/>
                            <h1 className='title'>{event.title}</h1>
                          </div>
                            <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                              {event.isLive ?<>
                                 <div className='live-span'>
                                   <span>live</span>
                                 </div>
                                 <div className='view-span'>
                                     <i className="fa-solid fa-eye"></i>
                                    <span> {event?.viewsCount}</span>
                                 </div> 
                                 </>: null}
                            </div>
                        </div>
                        </div>
                         <div className="col-lg-4"></div>
            
                      <div className="col-lg-12">
                              <div className='row'>
                                  <div className="col-lg-8">

              {(format(new Date(), 'yyyy-MM-dd') > event?.endDate) ?
                   <>
                   
                   <div className='videoArea'>
                            <img src={event?.displayImage} className="img-fluid block eventImg" alt="..."/>
                     </div>
                   </> 
                   
                   :
                    <> 
                    {!joined ?
                          <div className='videoArea d-flex w-100 justify-content-center align-items-center'>
                               <img src={event?.displayImage} className="img-fluid block eventImg" alt="..."/>

                  {event?.isLive && <>
                              {streamError && 
                                <span className="mt-4 p-2 activeSec" style={{cursor:"pointer", position:"absolute"}}  onClick={join}>Connection failed, try again </span>
                              }
                         </> }
                            </div>

                       :<>

                       <div className='videoArea'>
                              
                              {/* {audioOnly ?
                                  <img src={event?.displayImage} className="img-fluid block eventImg" alt="..."/>
                                :<> */}
                                  {/* add video here */}

                                {Object.keys(remoteUsers).length ? <div className="mt-10">
                                    {Object.keys(remoteUsers).map(id => <AgoraVideoPlayer videoTrack={remoteUsers[id]?.videoTrack} text={`uid: ${id}`} key={id}></AgoraVideoPlayer>)}
                                  </div> : null}

                                {/* </> } */}
        
                        </div>

                      {/* {event?.isLive && */}
                            <div className='controls'>

                              <div onClick={()=> setAudioOnly(!audioOnly)} className="controlItem">
                                  <div className={audioOnly ? "activeIconController" : "iconController"}>
                                    {audioOnly ?
                                      <BsFillCameraVideoOffFill className={audioOnly ? "activeControlIcon" : "controlIcon"}/>
                                        :
                                      <BsFillCameraVideoFill className={audioOnly ? "activeControlIcon" : "controlIcon"}/>
                                    }
                                  </div>
                                    {audioOnly ?
                                    <span className='iconText'>Audio</span>
                                        
                                        :
                                        <span className='iconText'>Video</span>

                                    }
                                </div>
                                <div onClick={likeEvent} className="controlItem">
                                  <div className={isEventLiked ? "activeIconController" : "iconController"}>
                                    <AiFillHeart className={isEventLiked ? "activeControlIcon" : "controlIcon"}/>
                                  </div>
                                    <span className='iconText'>Likes</span>
                                </div>
                                <div onClick={followHost} className="controlItem">
                                  <div className={isFollowed ? "activeIconController" : "iconController"}>
                                    <IoPeopleCircle className={isFollowed ? "activeControlIcon" : "controlIcon"}/>
                                  </div>
                                    <span className='iconText'>Follow host</span>
                                </div>

                              <div onClick={()=> handleShow('note')} className="controlItem">
                                    <div className="iconController">
                                      <BsPencilSquare className="controlIcon"/>
                                    </div>
                                      <span className='iconText'>Take Note</span>
                                </div>
                              <div onClick={()=> handleShow('review')} className="controlItem">
                                    <div className="iconController">
                                      <AiFillStar className="controlIcon"/>
                                    </div>
                                      <span className='iconText'>Review</span>
                                </div>
                              <div onClick={()=> handleShow('report')} className="controlItem">
                                    <div className={report ? "activeIconController" : "iconController"}>
                                      <GoReport className={report ? "activeControlIcon" : "controlIcon"}/>
                                    </div>
                                      <span className='iconText'>Report host</span>
                                </div>
                                <div onClick={leave} className="controlItem">
                                  <div className='iconController exitController'>
                                    <IoExitSharp className='controlIcon exitIcon'/>
                                  </div>
                                    <span className='exitText'>Exit</span>
                                </div>

                            </div>
                         

                        

                   </>
                   } 

                        </> }

                                  </div>




            
                        <div className="col-lg-4">
                              <div className='row'>
                                  <div className="col-lg-12">
            
                             <div className="sideMenu"> 
                            
                         {show ? 
                               <div className=''>                    
                                  <nav className='navlistParent gap-3'>
                                      <span className={(page === "board") ?"nav-list activeSec" : "nav-list inactiveSec"} onClick={()=> setPage('board')}>Host uploads</span>
                                      <span className={(page === "comments") ?"nav-list activeSec" : "nav-list inactiveSec"} onClick={()=> setPage('comments')}>Chat</span>
                                      <span className={(page === "participants") ?"nav-list activeSec" : "nav-list inactiveSec"}  onClick={()=> setPage('participants')}>Participants</span>
                                    </nav>
            
                                    <div className='pageNav'>
                                        
                                        {page === "board" ? <>
                                              <Board data={event}/>
                                           </> : null}
                                  
                                        {page === "comments" ? <>
                                               <Comments data={event}/>
                                        </> : null}
            
                                        {page === "participants" ? <>
                                              <Participants data={event}/>
                                                </> : null}
                                        
                                      </div>
                                </div>  

                                :

                                <div>
                                  {page === "note" ? <>

                         
                                  <nav className='navlistParent gap-3' style={{justifyContent:'space-between'}}>
                                      <span className={"nav-list activeSec"}>Take Note</span>
                                      <span className='xIcon' onClick={handleClose}>x</span>
                                  </nav>
                                    <Note data={event}/> 
                                   </> : null}

                                  {page === "review" ?<>
                                                   
                                    <nav className='navlistParent gap-3' style={{justifyContent:'space-between'}}>
                                        <span className={"nav-list activeSec"}>Review this event</span>
                                        <span className='xIcon' onClick={handleClose}>x</span>
                                    </nav>
                                    <Review data={event}/> </>: null}

                                  {page === "report" ?<>                           
                                      <nav className='navlistParent gap-3' style={{justifyContent:'space-between'}}>
                                          <span className={"nav-list activeSec"}>Report this event</span>
                                          <span className='xIcon' onClick={handleClose}>x</span>
                                      </nav> <Report id={event._id}/> </>: null}
                                   
                                  </div>  
                                } 
            
                                </div>
            
            
            
            
                                </div>
            
                                </div>
            
                        </div>
            












            
                                  <div className="col-lg-8">
                                      
                                    <div className='event-details'>

                                  {event?.isLive ? null :  <>
                                      <p>{event?.description}</p>
            
                                      <div className="BadagryDate gap-4">
                                          <div style={{display:"flex"}}>
                                                <i className="fa-solid fa-calendar-days"></i> 
                                                <p style={{marginLeft:"5px"}}>{format(new Date(startDate), 'dd MMMM yy')}</p>
                                          </div>
                                          <div style={{display:"flex"}}>
                                              <i className="fa-solid fa-clock"></i>
                                                <p style={{marginLeft:"5px"}}>{event?.startTime}</p>
                                          </div>
                                      </div>
                                   </>}
            
                                      <div className='engagemet'>
                                          <h4>Events Engagements</h4>
                                                    
                                                <div className='box-parent'>
                                                    <div className="box engBox">
                                                            <i className="fa-solid fa-heart"></i>
                                                            <div>
                                                            <p >{event?.likesCount}</p>
                                                            <p className="view">Likes</p>
                                                            </div>
                                                    </div>
                                                    
                                                    <div className="box engBox">
                                                            <i className="fa-solid fa-eye"></i>
                                                            <div>
                                                                <p>{event?.viewsCount}</p>
                                                                <p className="view">Views</p>
                                                            </div>
                                                    </div>
                                                    
                                                    <div className="box engBox">
                                                          <i className="fa-solid fa-comments"></i>
                                                        <div>
                                                          <p className="view">Comments</p>
                                                        </div>
                                                    </div>
            
                                                </div>
                                        </div>
            
                                          
                                      </div>
            
                                  </div>
                              </div>
                      </div>
            
            </div>
            
            </>}

      </div>

            
        </div>
    );
};

export default Streaming;