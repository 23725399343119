import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Config from "../../../../Config.json";
import { BsFillCloudUploadFill } from 'react-icons/bs';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor  } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EventBoards = (props) => {
  const { data } = props;
  const [inputs, setInputs] = useState([{ name: '', type: '', content: '' }]);
  const [imgfile, setImgfile] = useState('');
  const [editorState, setEditorState] = useState();
  const [selectedTypeContent, setSelectedTypeContent] = useState(null);

  const token = sessionStorage.getItem('token');

  const handleAddInput = () => {
    setInputs([...inputs, { name: '', type: 'note', content: '' }]);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    const newInputs = [...inputs];
    if (type === 'image') {
      setImgfile(URL.createObjectURL(file));
      newInputs[0].content = file; // Assuming first input is image
    } else {
      setSelectedTypeContent(file);
      newInputs[0].content = file; // Assuming first input is the file for other types
    }
    setInputs(newInputs);
  };

  const handleEditorChange = (index, newEditorState) => {
    const newInputs = [...inputs];
    newInputs[index].editorState = newEditorState;
    newInputs[index].content = JSON.stringify(convertToRaw(newEditorState.getCurrentContent())); // or use another method to extract content
    setInputs(newInputs);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('event_id', data?._id); // Assuming you have event ID in props

    inputs.forEach((input, index) => {
      formData.append(`boards[${index}][name]`, input.name);
      formData.append(`boards[${index}][type]`, input.type);
      if (input.type === 'note') {
        formData.append(`boards[${index}][content]`, input.content);
      } else {
        formData.append(`boards[${index}][content]`, input.content); // Assuming content is a file
      }
    });

    try {
      const response = await axios.post(
        `${Config.SERVER_URL}/events/${data?._id}/boards`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'x-access-token': token,
          },
        }
      );
      console.log("response", response);
      alert('Board created successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to submit the board.');
    }
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <div id="input-container">
          {inputs.map((input, index) => (
            <div key={index} className="mb-3">
              <div id='parentBoard'>
                <div id="newBoard">
                  <div className="col-12 form-floating mt-3">
                    <input 
                      placeholder="Event title" type="text" className="h-auto form-control" id="floatingInput"
                      name="name"
                      value={input.name}
                      onChange={(event) => handleInputChange(index, event)}/>
                    <label htmlFor="floatingInput">Board Title</label>
                  </div>

                  <div className="col-12 form-floating mt-4">
                    <select className="form-select"  
                      id="selectType" aria-label="select category" name="type"
                      value={input.type}
                      onChange={(event) => handleInputChange(index, event)}>
                      <option selected>Choose Content Type</option>
                      <option value={"note"}>Note</option>
                      <option value={"image"}>Image</option>
                      <option value={"document"}>Document</option>
                      <option value={"audio"}>Audio</option>
                      <option value={"video"}>Video</option>
                    </select>
                    <label htmlFor="selectType">Choose Event Category</label>
                  </div>

                  {input.type === 'image' && (
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      {imgfile === '' ? (
                        <div
                          id="event-banner"
                          style={{
                            backgroundColor: '#5d75b9',
                            padding: '40px 20px',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <BsFillCloudUploadFill style={{ fontSize: '25px', color: '#fff' }} />
                          <p style={{ fontSize: '18px', color: '#fff', fontWeight: 'bold' }}>
                            Browse File
                          </p>
                          <span
                            className="upload_icon"
                            onClick={() => document.getElementById(`imgFile-${index}`).click()}
                          >
                            Choose from Device
                          </span>
                          <input
                            type="file"
                            className="form-control d-none"
                            id={`imgFile-${index}`}
                            name="content"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, 'image')}
                          />
                        </div>
                      ) : (
                        <div>
                          <img width={200} src={imgfile} alt="" />
                          <i
                            className="fa fa-pencil-square-o editIcon ms-2"
                            aria-hidden="true"
                            onClick={() => document.getElementById(`imgFile-${index}`).click()}
                          ></i>
                        </div>
                      )}
                    </div>
                  )}
                  {input.type === 'document' && (
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <div
                        id="event-banner"
                        style={{
                          backgroundColor: '#5d75b9',
                          padding: '40px 20px',
                          borderRadius: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <BsFillCloudUploadFill style={{ fontSize: '25px', color: '#fff' }} />
                        <p style={{ fontSize: '18px', color: '#fff', fontWeight: 'bold' }}>
                          Browse File
                        </p>
                        <input
                          type="file"
                          className="form-control"
                          name="content"
                          accept=".xls,.xlsx,.docx,.pdf"
                          onChange={(e) => handleFileChange(e, 'document')}
                        />
                      </div>
                    </div>
                  )}
                  {input.type === 'video' && (
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <div
                        id="event-banner"
                        style={{
                          backgroundColor: '#5d75b9',
                          padding: '40px 20px',
                          borderRadius: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <BsFillCloudUploadFill style={{ fontSize: '25px', color: '#fff' }} />
                        <p style={{ fontSize: '18px', color: '#fff', fontWeight: 'bold' }}>
                          Browse File
                        </p>
                        <input
                          type="file"
                          className="form-control"
                          name="content"
                          accept="video/*"
                          onChange={(e) => handleFileChange(e, 'video')}
                        />
                      </div>
                    </div>
                  )}
                  {input.type === 'audio' && (
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <div
                        id="event-banner"
                        style={{
                          backgroundColor: '#5d75b9',
                          padding: '40px 20px',
                          borderRadius: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <BsFillCloudUploadFill style={{ fontSize: '25px', color: '#fff' }} />
                        <p style={{ fontSize: '18px', color: '#fff', fontWeight: 'bold' }}>
                          Browse File
                        </p>
                        <input
                          type="file"
                          className="form-control"
                          name="content"
                          accept="audio/*"
                          onChange={(e) => handleFileChange(e, 'audio')}
                        />
                      </div>
                    </div>
                  )}
                  {input.type === 'note' && (
                    <div className="mt-4 form-floating">
                      <Editor
                        editorState={input.editorState}
                        onEditorStateChange={(newEditorState) => handleEditorChange(index, newEditorState)}
                        editorClassName="editor-class"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center mb-3">
          <button type="button" className="btn btn-success btn-elrio-sec" onClick={handleAddInput}>+</button>
        </div>
        <div className="d-flex justify-content-center">
          <button type="submit" className="btn btn-primary btn-elrio float-end w-50 mt-2">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default EventBoards;
