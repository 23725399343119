import React from 'react';
import Nav from './components/Nav';
import Footer from './components/Footer';
import { Link } from "react-router-dom";
import styles from "./Style.module.css";

const WaitList = () => {
    return (<>
        <Nav/>

        <section className={styles.waitListContainer}>

           <div className={styles.co5}></div>


            <div className={styles.co3}>

            <label className={styles.field}>
                    <input className={styles.field__area} type="text" name="firstName" placeholder="First name"/>
                    <div className={styles.field__label}>
                            <span>
                                First Name
                            </span>
                    </div>
                </label>

            <label className={styles.field}>
                    <input className={styles.field__area} type="text" name="lastName" placeholder="Last name"/>
                    <div className={styles.field__label}>
                            <span>
                                Last name
                            </span>
                    </div>
                </label>

            <label className={styles.field}>
                    <input className={styles.field__area} type="email" name="email" placeholder="Write your email"/>
                    <div className={styles.field__label}>
                            <span>
                                Email
                            </span>
                    </div>
                </label>

            <label className={styles.field}>
                    <input className={styles.field__area} type="tel" name="phone" placeholder="Write your Phone Number"/>
                    <div className={styles.field__label}>
                            <span>
                                Phone Number
                            </span>
                    </div>
                </label>

            <label className={styles.field}>
                    <input className={styles.field__area} type="text" name="country" placeholder="What Country are you located"/>
                    <div className={styles.field__label}>
                            <span>
                            Country
                            </span>
                    </div>
                </label>

            <label className={styles.field}>
                    <input className={styles.field__area} type="text" name="city" placeholder="City of residence"/>
                    <div className={styles.field__label}>
                            <span>
                            City
                            </span>
                    </div>
                </label>


            <label className={styles.field}>
                    {/* <input className={styles.field__area} type="text" name="email" placeholder="Type here"/> */}
                     <textarea className={styles.field__area} placeholder="Leave a comment here" id="floatingTextarea2" style={{height: "100px", paddingTop:"25px"}}></textarea>
                    <div className={styles.field__label}>
                            <span>
                            What will you want to use ElRoi for?
                            </span>
                    </div>
                </label>


              <div style={{ margin: "10% 0"}}>
                    <p  className={styles.label}>
                    Do you want us to send you more information about ElRoi? 
                    </p>
                    <div className="form-check form-check-inline">
                         <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked/>
                    <label className="form-check-label" for="flexRadioDefault1">
                        Yes
                    </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label className="form-check-label" for="flexRadioDefault2">
                        No
                    </label>
                    </div>

                 </div>


            <label className={styles.field}>
                     <textarea className={styles.field__area} placeholder="Leave a comment here" id="floatingTextarea2" style={{height: "100px", paddingTop:"25px"}}></textarea>
                    <div className={styles.field__label}>
                            <span>
                            You may leave a comment or question for us (if any).
                            </span>
                    </div>
                </label>


{/* 
                <label className={styles.field}>
                                 
                    <select className={`${styles.field__area} ${"form-select"}`} aria-label="Default select example">
                        <option selected>Select menu</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                    
                    <div className={styles.field__label}>
                            <span>
                              How did hear About us?
                            </span>
                     </div>
                </label> */}

                <button type="submit" className={`${styles.btnOutlined} ${"btn"}`}>
                      <span>
                        Log in
                      </span>
                </button>






            </div>


            <div className={styles.co5}></div>




             
        </section>

            
        {/* <Footer/> */}
        </>);
};

export default WaitList;