
import React, {useState, useEffect} from 'react';
import Config from "../../../../Config.json";
import { ThreeDots } from  'react-loader-spinner'
import { RiEmotionHappyFill } from "react-icons/ri";
import { IoSend } from "react-icons/io5";
import {format} from 'date-fns';
import Picker from "emoji-picker-react";


const Comments = (props) => {
    const {data} = props;
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);

       const [message, setMessage] = useState("");

       const [showPicker, setShowPicker] = useState(false);
     
       const onEmojiClick = (event, emojiObject) => {
        console.log('Emoji Object:', emojiObject); 
        setMessage(prevMessage => prevMessage + emojiObject.emoji);
        setShowPicker(false);
      };

    const _id = sessionStorage.getItem("id");


  const sendMsg = (msg) =>{
    if(msg ===""){
        
      return;
    }
          
    return fetch(`${Config.SERVER_URL}/events/${data._id}/live-comments`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "token":  sessionStorage.getItem("token")
      },
        body: JSON.stringify({
          content:msg,
        })
    })
      .then((response) => response.json())
      .then((responseJson) => {
          console.log(responseJson.data);
          setMessage("");
      })
      .catch((error) => {
        console.error(error);
      });
    
  }


    const getComments = async () => {


        return fetch(`${Config.SERVER_URL}/events/${data._id}`, {
            method: "get",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("token")
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("comment", responseJson)
                if(responseJson.status === "success") {
                    setComments(responseJson.data.event.liveComments);
                }
                setLoading(false)
            })
            .catch((error) => {
            console.error(error);
            setLoading(false)
            });

        
        }


        useEffect(()=>{
            getComments();
        },[comments])


    return (<>


        {loading ?  <>
            <div style={{height:"50vh"}}>
              <ThreeDots 
                  height="80" 
                  width="80" 
                  radius="9"
                  color="#003399" 
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                  visible={true}/>
              </div>

      </>
  
    : <>
    <section style={{display:"flex", height:"100%", flexDirection:"column", justifyContent:"space-between"}}>


    <div className='msg-div'>
            {comments && comments.length > 0
                                ? comments.map((comment,index) =>{
                return(<>
                
                <div key={index} className="msg-wrap">
                     <img src={comment.userId.profilePhotoUrl} className="img-fluid d-block rounded-circle chat-image" alt="..."/>
                        <div style={{width:"80%"}}>
                                <p className='chat-name'>{comment.userId.firstName} {comment.userId.lastName}</p>
                                <div className="chat-msg">
                                        <p className="message-list">   
                                        {comment.content}
                                        </p>
                                    </div>
                                <p className='chat-time'>{format(new Date(comment.createdAt), 'hh:mm')}</p>  
                                </div>
                    </div>
                    </>)
                })
                    :
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="text-center">
                            <div className="chat">
                                No Comment yet
                            </div>
                        </div>}
   </div>

   <div className="send-div">
            <div className="m-border">

        {showPicker && (
          <Picker pickerStyle={{ width: "50%" }} onEmojiClick={onEmojiClick} />
        )}
                    <form className="chat-div" style={{display:"flex",justifyContent:"space-around", alignItems: "center", backgroundColor:"#fff", paddingTop:'2%', paddingBottom:'2%', paddingLeft:'2%', paddingRight:'2%'}} onSubmit={(e) =>e.preventDefault()}>

                        <RiEmotionHappyFill className="icon" style={{fontSize:'25px', width:'10%'}} onClick={() => setShowPicker((val) => !val)}/>

                        <input type="text" value={message} onChange={(e) =>setMessage(e.target.value)} 
                        placeholder="Type here" disabled={message.length ===100?true:false} 
                        className="msg" style={{width:'80%'}}
                        />
                        <button type="submit" className="sendBtn" onClick={()=>{sendMsg(message)}} style={{width:'10%'}}
                        ><IoSend  style={{color:"#003399", fontSize:'25px'}}/></button>
                    </form>
            </div>
            <div className="msg-limit">
                <span>{message.length}/100</span>
            </div>
        </div>


        </section>

  
   </> }




</> );
};

export default Comments;