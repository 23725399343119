import React, {useState, useEffect} from 'react';
import Layout  from './Layout';
import { Offcanvas, Row, Col} from 'react-bootstrap';
import { BsFillCloudUploadFill, BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import Axios from "axios";
import Config from "../../Config.json";
import EventCard from "../User/Host/EventCard";
import { ThreeDots } from  'react-loader-spinner';
import {format} from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Swal from 'sweetalert2';



const MyEvent = () => {
  let navigate = useNavigate();
  const [page, setPage] = useState('Published');

  const [disableBtn, setDisableBtn] = useState(false);
  const [msg, setMsg] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [displayImage, setDisplayImage] = useState('');
  const [eventBg, setEventBg] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);
  const [limit, setLimit] = useState();
  const [isPassword, setIsPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [seePass, setSeePass] = useState(false);

  const today = format(new Date(), 'yyyy-MM-dd');

  const [cat, setCat] = useState([]);
  const [events, setEvents] = useState([]);

  const [startTime, setStartTime] = useState(new Date(Date.now()));
  const [endTime, setEndTime] = useState(new Date(Date.now()));

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const offCanvasClose = () => setShowOffcanvas(false);
  const offCanvasShow = () => setShowOffcanvas(true);

  const setPasswordState = (value) => {
        if(value === true) return setIsPassword(true);
        if(value === false) return setIsPassword(false);
    }

    const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
    );

     
    const config = {
      headers: { 
        'token': `${sessionStorage.getItem('token')}`, 
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      },
    };

    const handleBgUpload = (e) =>{
      const file = e.target.files[0];
      if (file) {
        setDisplayImage(e.target.files[0]);
        setEventBg(URL.createObjectURL(e.target.files[0]));
        console.log(eventBg);
      } else {
        console.log("No file selected or file selection was cancelled.");
      }
    }

    const publishEvent = async() => {

      const data = editorState.getCurrentContent();
      var contentNew = JSON.stringify(convertToRaw(data));
    
      setMsg("");
       
      setDisableBtn(true);

      if(title === ""){
        Swal.fire({
          timer: 3000,
          text:"Your event must have a title.",
          toast:true,
          position:"top-right",
          icon:"error",
          showConfirmButton:false,
          timerProgressBar: true,
          
        });
        // setMsg(`Error: Your event must have a title.`);
        setDisableBtn(false);
        return;
      }
      if(displayImage === ""){
        // setMsg(`Error:Upload image for your event. `);
        Swal.fire({
          timer: 3000,
          text:"Upload image for your event.",
          toast:true,
          position:"top-right",
          icon:"error",
          showConfirmButton:false,
          timerProgressBar: true,
          
        });
        setDisableBtn(false);
        return;
      }
      if(contentNew === ""){
        // setMsg(`Error: Your event must have a description.`);
        Swal.fire({
          timer: 3000,
          text:"Event Description is required.",
          toast:true,
          position:"top-right",
          icon:"error",
          showConfirmButton:false,
          timerProgressBar: true,
          
        });
        setDisableBtn(false);
        return;
      }
      if(categoryId === ""){
        Swal.fire({
          timer: 3000,
          text:"Select a category for your event.",
          toast:true,
          position:"top-right",
          icon:"error",
          showConfirmButton:false,
          timerProgressBar: true,
          
        });
        setDisableBtn(false);
        return;
      }
      if(startDate === ""){
        Swal.fire({
          timer: 3000,
          text:"Start Date should be earlier than it's End Date.",
          toast:true,
          position:"top-right",
          icon:"error",
          showConfirmButton:false,
          timerProgressBar: true,
          
        });
        setDisableBtn(false);
        return;
      }
      if(endDate === ""){
        Swal.fire({
          timer: 3000,
          text:"End Date should be earlier than it's End Date.",
          toast:true,
          position:"top-right",
          icon:"error",
          showConfirmButton:false,
          timerProgressBar: true,
          
        });
        setDisableBtn(false);
        return;
      }
      if(isPassword && password === ""){
        Swal.fire({
          timer: 3000,
          text:"Event Password is required",
          toast:true,
          position:"top-right",
          icon:"error",
          showConfirmButton:false,
          timerProgressBar: true,
          
        });
        setDisableBtn(false);
        return;
      }
      if(desc ===""){
        Swal.fire({
          timer: 3000,
          text:"Event Description is required.",
          toast:true,
          position:"top-right",
          icon:"error",
          showConfirmButton:false,
          timerProgressBar: true,
          
        });
        setDisableBtn(false);
        return;
      }
      // setDisableBtn(true);

     const loc = {
        location: {
          coordinates: [
            30,
            40.0
          ]
        },
       }

        const formData = new FormData();
        formData.append('displayImage', displayImage);
        formData.append('title', title);
        formData.append('description', desc);
        formData.append('categoryId', categoryId);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        formData.append('isPublished', true);
        formData.append('startTime', startTime);
        formData.append('endTime', endTime);
        formData.append('requirePassword', isPassword);
       
        if(isPassword){
           formData.append('password', password);
          }
      

    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }


     return await Axios.post(`${Config.SERVER_URL}/events/publish`, formData, config)
      .then((response) => {
        console.log(response);
            if(response.data.status === "success"){
                
                setTitle("");
                setDesc("");
                setDisplayImage("");
                setStartDate(new Date());
                setEndDate("");
                setLimit();
                setIsPassword(false);
                setPassword('');
                setCategoryId('');
                setBtnLoading(false);
                setDisableBtn(false);
                
                Swal.fire({
                  timer: 3000,
                  text:"Event uploaded successfully",
                  toast:true,
                  position:"top-right",
                  icon:"success",
                  showConfirmButton:false,
                  timerProgressBar: true,
                })
                console.log("event data")
                navigate(`/user/Details/${response.data.data.event.title}/${response.data.data.event._id}`, { state: { data: response.data.data.event} });

              }
      })
      .catch(err => {
        console.log(err)
        if(err){
          console.log(err.response.data.message);
          Swal.fire({
            timer: 3000,
            text:`An error occurred. Please try again`,
            toast:true,
            position:"top-right",
            icon:"error",
            showConfirmButton:false,
            timerProgressBar: true,
          })

          setDisableBtn(false);
        }
        
        setTimeout(() => {
          setDisableBtn(false);
        }, 1000);
      });



    }

    const saveEvent = async() =>{
        setMsg("");
        setDisableBtn(true);
        if(title === ""){
          Swal.fire({
            icon: 'error',
            text: 'Your event must have a title.',
            toast:true,
            timer: 3000
          });
          // setMsg(`Error: Your event must have a title.`);
          
        setDisableBtn(true);
          return;
        }
          const formData = new FormData()
          formData.append('displayImage', displayImage);
          formData.append('title', title);
          formData.append('description', desc);
          formData.append('categoryId', categoryId);
          formData.append('startDate', startDate);
          formData.append('endDate', endDate);
          formData.append('startTime', startTime);
          formData.append('endTime', endTime);
          // if(limit !== ""){
          //   formData.append('attendanceLimit', limit);
          // }
          formData.append('isPublished', false);
          formData.append('requirePassword', isPassword);
          if(isPassword){
            formData.append('password', password);
            }

      return await Axios.post(`${Config.SERVER_URL}/events`, formData, config)
        .then((response) => {
          console.log(response);
              if(response.data.status === "success"){

                  setTitle("");
                  setDesc("");
                  setDisplayImage("");
                  setStartDate(new Date());
                  setEndDate(startDate);
                  setLimit();
                  setIsPassword(false);
                  setPassword('');
                  setCategoryId('');
                  setDisableBtn(false);
                  Swal.fire({
                    icon:'success',
                    text: 'Event Saved successfully',
                    toast:true,
                    timer: 2500
                  });

                console.log("event data")
                navigate(`/user/Details/${response.data.data.event.title}/${response.data.data.event._id}`, { state: { data: response.data.data.event} });

                
                }
        })
        .catch(err => {
          console.log(err)
          setDisableBtn(false)
          if(err){
            // const {data} = err.response.data;
            console.log(err.response.data.error[0]);
            // const {errors} = data;
          Swal.fire({
            timer: 3000,
            text:err.response.data.errors,
            toast:true,
            position:"top-right",
            icon:"error",
            showConfirmButton:false,
            timerProgressBar: true,
            
          });
            // setMsg(`Error: ${err.response.data.errors}`)
          }
          
          setTimeout(() => {
            
            setBtnLoading(false);
          }, 1000);
        });
      }

    const renderPage = (status) => {
      
      if(status === 'Published'){
        myEvents();
        setPage('Published');
        return
      }
        pendingEvents();
        setPage('Pending');
    }

     
    const myEvents = async () => {

        const userId = sessionStorage.getItem('userId');
            
      return fetch(`${Config.SERVER_URL}/events?userId=${userId}&isPublished=${true}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":  sessionStorage.getItem("token") 
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson);
              if(responseJson.status === "success") {
                  setEvents(responseJson.data.events);
                }
                setPageLoading(false);
            })
            .catch((error) => {
              console.error(error);
              setPageLoading(false)
            });

     
    }

      
    const pendingEvents = async () => {

      const userId = sessionStorage.getItem('userId');
          
    return fetch(`${Config.SERVER_URL}/events?userId=${userId}&isPublished=${false}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem("token") 
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
            if(responseJson.status === "success") {
                setEvents(responseJson.data.events);
              }
              setPageLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setPageLoading(false)
          });

   
  }

    useEffect(() => {
        myEvents();
    },[limit])

  
  const getPreferences = async() => {

    return fetch(`${Config.SERVER_URL}/categories`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem("token") 
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson,"category fetched successfully");
            setCat(responseJson.data.categories);
        })
        .catch((error) => {
          console.error(error);
        });

    }


    useEffect(() =>{
        getPreferences();
    },[])


    return (

        <Layout>
           
      <div className="container"> 
          <div className="row">
              <div className="col-lg-5">
                  <h1 className='title'>My Events</h1>
              </div>
              <div className="col-lg-3">
                  {/* <input type="search" className="search" placeholder="search for event"/> */}
              </div>
              <div className="col-lg-3">
                    <button className="btn btn-primary btn-elrio btn-sm"  onClick={offCanvasShow}>Create New Event</button>
              </div>
          </div>

      </div>

      <div className="col-lg-12">
        <div style={{display:"flex"}} className='myEventNav'>

            <nav className='navlistParent gap-4'>
                <span className={page === "Published" ? "nav-list activeList" : "nav-list" }  onClick={()=> renderPage('Published')}>Published events</span>
                <span className={page === "Pending" ? "nav-list activeList" : "nav-list" }  onClick={()=> renderPage('Pending')} href="#">Draft/Saved events</span>
                {/* <span className="nav-list" href="#">Saved</span> */}
            </nav>

          </div>




          {pageLoading === true ?
            <>
              <div style={{height:"50vh"}}>
                <ThreeDots 
                    height="40" 
                    width="40" 
                    radius="10"
                    color="#003399" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                    visible={true}/>
                </div>
            </> 
            : <>
          
                <div className="row">

                      <EventCard  data={events}/>
                    
                  </div>

                  </>
                }
        
      </div>



      <Offcanvas show={showOffcanvas} onHide={offCanvasClose} placement={'end'}>
              <Offcanvas.Header style={{padding:"2% 10%"}} closeButton>
                <Offcanvas.Title>Create event</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>

              {btnLoading === true &&
                <>
                <div className="loading">
                  
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="10"
                        color="#fff" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>

                    </div>
                </> 
                   }
              

              <Row>

                  <Col lg="1"/>
                  <Col lg="10" style={{paddingBottom:"120px"}}>


                  <div style={{ marginTop: "20px", textAlign: 'center' }}>
      <div className={displayImage === "" ? "d-none" : "eventbg_image"}>
        {displayImage && (
          <img src={URL.createObjectURL(displayImage)} alt="event banner" />
        )}
        <i
          className="edit_icon fas fa-edit fa-2x"
          onClick={() => { document.getElementById('customFile').click() }}
        ></i>
      </div>

      <div
        id="event-banner"
        className={displayImage === "" ? '' : "d-none"}
        style={{
          backgroundColor: "rgb(92 92 92)",
          padding: "40px 20px",
          height: "300px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={() => document.getElementById('customFile').click()}
      >
        <BsFillCloudUploadFill style={{ fontSize: "25px", color: "#fff" }} />
        <p style={{ fontSize: "18px", color: "#fff", fontWeight: "bold" }}>Add your image</p>

        <input
          type="file"
          accept="image/png, image/jpeg"
          className="d-none"
          id="customFile"
          name="displayImage"
          onChange={(e) => handleBgUpload(e)}
          style={{ display: 'none' }} // Ensure the input is hidden
        />
      </div>
    </div>




                <div className="row g-2 mt-4">

                <div className="col">
                    <div className="form-floating mt-3">
                        <input placeholder="Event title" type="text" className="h-auto form-control" id="floatingInput" name="name"
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Event Title</label>
                      </div>
                  </div>

                  <div className="col">

                    <div className="form-floating mt-3">
                                <select className="form-select" id="floatingSelect" aria-label="select category" name="category" onChange={(e)=> setCategoryId(e.target.value)}>
                                    <option value="0">Choose Event Category</option>
                                    {cat.map((c,index) => (
                                          <option key={index} value={c._id}>{c.name}</option>
                                        ))}
                                    </select>
                                  <label htmlFor="floatingSelect">Choose Event Category</label>
                              </div>
                      </div>
                          
                              
                      </div>


                     <div className="mt-4 form-floating">

                     <div className="col-12">
                     {/* <label for="floatingTextarea2">Event Description</label> */}
                     </div>
                     {/* <Editor
                          toolbarHidden
                          editorState={editorState}
                          onEditorStateChange={setEditorState}
                          editorClassName="editor-class" /> */}

                        <textarea className="form-control" placeholder="let your participants know why they should attend" id="floatingTextarea2" name="description"  style={{height: "200px"}} value={desc} onChange={(e) => setDesc(e.target.value)}/> 
                        <label htmlFor="floatingTextarea2">Event Description</label>

                       </div>


                    <div className="row g-2 mt-4">
                        <h5>Event Schedule</h5>
                        <p>Schedule date and time of your event.</p>
                          <div className="col">
                            <div className="form-floating mt-3">
                                <input placeholder="Start Date" type="date" className="h-auto form-control" id="floatingInput" name="startDate"
                                value={startDate} 
                                min={today}
                                onChange={(e) => setStartDate(e.target.value)}
                                />
                                <label htmlFor="floatingInput">Start Date</label>
                              </div>
                            </div>
                          <div className="col">
                            <div className="form-floating mt-3">
                                <input placeholder="End Date" type="date" className="h-auto form-control" id="floatingInput" name="endDate"
                                value={endDate} 
                                min={startDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                />
                                <label htmlFor="floatingInput">End Date</label>
                              </div>

                            </div>

                      </div>

                      <div className="row g-2 mt-4">

                      <div className="col">
                            <div className="form-floating mt-3">
                                <input placeholder="Start Time" type="time" className="h-auto form-control" id="floatingInput" name="startTime"
                                value={startTime} 
                                onChange={(e) => setStartTime(e.target.value)}
                                />
                                <label htmlFor="floatingInput">Start Time</label>
                              </div>
                            </div>

                            <div className="col">
                                  <div className="form-floating mt-3">
                                      <input placeholder="End Time" type="time" className="h-auto form-control" id="floatingInput" name="endtime"
                                      value={endTime} 
                                      onChange={(e) => setEndTime(e.target.value)}
                                      />
                                      <label htmlFor="floatingInput">End Time</label>
                                    </div>
                                  </div>

                        </div>

                        <div className="row g-2 mt-4">

                            <div className="col">
                              <p>Will participants require password to attend your event? </p>

                              <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio1" onClick={()=> setPasswordState(true)}/>
                                  <label className="form-check-label" htmlFor="radio1">
                                  Yes
                                  </label>
                              </div>
                              <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio2" onClick={()=> setPasswordState(false)}/>
                                  <label className="form-check-label" for="radio2">
                                 No
                                  </label>
                             </div>


                              {isPassword ?<>
                                <div className="input-group mb-3">
                                      <div className="form-floating">
                                          <input placeholder="E.g 2000" type={seePass ? "text" : "password"} className="h-auto form-control" id="floatingInput" name="password"
                                          value={password} onChange={(e) => setPassword(e.target.value)}/>
                                          <label htmlFor="floatingInput">Event Password</label>
                                        </div>
                                      <span onClick={()=> setSeePass(!seePass)} className="input-group-text" id="basic-addon1">
                                        {seePass ? <BsEyeFill style={{fontSize:"18px", color:"#003399"}}/> : <BsEyeSlashFill style={{fontSize:"18px", color:"#003399"}}/>}
                                      </span>
                                  </div>

                                </>: null}


                               </div>

                          </div>
                            
                          {msg !== "" ?   
                          <span style={{color:"#D42323"}}>
                          <strong>{msg}</strong> <br/>
                              <strong>{isPassword}</strong> 
                          </span> 
                                : null}



                        <div className="row g-2 mt-4">
                            <div className="col"> 
                            {disableBtn ?             
                                <ThreeDots 
                                height="40" 
                                width="40" 
                                radius="10"
                                color="#003399" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center"}}
                                visible={true}/>
                                  :    
                                <button type="submit" onClick={() => saveEvent()} className='btn btn-outline-primary  float-start' disabled={disableBtn}>Save Event</button>
                                }
                            </div>
                            <div className="col"> 
                            {disableBtn ?             
                                <ThreeDots 
                                height="40" 
                                width="40" 
                                radius="10"
                                color="#003399" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-end", alignItems:"center"}}
                                visible={true}/>
                                  :        
                                <button type="submit" className='btn btn-primary btn-elrio float-end' onClick={() => publishEvent()} disabled={disableBtn}>Publish Event</button>
                            }
                            </div>
                        </div>


                                                            
                    </Col>  
                    <Col lg="1"/>
                </Row>

{/* 
                </>
                   } */}

                
            </Offcanvas.Body>
        </Offcanvas>


        
        </Layout>
    );
};

export default MyEvent;