import React from 'react';
import Nav from './components/Nav';
import Footer from './components/Footer';
import styles from "./Style.module.css";
import { HiCheckCircle } from "react-icons/hi";

const Solutions = () => {
    return (
        <>
        <Nav/>


        <section  className={styles.container}>


        <div className={styles.solContain}>

                <div className={styles.solOne}>            
                        <h4 className={`${styles.digital} ${"wow fadeInUp"}`}  data-wow-duration=".6s">ElRoi 
                        <br/> 
                        FEATURES
                        </h4>

                  </div>


                <div className={styles.solTwo}>
                        {/* <p className={`${styles.abouthero_text} ${"wow fadeInUp"}`}  data-wow-duration="1.2s">rftyguhij de55fr6tg7yh8uj9inbxecrvt bn 4df6vt7yb8un n57exxcr6 tvhuij 5excr6tv7 huij 75 6uh x5erc6tv7yui 5rrc6tv ybhunij xe5cr6tyu n 5bu njm </p> */}

                        <ul className={styles.liParent}>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="1s"><HiCheckCircle style={{color:"#003399"}}/> Chat room.</li>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="1.2s"><HiCheckCircle style={{color:"#003399"}}/> Invite friends to join, like or follow a certain event/host.</li>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="1.4s"><HiCheckCircle style={{color:"#003399"}}/> Customer reviews.</li>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="1.6s"><HiCheckCircle style={{color:"#003399"}}/> Video Live-streaming.</li>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="1.8s"><HiCheckCircle style={{color:"#003399"}}/> Audio Live-streaming.</li>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="2s"><HiCheckCircle style={{color:"#003399"}}/> Document upload.</li>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="2.2s"><HiCheckCircle style={{color:"#003399"}}/> Make payment to hosts/admin using different payment options</li>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="2.4s"><HiCheckCircle style={{color:"#003399"}}/> Switch from being an end user to a host</li>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="2.6s"><HiCheckCircle style={{color:"#003399"}}/> Leave a secret review to the event’s host about the event.</li>
                            <li className={`${"d-block wow fadeInUp"}`} data-wow-duration="2.8s"><HiCheckCircle style={{color:"#003399"}}/> Search, create, join and register for events. </li>
                        </ul>
                                    
                 </div>


            </div>







         </section>






         <Footer/>





            
        </>
    );
};

export default Solutions;