import React, {useState, useEffect} from "react";
import styles from "./Sign.module.css"
import {useForm} from "react-hook-form";
import { Link } from "react-router-dom";
import Axios from "axios";
import Config from "../../Config.json"
import { ThreeDots } from  'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import GoToTop from "../GoToTop";
import SocialAuth from "./SocialAuth";

const LogIn = () => {

    let navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [seePass, setSeePass] = useState(false);

    
    const onSubmit = (data) => {
                
            setErrorMsg('');
            let details={
                email:data.email, 
                password:data.password
             };
            setBtnDisabled(true);
            setTimeout(() => {
            
            return Axios.post(`${Config.SERVER_URL}/auth/login`, details)
                .then(async (res) => {
                console.log(res.data);
                if(res.data){
                    sessionStorage.clear();
                    const {data} = await res.data;
                    sessionStorage.setItem("token", data.token);
                    sessionStorage.setItem("userDetails", JSON.stringify(data));
                    sessionStorage.setItem("firstName",`${data.user.firstName}`)
                    sessionStorage.setItem("lastName",`${data.user.lastName}`)
                    sessionStorage.setItem("userId",`${data.user._id}`)
                    navigate("/user/Events");
                }
                setBtnDisabled(false);
                })
                .catch(err =>{
                    console.log(err.message);
                    setErrorMsg("Invalid Email or Password.");
                    setBtnDisabled(false);
                })
                
            }, 2000);
    };

    const handleForgetPassword =() => navigate(`/forgot-password`);


    return(

        <>
            
   
                    <section className={styles.loginContainer}>

                    <div className={styles.co5}></div>


                    <div className={styles.co3}>

                            <Link to="/" className={styles.imgDiv}>
                               <img src="images/logo.png" width="80" className="d-inline-block align-top" alt=""/>  
                            </Link>

                            <div className="loginBack mt-4">
                                <h3 className={styles.welText}>Welcome back,<br/> Login to continue</h3>
                            </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                                {errorMsg &&  
                                <span className={styles.formAlert}>
                                    <strong>{errorMsg}</strong> 
                                </span>
                                }
                                    <div>
                                        {errors.email && <span className={styles.formAlert}>* Email is required</span>}
                                        <label className={styles.field}>
                                                <input className={styles.field__area} type="email" name="email" placeholder="email"  {...register("email", { required: true })}/>
                                                <div className={styles.field__label}>
                                                        <span> Email</span>
                                                </div>
                                            </label>
                                    </div>

                                    <div>
                                        {errors.password && <span className={styles.formAlert}>* Password is required</span>}
                                            <label className={styles.field}>
                                                <div style={{display:"flex"}}>
                                                <input className={styles.field__area} type={seePass ? "text" : "password"} name="password" placeholder="password"  {...register("password", { required: true })}/>
                                                <span onClick={()=> setSeePass(!seePass)}   style={{display:"flex", justifyContent:"center", alignItems:"center", color:"#fff", marginRight:"20px"}}>
                                                    {seePass ? <BsEyeFill style={{fontSize:"18px", color:"#fff"}}/> : <BsEyeSlashFill style={{fontSize:"18px", color:"#fff"}}/>}

                                                </span>

                                                </div>
                                                <div className={styles.field__label}>
                                                        <span> Password </span>
                                                </div>
                                            </label>
                                        </div>
         
                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                        <div className={`${styles.remberText} ${"mt-4 form-check"}`}>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                <label className="form-check-label text-white" for="exampleCheck1">Remember me</label>
                                        </div>
                                        <p className={`${styles.remberText} ${"mt-4 text-center text-white"}`} onClick={() =>handleForgetPassword()}>Forgotten password? Reset
                                        </p>

                                    </div>


                                            { btnDisabled !== true ?
                                            <button type="submit" className={`${styles.btnOutlined} ${"btn"}`}>
                                                    Log in
                                            </button>
                                            :
                                                <ThreeDots 
                                                    height="80" 
                                                    width="80" 
                                                    radius="9"
                                                    color="#fff" 
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                                                    visible={true}/>
                                            }


                                            </form>

                                <div style={{marginTop:"40px"}}>
                                        <p className="text-center text-white">- Or Continue using -</p>

                                <SocialAuth/>

                                    
                                    <p className="text-center text-white mt-5">Dont have an account <Link style={{color:"#f8d7da"}} to="/SignUp">SignUp</Link></p>




                                </div>


                                </div>


                                <div className={styles.co5}></div>





                                </section>

                                


<GoToTop/>
                                
        
        </>

    );
};

export default LogIn;
