import React, { useEffect, useState } from "react";
import {FaArrowLeft, FaCaretRight, FaImage, FaInfoCircle, FaPlus, FaQuestionCircle} from "react-icons/fa";
import {useForm} from "react-hook-form";
import Axios from "axios";
import {Row, Col } from 'react-bootstrap';

import Layout from '../Layout';

const CreateEvent = () => {

    const [venue, setVenue] = useState("online");
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [backgroundImgUrl, setBackgroundImgUrl] = useState('');
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [categories, setCategories] = useState([]);
    
    const handleVenue = (option) =>{
      setVenue(option);
    }
    
    const {register, handleSubmit, formState:{errors},} = useForm();
  
    const handleImgChange = (e) =>{
      let reader = new FileReader();
      let file  = e.targert.files[0];
      reader.onloadend =  () =>{
        setBackgroundImgUrl(reader.result);
      }
      reader.readAsDataURL(file);
      console.log(e.target.files[0]);
    }
    const addBgImg = () =>{
      const img = document.getElementById("event-banner");
      img.click();
    }

    const config = {
      headers: { 
        'token': `${sessionStorage.getItem('token')}`, 
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`, 
        'Content-Type': 'application/json'
      },
    };
    const publishEvent = async(data) => { 
       setMsg("");
       setLoading(true);
  
      let startTime = data.sessionStart;
      let stopTime = data.sessionEnd;
      if(startTime >= stopTime){
        setMsg('Error: session start time should be earlier than session stop time.');
        setLoading(false);
        return;
      }
      let eventDetails ={
        title: data.title,
       description:data.description,
        categoryId: data._id,
        startDate: data.date,
        endDate: data.endDate,
        location: {
          coordinates: [
            30,
            40.0
          ]
        },
        attendanceLimit: data.participantLimit,
        requirePassword: false
      }
      const location = {
        coordinates: [
          30,
          40.0
        ]
      }

        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('categoryId', data._id);
        formData.append('startDate', data.date);
        formData.append('endDate', data.endDate);
        formData.append('location', location);
        // formData.append('attendanceLimit', limit);
        // formData.append('requirePassword', isPassword);
        // formData.append('password', password);
        // formData.append('displayImage', { uri: secondImage[0].uri })
        
        // formData.append('displayImage', { 
        //   uri: secondImage[0].uri, 
        //   name: displayImage, 
        //   type:secondImage[0].type 
        // })
     
  
      console.log(eventDetails);
      return Axios.post("https://elroi.live/api/v1/events",eventDetails,config)
        .then((res) =>{
          setMsg("Event Created Successfully.");
          setTimeout(() => {
          setLoading(false);
          }, 1000);
        })
        .catch(err => {
          if(err){
            const {data} = err.response;
            const {errors} = data;
            let catError ="";
            let descError ="";
            let dateError ="";
            if(errors){
              for (let index = 0; index < errors.length; index++) {
                if(errors[index].toLowerCase().includes('category')){
                  catError ='select event Category';
                }
                if(errors[index].toLowerCase().includes('description')){
                  descError ='event description too short';
                }
                if(errors[index].toLowerCase().includes('date')){
                  dateError ='wrong Date.';
                }
              }
            }
            catError !== "" || descError !== ""?
            setMsg(`Error: Event Category not selected and Description too short.`) :
            dateError !==""? setMsg("Error: Invalid date selection."):
            setMsg('Error while publishing event. Try again later.');
          }
          
          console.log();
          setTimeout(() => {
            
            setLoading(false);
          }, 1000);
        });
    }
    
    useEffect(()=>{
    },[]);

    
    return (
        <Layout>
        <div className="header">
          <FaArrowLeft className="icon"/>
            <h1 className='title'>Create event</h1>
        </div>


         
            <Col lg="12">


                <Row>
                                    

                <Col lg="8" className="white-card">


                       <div className="form-floating mt-3">
                                  <input placeholder="Event title" type="text" className="h-auto form-control" id="floatingInput" name="name"
                                  value={title} 
                                  onChange={(e) => setTitle(e.target.value)}
                                  />
                                  <label for="floatingInput">Estate Name</label>
                        </div>

                </Col> 

                <Col lg="1"></Col>

                </Row> 
             </Col>
                                   



      <div className="div-wrap">
        <div className="banner">
          
          <div>
            <div className="img-icon" onClick={() => addBgImg()}>
              <FaImage className="icon"/>
            </div>
            <p>Add your Image</p>
            <input type="file" hidden  id="event-banner" onChange={(e)=> handleImgChange(e)}/>
          </div>

        </div>
        {backgroundImgUrl &&<img src={backgroundImgUrl} alt=''/>}
        <div className="form-div">
          
          <form onSubmit={handleSubmit(publishEvent)} >
            <div className="form-1">
              <div>
                <label>Event Title</label><br/>
                <input type="text" placeholder="Event Title" name="title"
                  maxLength={75} 
                  {...register('title',{required:true})}
                />
                <p>0/75</p>
                {errors.title && <p className="error">Event Title is required</p>}
              </div>
              <div>
                <label>Host/Organizer</label><br/>
                <input type="text" placeholder="Name of Host/Organizer" name="host"
                  {...register('host',{required:true})}
                />
                {errors.host && <p className="error">Host Name is required</p>}
              </div>
              <div>
                <label>Event Description</label><br/>
                <input type="text" placeholder="let your participants know why they should attend" name="description"
                  maxLength={150}
                  {...register('description',{required:true})}
                />
                <p>0/150</p>
                {errors.description && <p className="error">Event Description is required</p>}
              </div>
              <div>
                <label>Category</label><br/>
                <select name="_id" {...register('_id')}>
                  {
                    categories.map(category =>{
                      return(
                        <option key={category._id} value={category._id} >
                          {category.name}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
            <h5>Event Schedule</h5>
            <p>Schedule date and time of your event and make changes your dashboard.</p>
            <div className="schedule-data">
              <div>
                <label>Event Date</label>
                <input type="date" id="calendar"  name="date"  
                  {...register('date',{required:true})}
                />
                {errors.date && <p className="error">Event Date is Required.</p>}
              </div>
              <div>
                <label>Registration start time(If applicable)</label>
                <input type="time" className="time" name="startTime"
                  {...register('startTime',{required:true})}
                />
                {errors.startTime && <p className="error">Registration Start Time is required</p>}
              </div>
              <div>
                <label>Registration end Date(If applicable)</label>
                <input type="date" className="time" name="endDate"
                  {...register('endDate',{required:true})}
                />
                {errors.endDate && <p className="error">Registration End Time is required</p>}
              </div>
              <div>
                <label>Session start</label>
                <input type="time" className="time" name="sessionStart"
                  {...register('sessionStart',{required:true})}
                />
                {errors.sessionStart && <p className="error">Session Start Time is required</p>}
              </div>
              <div>
                <label>Session ends</label>
                <input type="time" className="time" name="sessionEnd"
                  {...register('sessionEnd',{required:true})}
                />
                {errors.sessionEnd && <p className="error">Session End Time is required</p>}
              </div>
            </div>
            <button className="more" disabled>
              <FaPlus className='icon'/>
              Add more Date
            </button>
            <h5>Location</h5>
            <p>Help people to discover where to show up.</p>
            <div className="location">
              <button className={venue ==="physical"? "selected-location venue":"venue"} onClick={() =>handleVenue("physical")} >Venue</button>
              <button className={venue ==="online"? "selected-location online":"online"}  onClick={() =>handleVenue("online")}>Online</button>
              <div className={venue === "physical"? "" :"hide"}>
                <label>Event Location</label><br/>
                <input type="text" name="location"
                  {...register('location',{required:false})}
                /> <br/>
                <button className="more" disabled>
                  <FaPlus className='icon'/>
                  Add more Location
                </button>
              </div>
              <div className={venue ==="online"?"online-event":"hide"}>
                <p>Set your event room and generate an ID for participants to join.</p>
                <div >
                  <h6>Use Personal event ID</h6>
                  <div className="online-parameter">
                    <p>Generate another event ID for this event </p>
                    <p>Change</p>
                  </div>
                </div>
                <div >
                  <h6>Audio Background </h6>
                  <div className="online-parameter">
                    <p>Add style to your audio background</p>
                    <p><FaCaretRight className="icon"/></p>
                  </div>
                </div>
                <div >
                  <h6>Make Event Public</h6>
                  <div className="online-parameter">
                    <p>Allow anybody without the link or password join your event. </p>
                    {/* <Toggler id="join-by-pwd"/> */}
                  </div>
                </div>
                <div >
                  <h5>Event Options</h5>
                  <div className="online-parameter">
                    <p>Allow Participant join before host</p>
                    {/* <Toggler id="join"/> */}
                  </div>
                </div>
                <div >
                  <div className="online-parameter">
                    <p>Automatically record event</p>
                    {/* <Toggler id="record-event"/> */}
                  </div>
                </div>
                <div className="online-parameter">
                  <div>
                    <p>Recording Location</p>
                  </div>
                  <p>Desktop device</p>
                </div>
                <div >
                  <h5>Settings</h5>
                  <p>Require event passcode</p>
                  <div className="online-parameter">
                    <p>Only users with the event passcode can join the event. </p>
                    {/* <Toggler id='setting'/> */}
                  </div>
                </div>
                <div>
                  <div className="online-parameter">
                    <p>Passcode</p>
                    <p>W665221</p>
                  </div>
                </div>
                <div >
                  <h6>Only allow authenticated users</h6>
                  <div className="online-parameter">
                    <p>Users not registered on the app can not join event </p>
                    {/* <Toggler id="auth"/> */}
                  </div>
                </div>
                <div>
                  <div  className="online-parameter">
                    <p>Add to calendar </p>
                    {/* <Toggler id="add-calendar"/> */}
                  </div>
                </div>
              </div>
            </div>

            <h5>Event fees</h5>
            <p>Help participants know if your event is free or paid</p>
            <div>
              <div className="fees">
                <div>
                  <select name='currency' {...register('currency')}>
                    <option value="NGN">NGN</option>
                    <option vlaue="USD">USD</option>
                  </select>
                </div>
                <div>
                  <input type='text' placeholder="Type amount" name="amount"
                    {...register('amount',{required:true})}
                  />
                  {errors.amount && <p className="error">Fee is required</p>}
                </div>
                <div>
                  <input type='text' id="desc" placeholder="Add more description of payment" name="payment-desc"
                    {...register('payment-desc',{required:false})}
                  />
                </div>
                
              </div>
              <div>
                <input className="link-input" type='text' placeholder="Add link for participant payment" name="pay-link"
                  {...register('pay-link',{required:false})}
                />
                <FaInfoCircle className="icon-warning"/>
              </div>
              <button className="more" disabled>
                <FaPlus className='icon'/>
                Add more Fees
              </button>
            </div>
            <h5>Registration Limit</h5>
            <p>Participants are unable to register once the number registered reaches the limit you set.</p>
            <div className="limit-div">
              <div>
              {errors.participantLimit && <p className="error">Registration Limit is required</p>}
                <input type="text" placeholder="100" name="participantLimit"
                  {...register('participantLimit',{required:true})}
                />
                <FaQuestionCircle className="icon-warning" />
              </div>
              <div className="calendar-setting">
                <p>Add this event to your calendar</p>
                {/* <Toggler id="personal"/> */}
              </div>
              <p className={msg.toLocaleLowerCase().includes('error')?"error":"text-success"}>{msg}</p>
              {loading?
                null :
                <button className="send" >Publish</button>
              }
              <div className={venue ==="publish"?"publish-notice":"hide"}>
                {/* <Overlay title="Publish Event" dismiss={handleVenue}/> */}
              </div>
            </div>
          </form>
          
        </div>
      </div>



        </Layout>
    );
};

export default CreateEvent;