import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Layout from '../Layout';
import EventSide from './components/EventSide';
import { useNavigate, useParams } from "react-router-dom";
import {format} from 'date-fns';
import Config from "../../../Config.json";
import { ThreeDots } from  'react-loader-spinner';
import { BsArrowLeftCircleFill } from "react-icons/bs";


const OpenEvent = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [numbOfFollowers, setnumbOfFollowers] = useState(0);
    const [pageLoading, setPageLoading] = useState(true);
    const [host, setHost] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [data, setData] = useState([])

    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [startDate, setStartDate] = useState(new Date());

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  const [btnDisabled, setBtnDisabled] = useState(false);


  const getEvent = async () => {
    setPageLoading(true);
     
    return fetch(`${Config.SERVER_URL}/events/${id}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson);
            if(responseJson.status === "success"){
                setData(responseJson.data.event);
                setStartDate(responseJson.data.event.startDate);
                followers();
                getUser();
                setPageLoading(false);
            }
            if(responseJson.status === "error"){
                 console.log("Event does not exist");
            }
            setBtnDisabled(false);
        })
        .catch((error) => {
          console.error(error);
          setBtnDisabled(false)
        });

   
}


  const followers = async () => {


        return fetch(`${Config.SERVER_URL}/users/${data.userId}/followers`, {
            method: "get",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("token")
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
                if(responseJson.status === "success") {
                    setnumbOfFollowers(responseJson.data.followers);
                }
            })
            .catch((error) => {
            console.error(error);
            });

        
        }

   const getUser = async () => {
    setPageLoading(true)

     return fetch(`${Config.SERVER_URL}/users/${data.userId}`, {
        method: "get",
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "token": sessionStorage.getItem("token")
        },
    })
        .then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.status === "success") {
                setHost(responseJson.data.user);
            }
            setPageLoading(false)
        })
        .catch((error) => {
        console.error(error);
        setPageLoading(false);
        });


    }

    const regEvent = async() =>{
        setBtnDisabled(true);
        return fetch(`${Config.SERVER_URL}/events/${data._id}/register`, {
            method: "post",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("token")
            },
            body: JSON.stringify({
                firstName:firstName,
                lastName:lastName,
                email:email
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if(responseJson.status === "success") {
                    alert("Registration Successfull");
                    setBtnDisabled(false);
                    handleClose();
                }
                if(responseJson.status === "error"){
                    setBtnDisabled(false);
                    alert(responseJson.error);
                }
            })
            .catch((error) => {
            console.error(error);
            });
    }

        useEffect(()=>{
            getEvent();
        },[])

        const viewStream = () => {
            navigate(`/user/Streaming/${data.title}/${data._id}`);
        }


    //    console.log((convertFromRaw(JSON.parse(data?.description))))

    return (
        
        <Layout>
               
   {pageLoading === true ?
                <>
                  <div style={{height:"50vh"}}>
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>
                </> 
                : <>

      <div className="container"> 
          <div className="row">


              <div className="col-lg-8">
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", marginBottom:"10px"}}>
                            <BsArrowLeftCircleFill onClick={()=> navigate(`/user/Events`)} className='arrowIcon' style={{color:"#003399"}}/>
                            <h1 className='title'>Event Details</h1>
                        </div>
                  <div className='eventBg'>
                     <img src={data?.displayImage} className="img-fluid block eventImg" alt="..."/>
                  </div>

                  <div className='row'>

                      

                      <div className="col-lg-11">
                           
                         <div className='event-details'>
                              <h4 className='event-title'>{data?.title}<hr style={{width:"50%", color:"#444445", fontWeight:"bold"}}/></h4>
                            <div style={{display:"flex", flexWrap:"wrap", width:"100%"}}>

                            <p>{showMore ? data?.description : `${data?.description.substring(0, 220)}`}

                            {data?.description.length < 220 ? null :<>
                              <span onClick={() => setShowMore(!showMore)} className="btn-a">{showMore ? "Show less" : "... Show more"}</span>
                              </>}
                              </p>
                            </div>

                           <div className="BadagryDate gap-4">
                              <div style={{display:"flex"}}>
                                    <i className="fa-solid fa-calendar-days"></i> 
                                     <p style={{marginLeft:"5px"}}> {format(new Date(startDate), 'dd MMMM yy')}</p>
                              </div>
                              <div style={{display:"flex"}}>
                                   <i className="fa-solid fa-clock"></i>
                                   {/* {format(new Date(startDate), 'dd-MMM-yyyy')}   */}
                                    <p style={{marginLeft:"5px"}}>{data?.startTime}</p>
                              </div>
                           </div>

                           <div className='engagemet'>
                              <h4>Events Engagements</h4>
                                        
                                    <div className='box-parent'>
                                        <div className="box engBox">
                                                <i className="fa-solid fa-heart"></i>
                                                <div>
                                                <p >{data?.likesCount}</p>
                                                <p className="view">Likes</p>
                                                </div>
                                        </div>
                                        
                                        <div className="box engBox">
                                                <i className="fa-solid fa-eye"></i>
                                                <div>
                                                    <p>{numbOfFollowers.length}</p>
                                                    <p className="view">Followers</p>
                                                </div>
                                        </div>
                                        
                                        <div className="box engBox">
                                                    <i className="fa-solid fa-comments"></i>
                                                <div>
                                                    <p>{data?.reviewsCount}</p>
                                                    <p className="view">Review</p>
                                                </div>
                                        </div>

                                    </div>
                             </div>

                             <hr style={{color:'#003399'}}/>

                             <div className='bottom'>
                                {( format(new Date(), 'yyyy-MM-dd') > format(new Date(startDate), 'yyyy-MM-dd') )
                                 ? 
                                   <p>Event Closed</p>
                                   : null}


                                {( format(new Date(), 'yyyy-MM-dd') < format(new Date(startDate), 'yyyy-MM-dd') ) ? 
                                   <button type="button" className=" botton btn btn-primary btn-elrio btn-md" onClick={handleShow}>Register for Event</button>: null}

                                {( format(new Date(), 'yyyy-MM-dd') == format(new Date(startDate), 'yyyy-MM-dd') ) ? 
                                    <button type="button" onClick={viewStream} className="botton btn btn-outline-primary btn-md">Join Event</button>
                                     :null
                                  } 
                             </div>
                              
                           </div>

                      </div>
                  </div>



              </div>
              

          <div className="col-lg-4">
                        <div className="col-12 ">
                        <h1 className='title text-white'>Following</h1>
                        </div>
                <div className="sideMenu">
                        <EventSide catId={data?.categoryId}/>

                    </div>
                
          </div>




          </div>

      </div>

      </>
    }


      <Modal show={show} onHide={handleClose}>
        
        <Modal.Body>
            
        <div className="row">
                  <div className="col-1"></div>

                  <div className="col-10">
                            <div className="row py-5">
                                <div className="col-12 text-center">

                                      {/* <p>The Believers’ Tabernacle </p> */}
                                      <h4>{data?.title}</h4>
                                      <p className='time'>{format(new Date(startDate), 'dd-MMM-yyyy')}   {data?.startTime} </p>

                                </div>
                                <div className="col-12 text-center">

                                      <h5>Event Registration</h5>

                                </div>
     
                                <div className="col-12">
                                    <div className="form-floating mt-3">
                                        <input placeholder="First Name" type="text" className="h-auto form-control" id="floatingInput" name="firstName"
                                          value={firstName} 
                                          onChange={(e) => setfirstName(e.target.value)}
                                        />
                                        <label for="floatingInput">First Name</label>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="form-floating mt-3">
                                        <input placeholder="Last Name" type="text" className="h-auto form-control" id="floatingInput" name="lastName"
                                          value={lastName} 
                                          onChange={(e) => setlastName(e.target.value)}
                                        />
                                        <label for="floatingInput">Last Name</label>
                                    </div>
                                </div>



                                <div className="col-12"> 
                                <div className="mt-3 form-floating">
                                    <input placeholder="Email" type="email" className="h-auto form-control" id="floatingInput" name="email"
                                              value={email} 
                                              onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label for="floatingInput">Email Address</label>
                                    </div>
                                </div>



                                <div className="col-12">

                                      <p className='time mt-4'>The information you provide when registering will be shared with the host in accordance with ElRoi <a href=''>Terms and Privacy Policy</a>.</p>

                                </div>
                                <div className="col-12">  
                                   { btnDisabled !== true ?
                                            <button type="button" className="w-100 botton btn btn-primary btn-elrio btn-md" onClick={regEvent}> Confirm Registration</button>
                                            :
                                                <ThreeDots 
                                                    height="40" 
                                                    width="40" 
                                                    radius="9"
                                                    color="#fff" 
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#003399"}}
                                                    visible={true}/>
                                            }
                                      
                                    </div>
                                <div className="col-12 mt-2" style={{display:"flex", justifyContent:"center", cursor:"pointer", alignItems:"center"}}>
                                      <span className="w-100 text-center" onClick={handleClose}>Cancel</span>
                                    </div>




                            </div>

                       </div>

                  <div className="col-1"></div>

                </div>
            
            
        </Modal.Body>
      </Modal>
            
            
        </Layout>
    );
};

export default OpenEvent;