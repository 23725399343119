import React, {useState, useEffect} from 'react';
import Config from "../../../Config.json";
import { ThreeDots } from  'react-loader-spinner'

const Followers = () => {


  const [followers, setfollowers] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  const getFollowers = async() => {

    const userId = await sessionStorage.getItem("userId");
    console.log(userId)


      setPageLoading(true);

      return fetch(`${Config.SERVER_URL}/users/${userId}/followers`, {
          method:"get",
          headers:{
              Accept: "application/json",
              "Content-Type": "application/json",
              "token": sessionStorage.getItem("token")  
          }
      })
      .then((response) => response.json())
      .then((responseJson) => {

        console.log("Followers", responseJson);

          if(responseJson.status === "success"){
              setfollowers(responseJson.data.followers);
              setPageLoading(false);
          }

      })
      .catch((error) => {
        console.error(error);
        setPageLoading(false);
      });




  }

  useEffect(()=>{
      getFollowers();
  },[])






    return (
        <div className='col-12' style={{minHeight:"30vh"}}>

                <div className="row">



  
        {pageLoading === true ?
                <>
                  <div style={{height:"30vh"}}>
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="10"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>
                </> 
                : <>




          {followers && followers.length > 0
                    ? followers.map((f, index) => {
                      return <>

                <div key={index} className="col-lg-12">

                    <div className="follower">
                        <div className='followerImg'>
                            <img src={f.followingUserId.profilePhotoUrl} className='img-fluid d-block rounded-circle follow-img'/>
                        </div>
                        <div className='followerBox'>
                                <h5>{f.followingUserId.firstName}  {f.followingUserId.lastName}</h5>
                                {/* <p>200 Events</p> */}
                        </div>
                    </div>
                    
                </div>

                          </> })
                : <div style={{height:"100%", display:"flex", justifyContent:"center", width:"100%", alignItems:'center'}}>
                          <h4 style={{marginTop:"20px", fontSize:"16px"}}>No follower Found</h4>
                      </div>}

                                      </>
                }




                        
                </div>
            
        </div>
    );
};

export default Followers;