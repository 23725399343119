import React, {useState, useEffect} from "react";
import styles from "./Sign.module.css"
import {useForm} from "react-hook-form";
import { Link } from "react-router-dom";
// import Axios from "axios";
import Config from "../../Config.json"
import { ThreeDots } from  'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import GoToTop from "../GoToTop";
import Swal from 'sweetalert2';
import SocialAuth from "./SocialAuth";




const SignUp = () => {
    
    let navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [accept, setAccept] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [seePass, setSeePass] = useState(false);
    const [seeConfirmPass, setSeeConfirmPass] = useState(false);

    const onSubmit = (data) => {


    if(!errors.lenght){
        setErrorMsg("");
        setBtnDisabled(true);
        if(data.password === data.confirmPassword ){
          if(accept !== true){
          Swal.fire({
            icon: 'error',
            position:'top-right',
            text: "You must accept our Terms and Conditions to proceed.",
            toast:true,
            timer: 3000,
            showConfirmButton:false,
          });
            setBtnDisabled(false);
            return;
          }
        let userDetails ={
            "firstName": data.firstName,
            "lastName": data.lastName,
            "email": data.email,
            "password": data.password,
            "phoneNumber":data.phoneNumber
          }
          setTimeout(() => {
            console.log(userDetails);
  
                  
      return fetch(`${Config.SERVER_URL}/auth/signup`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
          body: JSON.stringify({
              firstName:data.firstName,
              lastName: data.lastName,
              email: data.email,
              password: data.password,
              phoneNumber: data.phoneNumber
          })
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if(responseJson.status ==="success"){
                    sessionStorage.setItem("token", responseJson.data.token);
                    sessionStorage.setItem("firstName",`${responseJson.data.user.firstName}`)
                    sessionStorage.setItem("lastName",`${responseJson.data.user.lastName}`)
                    sessionStorage.setItem("userId",`${responseJson.data.user._id}`)
                    setBtnDisabled(false);
                    navigate("/Preference");
          }
          if(responseJson.status ==="error"){
            console.error(responseJson.error);
            setBtnDisabled(false);
            Swal.fire({
              icon: 'error',
              position:'top-right',
              text: responseJson.error,
              toast:true,
              timer: 3000,
              showConfirmButton:false,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          setBtnDisabled(false);
          Swal.fire({
            icon: 'error',
            position:'top-right',
            text: "Unable to Create Account. Try again later.",
            toast:true,
            timer: 3000,
            showConfirmButton:false,
          });
        });
  
  
  
            // return Axios.post(`${Config.SERVER_URL}/auth/signup`,userDetails)
            //   .then(async (res) =>{
            //     console.log(res)
            //     const {data, status} =  await res.data;
            //     console.log(data)
            //     if(status ==="success"){
            //         sessionStorage.setItem("token", data.token);
            //         sessionStorage.setItem("firstName",`${data.user.firstName}`)
            //         sessionStorage.setItem("lastName",`${data.user.lastName}`)
            //         sessionStorage.setItem("userId",`${data.user._id}`)
            //         navigate("/user/feeds");
            //     }
            //     if(status ==="error"){
            //         setErrorMsg(data.error)
            //     }
            //   }).catch(err => {
            //     console.log(err);
            //     setBtnDisabled(false);
            //     setErrorMsg("Unable to Create Account. Try again later.")
            //     });
            
          }, 2000);
        }else{
          setTimeout(() => {
            setBtnDisabled(false);
            Swal.fire({
              icon: 'error',
              position:'top-right',
              text: "Password does not match.",
              toast:true,
              timer: 3000,
              showConfirmButton:false,
            });
          }, 2000);
        }
        
      }

    }



    return(

        <>




<section className={styles.loginContainer}>

            <div className={styles.co5}></div>


            <div className={styles.co3}>

             <Link to="/" className={[styles.imgDiv]}>
               <img src="images/logo.png" width="80" className="d-inline-block align-top" alt=""/>  
             </Link>
                            <div className="loginBack mt-4">
                                <h3 className={styles.welText}>Let's get,<br/> you started!</h3>
                            </div>

             
             <form onSubmit={handleSubmit(onSubmit)}>
                                {errorMsg &&  
                                <span className={styles.floatingAlert}>
                                    <strong>{errorMsg}</strong> 
                                </span>
                                }
          
               <div>
                      {errors.firstName && <span className={styles.formAlert}>* First Name is required</span>}
                    <label className={styles.field}>
                            <input className={styles.field__area} type="text" name="firstName" placeholder="First name"  {...register("firstName", { required: true })} />
                            <div className={styles.field__label}> First Name
                            </div>
                        </label>
               </div>

               <div>
                      {errors.lastName && <span className={styles.formAlert}>* Last Name is required</span>}
                    <label className={styles.field}>
                            <input className={styles.field__area} type="text" name="lastName" placeholder="Last name"  {...register("lastName", { required: true })}/>
                            <div className={styles.field__label}>
                                        Last name
                            </div>
                        </label>
                
               </div>

               <div>
                      {errors.email && <span className={styles.formAlert}>* Email is required</span>}
                    <label className={styles.field}>
                            <input className={styles.field__area} type="email" name="email" placeholder="Write your email"  {...register("email", { required: true })}/>
                            <div className={styles.field__label}>Email
                            </div>
                        </label>

               </div>

               <div>
                      {errors.phoneNumber && <span className={styles.formAlert}>* Phone Number is required</span>}
                    <label className={styles.field}>
                            <input className={styles.field__area} type="tel" name="phoneNumber" placeholder="Write your Phone Number"  {...register("phoneNumber", { required: true })}/>
                            <div className={styles.field__label}>Phone Number
                            </div>
                        </label>

               </div>

               <div>
                      {errors.password && <span className={styles.formAlert}>* Password is required</span>}
                    <label className={styles.field}>
                          <div style={{display:"flex"}}>
                            <input className={styles.field__area} type={seePass ? "text" : "password"} name="password" placeholder="Password"  {...register("password", { required: true, min: 8 })}/>
                              <span onClick={()=> setSeePass(!seePass)}   style={{display:"flex", justifyContent:"center", alignItems:"center", color:"#fff", marginRight:"20px"}}>
                                  {seePass ? <BsEyeFill style={{fontSize:"18px", color:"#fff"}}/> : <BsEyeSlashFill style={{fontSize:"18px", color:"#fff"}}/>}
                                 </span>

                              </div>


                            <div className={styles.field__label}> Password
                            </div>
                        </label>
                </div>

               <div>
                      {errors.confirmPassword && <span className={styles.formAlert}>* Re-type Password</span>}
                    <label className={styles.field}>
                          <div style={{display:"flex"}}>
                            <input className={styles.field__area} type={seeConfirmPass ? "text" : "password"}  name="confirmPassword" placeholder="Confirm Password"  {...register("confirmPassword", { required: true })}/>
                              <span onClick={()=> setSeeConfirmPass(!seeConfirmPass)}   style={{display:"flex", justifyContent:"center", alignItems:"center", color:"#fff", marginRight:"20px"}}>
                                  {seeConfirmPass ? <BsEyeFill style={{fontSize:"18px", color:"#fff"}}/> : <BsEyeSlashFill style={{fontSize:"18px", color:"#fff"}}/>}
                                 </span>

                              </div>

                            <div className={styles.field__label}>Confirm Password
                            </div>
                        </label>
                </div>

          <div className="form-group mt-4 text-white" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <label className={`${styles.remberText}`} style={{}}>
                <input type="checkbox" className={styles.checkbox} /> Remember me.</label>

              <label className={`${styles.remberText}`}>
                <input type="checkbox"  className={styles.checkbox} onClick={()=>{setAccept(!accept)}} /> 
                  <span>I agree to the <span className="terms"> Terms and Privacy Policy</span></span>
                </label>
            </div>

                             { btnDisabled !== true ?
                                     <button type="submit" className={`${styles.btnOutlined} ${"btn"}`}>Sign Up
                                         </button>
                                            :
                                                <ThreeDots 
                                                    height="80" 
                                                    width="80" 
                                                    radius="9"
                                                    color="#fff" 
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                                                    visible={true}/>
                                            }

             </form>


             <div style={{marginTop:"40px"}}>
                                        <p className="text-center text-white">- Or Continue using -</p>

                                
                                  <SocialAuth/>

                                    
                                    <p className="text-center text-white mt-5">Already have an account <Link style={{color:"#f8d7da"}} to="/LogIn">Login</Link></p>




                                </div>



            </div>


            <div className={styles.co5}></div>




  
</section>


<GoToTop/>
        
        </>

    );
};

export default SignUp;