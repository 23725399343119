import React, { useState } from 'react';
import styled from 'styled-components';
import logo from  "./logo.png";
import { Footer2 } from '../components/Footer2';
import { useForm } from 'react-hook-form';


export const ResetPassword = () => {

  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleSubmitForm = async(data) => {
    setIsLoading(true);
    console.log(data);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };
  return (
    <Wrap>
      <div className='nav_bar'>
        <img className='img-fluid' src={logo}  width="80" alt="eroi-logo"/>
        <h4>Password Reset</h4>
      </div>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="form-group my-3">
          <input type="password" name='password' className="form-control" placeholder='Enter New Password' 
            {...register('password',{required:true})}
          />
          {errors.password && <span className="text-danger">Password is required.</span>}
        </div>
        <div className="form-group my-3">
          <input type="password" name='confirm_password' className="form-control" placeholder='Confirm New Password' 
            {...register('confirm_password',{required:true})}
          />
          {errors.confirm_password && <span className="text-danger">Confirm Password is required.</span>}
        </div>
        <button className='send'>Save Password</button>
      </form>
      {/* <Footer /> */}
      <Footer2 />
    </Wrap>
  )
}
const Wrap = styled.div `
  .nav_bar{
    background:var(--primaryColor);
    padding:10px;
    color:#fff;
    display:flex;
    align-items:center;
    gap:20px;
  }
  form{
    width:45rem;
    margin:5% auto;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding:15px;

    .send{
      border:1.5px solid var(--primaryColor);
      color:var(--primaryColor);
      background:#fff;
      border-radius:5px;
      padding:10px 20px;
      font-weight:800;
      transition: all 1s ease;
    }
    .send:hover{
      background:var(--primaryColor);
      color:#fff;
    }
  }
  @media screen and (max-width:640px){
    form{
      width:90%;
    }
  }
`;