import AgoraRTC from "agora-rtc-sdk-ng";
import React,{useEffect, useState,useRef} from 'react'
import styled from 'styled-components'
import Layout from '../../Layout';
import { ThreeDots } from  'react-loader-spinner';
import axios from "axios";
import { Offcanvas, Modal, Row, Col } from 'react-bootstrap';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { RWebShare } from "react-web-share";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../../App.css';

import {format} from 'date-fns';
import { useParams, useNavigate } from "react-router-dom";
import Config from "../../../../Config.json";
import { AiFillDelete } from "react-icons/ai";
import { BsPencilSquare, BsFillCloudUploadFill, BsArrowLeftCircleFill } from "react-icons/bs";
import Board from '../components/Board';
import Comments from '../../Events/components/Comments';
import Participants from '../../Events/components/Participants';
import Reviews from '../components/Reviews';
import Swal from 'sweetalert2';
import { showJoinedMessage, createMicrophoneAudioTrack, createCameraVideoTrack } from "./utils/utils";
import { useUrlQuery, useUnMount } from "./utils/hooks";
import AgoraVideoPlayer from "./comp/VideoPlayer";
const client = AgoraRTC.createClient({
  mode: "live",
  codec: "vp8"
});

const DEFAULT_LATENCY = '1';
const latencyItems = [{
  label: 'Interactive Live Streaming Standard',
  key: "1"
}, {
  label: 'Interactive Live Streaming Premium',
  key: "2"
}];
let role = 'host';
let audienceLatency = DEFAULT_LATENCY;


export const LiveStream = () => {
 
  let {eventId } = useParams();
  sessionStorage.setItem("eventId", eventId);
  sessionStorage.setItem("id", eventId);
  const navigate = useNavigate();

  const APP_ID = "0ec363408e7343a396b1d0a72c649777";
  const Token ="007eJxTYFA4yydWxN8g3fZ1+f4ifgHG/kCryW4PT53sZuMvejmX55ICg0FqsrGZsYmBRaq5sYlxorGlWZJhikGiuVGymYmlubm5WINTWkMgI4N8pDErIwMEgvg8DKk5RfmZ8cUlRamJuQwMAC5zHms=";
  const channel = "elroi_stream";
  const UID = null

  const formRef = useRef();
  useUrlQuery(formRef);
  const [joined, setJoined] = useState(false);
  const [videoTrack, setVideoTrack] = useState(null);
  const [audioTrack, setAudioTrack] = useState(null);
  const [remoteUsers, setRemoteUsers] = useState({});
  const [localUid, setLocalUid] = useState("");

  const [loadBtn, setLoadBtn] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [event, setEvent] = useState([]);
  const [eventIsLive, setEventIsLive] = useState(false);
  const [commentCount, setCommentCount] = useState([]);
  const [likeCount, setLikeCount] = useState();
  const [reviewsCount, setReviewsCount] = useState();
  const [viewsCount, setviewsCount] = useState();
  const [page, setPage] = useState('board');
  const [showMore, setShowMore] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [removeImage, setremoveImage] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [displayImage, setDisplayImage] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startD, setStartD] = useState('');
  const [endD, setEndD] = useState('');
  const [startTime, setStartTime] = useState('');
  const [limit, setLimit] = useState();
  const [isPassword, setIsPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState();
  const [cat, setCat] = useState([]);
  const [time, setTime] = useState('');

  useUnMount(() => {
    if (joined) {
      // stopStreaming();
    }
  });
  const initTracks = async () => {
    const tracks = await Promise.all([createMicrophoneAudioTrack(), createCameraVideoTrack()]);
    setAudioTrack(tracks[0]);
    setVideoTrack(tracks[1]);
    return tracks;
  };

  const subscribe = async (user, mediaType) => {
    await client.subscribe(user, mediaType);
    if (mediaType === "video") {
      // ...
    }
    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  };

  const handleUserPublished = async (user, mediaType) => {
    const id = user.uid;
    await subscribe(user, mediaType);
    setRemoteUsers(prev => ({
      ...prev,
      [id]: user
    }));
  };

  const startStreaming = async () => {
    try {
      setLoadBtn(true);
       client.setClientRole(role);
       
      // Join a channel
      const uid = await client.join(APP_ID, channel, Token || null, UID || null);
      setLocalUid(uid);
      const tracks = await initTracks();
      await client.publish(tracks);
      setJoined(true);
      setEventIsLive(true);
      setLoadBtn(false);
    } catch (error) {
      // message.error(error.message);
      // setEventIsLive(false);
      setLoadBtn(false);
      console.log("error", error);
      Swal.fire({
        timer: 3000,
        text:"Something went wrong please try again",
        toast:true,
        position:"top-right",
        icon:"error",
        showConfirmButton:false,
        timerProgressBar: true,
      })
    }
  };

  const stopStreaming = async () => {
    setLoadBtn(true);
    audioTrack?.close();
    setAudioTrack(null);
    videoTrack?.close();
    setVideoTrack(null);
    setRemoteUsers({});
    await client?.leave();
    setJoined(false);
    setTimeout(() => {
      setEventIsLive(false);
      setLoadBtn(false);
    }, 3000);
    Swal.fire({
      timer: 3000,
      text:"Event Ended",
      toast:true,
      position:"top-right",
      icon:"success",
      showConfirmButton:false,
      timerProgressBar: true,
    })
    // const msg = "client leaves channel success!";
    // message.success(msg);
  };




  useEffect(() => {
      getEvent();
  },[])

  const getEvent = async () => {
    // setPageLoading(true);
     return fetch(`${Config.SERVER_URL}/events/${eventId}`, {
        method: "get",
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "token": sessionStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson)
            if(responseJson.status === "success") {
                setEvent(responseJson.data.event);
                setLikeCount(responseJson.data.event.likesCount)
                setviewsCount(responseJson.data.event.viewsCount)
                setReviewsCount(responseJson.data.event.reviewsCount)
                // setCommentCount(responseJson.data.event.liveComments.length)
                setTitle(responseJson.data.event.title)
                setDesc(responseJson.data.event.description)
                setDisplayImage(responseJson.data.event.displayImage)
                setStartDate(responseJson.data.event.startDate)
                setEndDate(responseJson.data.event.endDate)
                setStartD(format(new Date(responseJson.data.event.startDate), 'yyyy-MM-dd'))
                setEndD(format(new Date(responseJson.data.event.endDate), 'yyyy-MM-dd'))
                setStartTime(new Date(responseJson.data.event.startTime), 'HH-mm');
                setLimit(responseJson.data.event.attendanceLimit)
                setIsPassword(responseJson.data.event.requirePassword)
                // setPassword(responseJson.data.event.password)

                console.log(cat)
               const eventCategory = cat.filter(c => c._id === responseJson.data.event.categoryId);
               console.log(eventCategory);
                setCategoryName(cat.find(c => c._id === responseJson.data.event.categoryId));
                setCategoryId(responseJson.data.event.categoryId)
                setTime(responseJson.data.event?.time);
            }
            setPageLoading(false);
        })
        .catch((error) => {
        console.error(error);
        // setPageLoading(false);
        });
  }

  return (
    <Wrapper>
      <Layout>            
        <div className="container-fluid"> 
          <div className="row">
            <div className="col-lg-7">

              {pageLoading ?
                <>
                  <div style={{height:"50vh"}}>
                    <ThreeDots 
                      height="120" 
                      width="120" 
                      radius="9"
                      color="#003399" 
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                      visible={true}/>
                  </div>
                </>
                : <>
                  <div className="col-lg-11">
                    <div className="titleContainer">
                      <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"10px"}}>
                        <BsArrowLeftCircleFill onClick={()=> navigate(-1)} className='arrowIcon' style={{color:"#003399"}}/>
                        <h1 className='title'>{title}</h1>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-lg-11">
                      <div className='eventBg'>
                        {!eventIsLive ? <img  src={displayImage}  className="img-fluid d-block eventImg" alt="..."/> :<>
                          
                          <div className="mt-10 mb-10" style={{width: '100%', height: '100%'}}>
                             <AgoraVideoPlayer videoTrack={videoTrack}></AgoraVideoPlayer>
                          </div>

                       </> }
                      </div>
                    </div>
                    <div className="col-lg-11">
                        
                      <div className='event-details'>

                        <div className='titleContainer'>

                          <div className='eventBtn'>
                            {!loadBtn ? <>
                              <div>
                                <span onClick={() => startStreaming()} className='btn-elrio btn-p'>Start Streaming</span>
                              </div>
                              <div>
                                <span onClick={() => stopStreaming()} className='btn-elrio btn-p'>End Event</span>
                              </div>
                              </> : <>

                                <ThreeDots 
                                  height="20" 
                                  width="20" 
                                  radius="9"
                                  color="#003399" 
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                                  visible={true}
                                />
                              </>
                            }
                          </div>
                        </div>

                        <div style={{display:"flex", flexWrap:"wrap", width:"100%"}}>
                          <p>{showMore ? desc : `${desc.substring(0, 220)}`}
                            {desc.length < 220 ? null :<>
                              <span onClick={() => setShowMore(!showMore)} className="btn-a">{showMore ? "Show less" : "... Show more"}</span>
                              </>}
                          </p>
                        </div>

                        <div className="BadagryDate gap-4">
                          <div style={{display:"flex"}}>
                            <i className="fa-solid fa-calendar-days"></i> 
                            <p className='dateTime'> {format(new Date(startDate), 'dd MMMM yy')}</p>
                          </div>
                          <div style={{display:"flex"}}>
                            <i className="fa-solid fa-location-dot"></i>
                            <p className='dateTime'>{event.startTime}</p>
                          </div>
                          <RWebShare
                            data={{
                              text: `Use the link to join this event ${title}` ,
                              url: `${Config.WEB_URL}/Streaming/${event._id}`,
                              title: `${title}`,
                            }}
                            onClick={() => console.log("shared successfully!")}>
                              <div style={{display:"flex", cursor: "pointer"}}>
                                  <i className="fa-solid fa-share"></i>
                                    <p className='dateTime'>Share this event</p>
                              </div>
                          </RWebShare>
                        </div>

                        <div className='engagemet'>
                          <h4>Events Engagement</h4>
                          <div className='box-parent'>
                            <div className="box engBox">
                              <i className="fa-solid fa-heart"></i>
                              <div>
                              <p >{likeCount}</p>
                              <p className="view">Likes</p>
                              </div>
                            </div>
                              
                            <div className="box engBox">
                              <i className="fa-solid fa-eye"></i>
                              <div>
                                <p>{viewsCount}</p>
                                <p className="view">Views</p>
                              </div>
                            </div>
                            <div className="box engBox">
                              <i className="fa-solid fa-comments"></i>
                              <div>
                                <p>{commentCount}</p>
                                <p className="view">Comments</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr style={{color:'#003399'}}/>
                            
                      </div>

                    </div>
                  </div>

                </>
              }
            </div>
            <div className="col-lg-5">
              <div className="sideMenu"> 
                <div className=''>                    
                  <nav className='navlistParent gap-3'>
                    <span className={(page === "board") ?"nav-list activeSec" : "nav-list inactiveSec"}  onClick={()=> setPage('board')}>Board</span>
                    <span className={(page === "comments") ?"nav-list activeSec" : "nav-list inactiveSec"} onClick={()=> setPage('comments')}>Chat</span>
                    <span className={(page === "participants") ?"nav-list activeSec" : "nav-list inactiveSec"}  onClick={()=> setPage('participants')}>Participants</span>
                    <span className={(page === "review") ?"nav-list activeSec" : "nav-list inactiveSec"}  onClick={()=> setPage('review')}>Review</span>
                  </nav>

                  <div className='pageNav'>
                      
                    {page === "board" ? <>
                      <Board data={event}/>
                      </> : null
                    }
              
                    {page === "comments" ? <>
                      <Comments data={event}/>
                    </> : null}

                    {page === "participants" ? <>
                      <Participants data={event}/>
                      </> : null}
                    {page === "review" ?<>
                    <Reviews id={event._id}/>
                    </>: null}
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
        
      </Layout>
    </Wrapper>
  )
}
const Wrapper = styled.div `

`
