import React from 'react';
import Nav from './components/Nav';
import Footer from './components/Footer';
import { Link } from "react-router-dom";
import styles from "./Style.module.css";
import { HiCheckCircle } from "react-icons/hi";

const About = () => {
    return (
        <>
        <Nav/>


        <section  className={styles.container}>
                    <div  className={styles.contain}>

                     <div className={styles.containOne}>
                                            
                                <h4 className={`${styles.abtTitle} ${"wow fadeInUp"}`}  data-wow-duration="1s">With ElRoi
                                <br className={styles.br}/> 
                                you can't miss out!
                                </h4>
                                <p className={`${styles.abouthero_text} ${"wow fadeInUp"}`}  data-wow-duration="1.2s">We deliver services to you in the most simplified way. Elroi helps you connect to others, host hitch-free virtual incents with massive interaction, upload and view content, and at the same time, give you top-notch video/audio experience for all your virtual meetings.</p>
                                <p className={`${styles.abouthero_text} ${"wow fadeInUp"}`}  data-wow-duration="1.2s">
                                This makes us the go-to problem solver for all organizations, institutions, enterprises, small and large businesses, religious bodies, and individuals. ElRoi was created to give your virtual events the social interaction it needs. Founded in 2022.</p>

                        </div>

                        <div className={styles.containTwo}>
                            <img src="/images/abtImg.png" className={`${styles.aboutImg} ${"d-block wow fadeInUp"}`} data-wow-duration="1s" alt="about"/>
                        </div>

                    </div>


                    <section className={styles.analyticSection}>

                            <div className={styles.sectionThreeBoxOne}>
                                    <img src="./images/section4.png" className={`${styles.secThreeImg} ${"d-block img-fluid wow fadeInLeft"}`} alt="playstore" data-wow-duration="1.2s"/>
                            </div>

                            <div className={`${styles.sectionThreeBoxTwo} ${"wow fadeInLeft"}`}  data-wow-duration="1s">
                                    {/* <span className={styles.features}>Features</span> */}
                                    <div  className={styles.sectionText}>
                                        <h3 className={styles.featureTitle}>Event Analytics</h3>
                                        <p className={styles.featureText}>Hosts can track their events<br className={styles.br}/> to better improve attendees' experience.</p>
                                        <span  className={styles.paragraph}>Event hosts can view the:</span>
                                        <ul className={styles.liParent}>
                                            <li><HiCheckCircle style={{color:"#003399"}}/> Number of likes, views and comments.</li>
                                            <li><HiCheckCircle style={{color:"#003399"}}/> Number of attendes for an event.</li>
                                            <li><HiCheckCircle style={{color:"#003399"}}/> Number of followers.</li>
                                            <li><HiCheckCircle style={{color:"#003399"}}/> People by there location using a map</li>
                                            <li><HiCheckCircle style={{color:"#003399"}}/> Number of those who registered for an event.</li>
                                        </ul>
                                    </div>
                            </div>

                            </section>



                    <section className={styles.digitalContain}>

                        <div className={styles.contain} style={{justifyContent:"space-between", paddingTop:"5%"}}>

                       <div className={styles.conOne} >
                                            
                                <h4 className={`${styles.digital} ${"wow fadeInUp"}`}  data-wow-duration="1s">OUR 
                                <br/> 
                                DIGITAL 
                                <br/> 
                                APPROACH
                                </h4>
                                <div className={styles.ourLine}></div>

                        </div>

                    <div className={styles.conTwo}>
                                <p className={`${styles.abouthero_text} ${"wow fadeInUp"}`}  data-wow-duration="1.2s">ElRoi focuses on rendering an efficient, easy-to-use, virtual-conferencing experience. This product enables you to set up virtual video/audio meetings and is a platform that specifically supports social interaction between event hosts and attendees by employing strategic features such as chat rooms, event feed, review sections, online payment options, transcripts, and analytics.</p>
                         </div>


                         </div>
                    </section>



                    {/* <section className={styles.sectionTwo}>

                            <div className={`${styles.actionSection}  ${"wow fadeInUp"}`}   data-wow-duration="1.1s" >
                                
                                <div className={`${styles.sectionTwoBoxTwo} ${"wow fadeInUp"}`} data-wow-duration="1.2s">
                            <h3 className={`${styles.actionTitle}  ${"wow fadeInUp text-center"}`}   data-wow-duration="1s">CLICK HERE TO GET STARTED</h3>

                                
                                    
                                </div>

                            </div>

                
                     </section> */}







         </section>






         <Footer/>





            
        </>
    );
};

export default About;