import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Config from "../../../../Config.json";
import { ThreeDots } from  'react-loader-spinner';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

const Board = (props) => {

    const {data} = props;
    const [boards, setBoards] = useState([]);
    const [cont, setCont] = useState('');
    const [loading, setLoading] = useState(true);

    const getBoards = async () => {


        return fetch(`${Config.SERVER_URL}/events/${data._id}/boards`, {
            method: "get",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("token")
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson.data.eventBoards)
                if(responseJson.status === "success") {
                    setBoards(responseJson.data.eventBoards);
                }
                setLoading(false)
            })
            .catch((error) => {
            console.error(error);
            setLoading(false)
            });

        
        }


        useEffect(()=>{
            // const interval = setInterval(()=>{
                getBoards();
            //  },3000);
        
            //  return () => clearInterval(interval);
        },[])



    return (<>
 
        {loading ?  <>
                  <div style={{height:"50vh"}}>
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>

            </>
        
          : 


    <div className="accordion accordion-flush" id="accordionFlushExample">



    {boards && boards.length > 0
                           ? boards.map((board, index) => {
                            // const contentState = convertFromRaw(JSON.parse(board.content));
                            // const editorState = EditorState.createWithContent(contentState);
                            
                            // {board.type === "note" &&
                            //     setCont(EditorState.createWithContent(convertFromRaw(JSON.parse(board.content))))
                            // }

           return( <>
           
                 <div className="accordion-item mb-4">
                   <h2 className="accordion-header" id={board._id}>
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls="flush-collapse">
                         {board.name}
                     </button>
                   </h2>
                   <div id={`flush-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={board._id} data-bs-parent="#accordionFlushExample">
                     <div className="accordion-body">

                        {board.type === "image" ?   <>
                        
                           <img  src={board.content}  className="img-fluid d-block eventImg"   alt="..."/> 
                           {/* <a href="geeksforgeeks.png" download="GFG">
                                <button type="button">Download</button>
                                </a> */}
                           <a download={board.content} href={board.content} title="ImageName">
                                    Download Image
                                </a>
                        
                                </> : null}
                          {board.type === "note" ?   <>
                          
                             <Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(board.content)))} readOnly={true}/>
                           
                           </> : null}
                            {board.type === "document" ?   
                             <>
                             <iframe src={`https://docs.google.com/viewerng/viewer?url=${board.content}&embedded=true `} frameborder="0" height="300px" width="100%"></iframe>

                             <Link  to={board.content} className="scroll-link waves-effect waves-light">Download Document</Link>
                             
                             </>
                            
                              : null}
                              {board.type === "audio" ?  <> 
                                    <audio controls>
                                        <source src={board.content} type="audio/mpeg"/>
                                         Your browser does not support the audio tag.
                                    </audio>
                              
                                    </>: null}
                                {board.type === "video" ?   
                                    <video width="100%" height="240" controls>
                                        <source src={board.content} type="video/mp4"/>
                                         Your browser does not support the video tag.
                                    </video>
                                
                                  : null}

                     </div>
                   </div>
                 </div>
               </>)
           })
               :
               <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="text-center">
                       <div className="chat">
                           No Board <br/>uploaded yet
                       </div>
                   </div>}


     </div>
          
          }
   

            

        
   </> );
};

export default Board;