import React from 'react';
import { useNavigate } from "react-router-dom";
import {format} from 'date-fns';
import { Editor, EditorState, convertFromRaw } from 'draft-js';



const EventCard = (props) => {
    const navigate = useNavigate();

    const {data} = props;

    const eventDetails = (data) => {
      // sessionStorage.setItem("eventId", data.eventId);
        navigate(`/user/Details/${data.title}/${data._id}`, { state: { data: data} });
     }
     
    return (<>


              {data && data.length > 0
                                    ? data.map((data, index) => {
                                      return <>
                
               <div onClick={()=> eventDetails(data)} key={index} className="col-lg-4 col-md-6">
                       <div className="Cardy card mt-3 " >
                             <img  src={data?.displayImage} className="card-img-top mh-50 " alt="..."/>
                        <div className="card-body p-4">
                             <h5>{data?.title}</h5>
                             {/* <p>
                             <Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(data?.description)))} readOnly={true}/>
                             </p> */}
                              <p>{data?.description}</p>
                           <div className="BadagryDate gap-4">
                                <div className="d-flex gap-4 w-100">  
                                  <span className='datTime'>
                                      <i className="fas fa-calendar-alt"></i> {" "}
                                      {format(new Date(data?.startDate), 'dd MMMM yy')}
                                    </span>
                                  <span className='datTime'> 
                                      <i className="fas fa-clock"></i> {" "}
                                      {data?.startTime}
                                    </span>
                                </div>

                           </div>
                           {/* <div className="button">
                              <div>
                                    <button onClick={()=> eventDetails(data)}  type="button" className=" btn  btn-sm rounded-pill">View Details</button>
                              </div>
                           </div> */}
                        </div>
                      </div>
                    </div>

                        </> })
               : <div style={{marginTop:"10%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", width:"100%", alignItems:'center'}}>
                        <img src='/images/notFound/savedEvnt.png' className={`${'img-fluid'} ${"notFound"}`}/>
                        <h4 style={{fontSize:"20px", marginTop:"5%", fontWeight:'bold'}}>No Event Found</h4>
                     </div>}
        
     
   </> );
};

export default EventCard;