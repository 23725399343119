import React, {useState, useEffect} from 'react';
import Config from "../../../../Config.json";
import { ThreeDots } from  'react-loader-spinner';
import {format} from 'date-fns';
import { useNavigate } from "react-router-dom";

const EventSide = (props) => {
     const [loading, setLoading] = useState(true);
     const [events, setEvents] = useState([]);

    const {catId } = props;

//     const idString = JSON.stringify(catId);
       const catArray = [catId];
//     catArray.push(catId);
    
   

    const myEvents = async () => {
     setLoading(true);
    //  categoryIds[]=["610cc75e3d0bf006207402d3"]
     return fetch(`${Config.SERVER_URL}/events?categoryIds[]=${catArray}`, {
         method: "get",
         headers: {
           Accept: "application/json",
           "Content-Type": "application/json",
           "token":   sessionStorage.getItem("token")
         },
       })
         .then((response) => response.json())
         .then((responseJson) => {
           console.log(responseJson)
           if(responseJson.status === "success") {
                setEvents(responseJson.data.events);
             }
             setLoading(false)
         })
         .catch((error) => {
           console.error(error);
           setLoading(false)
         });

    
      }

     useEffect(() => {
          myEvents();
     },[])


     const navigate = useNavigate();

     const eventDetails = (data) => {
         navigate(`/user/Event/${data.title}`, { state: { data: data} });
     }


    return (
        <>


        <div className="eventSide">

        {loading === true ?
                <>
                  <div style={{height:"50vh"}}>
                    <ThreeDots 
                    height="40" 
                    width="40" 
                    radius="10"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>
                </> 
                : <>
          {events.map((data, index) => {
                    return( 
                    <div  onClick={()=> eventDetails(data)} key={index} className="cardTwo">
                        <div className='imgCardBox'>
                             <img src={data?.displayImage} className="card-img img-fluid" alt="..."/>
                        </div>
                        <div className="eventDetails">
                           <div className="textDetails">
                                <h5>{data?.title}</h5>
                                <p>{data?.description.substring(0, 50)}</p>
                                <div className="eventTime">
                                     <i className="fa-solid fa-calendar-days"></i> 
                                      <p style={{marginLeft:"5px"}}> {format(new Date(data?.startDate), 'dd MMMM yy')}</p>
                                </div>
                                {data?.isLive && <p><i className="fa-solid fa-eye"></i> {data?.reviewsCount} Views</p>}
                                
                           </div>
                        </div>
                      </div>
                    )})}


                     </>
                   }

           </div>

            
        </>
    );
};

export default EventSide;