import React, { useState } from 'react';
import styled from 'styled-components';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from  'react-loader-spinner';
import { useForm } from 'react-hook-form';
import { Axios } from '../../Utils/AxiosConfig';
import Swal from 'sweetalert2'

export const ForgotPassword = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [success,setSuccess] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const resetPassword = async(dataInput) => {
    setIsLoading(true);
    // console.log(dataInput);
    const payload = {
      email: dataInput.email
    } ;
  
    try {
      const {data} = await Axios.post(`/auth/forgot-password`, payload);
      if(data.success){
        Swal.fire({
          icon: "success",
          text: "Password Reset Link Sent",
          toast:true,
          position: "top-right",
          timer:3000,
          showConfirmButton: false,
          timerProgressBar: true,
        });
        setTimeout(() => {
          navigate(`/login`);
        }, 7000);
      }
    } catch (error) {
      setSuccess(false);
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        text: "Unable to send link. Try again later!",
        toast:true,
        position: "top-right",
        timer:3000,
        showConfirmButton: false,
        timerProgressBar: true,
      });
      return;
    }
    setTimeout(() => {
      setIsLoading(false);
      setSuccess(true);
    }, 3000);
  }
  const backToLogin = () => navigate(`/login`);
  return (
    <Wrapper className='text-center'>
      <h2><IoMdArrowRoundBack className='me-5 back' onClick={() => backToLogin()}/>Forgot Password</h2>
      <hr className='mx-auto mt-0'/>
      <h5 className='mb-4'>Reset Password</h5>
      {!success ?
        <div className='form_div'>
          <p>Enter your email address to receive a link to <br/>reset your password.</p>
          <form onSubmit={handleSubmit(resetPassword)}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input type="email" className='form-control' name="email" placeholder='example@gmail.com' 
                {...register('email',{required:true})}
              />
              {errors.email && <span className="text-danger">Email is Address required.</span>}
            </div>
            <div className="btn_div mt-4 form-group">
              {!isLoading ? <button className="btn btn_send">Send</button> :
                <ThreeDots 
                height="80" 
                width="80" 
                radius="9"
                color="#1564c0" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                visible={true}/>
              }
            </div>
          </form>
        </div>
        : 
        <p className='msg'>A verification email has been sent to you to <br/> reset your password.</p>
      }
      
      
    </Wrapper>
  )
}
const Wrapper = styled.div `
  .back{
    cursor:pointer;
  }
  hr{
    border:1.5px solid #ccc;
    width:40%;
  }
  h5{
    font-weight:600;
  }
  .form_div{
    margin:6% 0;
  }
  form{
    text-align: left;
    padding:10px;
    width:23rem;
    margin:5px auto;

    input{
      margin-bottom:15px;
    }
    input:focus{
      outline:none !important;
    }
    .btn_div{
      margin:0 auto;
      width:max-content;

      .btn_send{
        background:#00b4d7;
        color:#fff;
        font-weight:800;
        width:250px;
        transition:all 1s ease;
      }
      .btn_send:hover{
        background:#1564c0;
      }
    }
  }
  .msg{
    margin:6% 0;
    color:#79bbda;
  }
`;