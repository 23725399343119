import axios from "axios";
import Config from"../../src/Config.json";

const url = `${Config.SERVER_URL}`
const Axios = axios.create({
  baseURL:url,
  headers:{
    "Content-Type":"application/json",
    Accept: "application/json",
    "token": sessionStorage.getItem("token"),
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,  
  }
});

const AxiosForm = axios.create({
  baseURL:url,
  headers:{
    "Content-Type":"multipart/form-data",
    "token": sessionStorage.getItem("token"),
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`, 
  }
});

export {Axios, AxiosForm};