import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Notes from './profile-pages/Notes';
import ProfileComp from "./profile-pages/ProfileComp";
import Notification from './profile-pages/Notification';
import Followers from './profile-pages/Followers';
import MyInterest from './profile-pages/MyInterest';
import Config from "../../Config.json";
import { ThreeDots } from  'react-loader-spinner'


const Profile = () => {

    const [page, setPage] = useState('profPage');



    const [followers, setfollowers] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
  
    const getFollowers = async() => {
  
      const userId = await sessionStorage.getItem("userId");
      console.log(userId)
  
  
        setPageLoading(true);
  
        return fetch(`${Config.SERVER_URL}/users/${userId}/followers`, {
            method:"get",
            headers:{
                Accept: "application/json",
                "Content-Type": "application/json",
                "token": sessionStorage.getItem("token")  
            }
        })
        .then((response) => response.json())
        .then((responseJson) => {
  
            if(responseJson.status === "success"){
                setfollowers(responseJson.data.followers);
                setPageLoading(false);
            }
  
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false);
        });
  
  
  
  
    }
  
    useEffect(()=>{
        getFollowers();
    },[])
  
  
  


    return (
        <Layout>  

      <div className=" parentContainer">
        <div className="row">
          <div className="col-lg-8">

              {page === "profPage" ? <>

                   <ProfileComp/>

                </> : null}

          </div>
 
          <div className="col-lg-4">

            <div className="container noteParentCard py-3 px-4">
                <div className="col-12">
                    <h5>Notes</h5>
                </div>
                <div className="col-12">
                    
                  <Notes/>
                  
                    </div>
            </div>

            <div className="container mt-5 noteParentCard py-3 px-4">
                <div className="col-12">
                    <h5>Followers</h5>
                </div>
                <div className="col-12">
                      <Followers/>
                 </div>
            </div>
                
          </div> 

        </div>
      </div>
        
        </Layout>
    );
};

export default Profile;