import React, {useState, useEffect} from 'react';
import Config from "../../../../Config.json";
import {format} from 'date-fns';
import { ThreeDots } from  'react-loader-spinner'


const Reviews = (props) => {
    const [isLoading, setLoading] = useState(true)
    const [reviews, setReviews] = useState("");
  
    const {id} = props;

    const _id = sessionStorage.getItem("id");

 
    const myReviews = async () => {
            
      return fetch(`${Config.SERVER_URL}/events/${_id}/reviews`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":  sessionStorage.getItem("token") 
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson.data.eventReviews)
              if(responseJson.status === "success") {
                setReviews(responseJson.data.eventReviews);
                }
                setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false)
          });

     
  }

  useEffect(() => {
      myReviews();
  },[])


    return (
        <>


{isLoading ?  <>

            <div style={{height:"50vh"}}>
              <ThreeDots 
                  height="80" 
                  width="80" 
                  radius="9"
                  color="#003399" 
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                  visible={true}/>
              </div>

      </>
  
    : <>
        
        {reviews && reviews.length > 0
                                ? reviews.map((reviews,index) =>{
                return(<>
                
                <div key={index} className='chat' style={{backgroundColor:"#fff"}}>
                        <div className='imgBox'>
                            {/* <img src='/images/prof.png' className='img-fluid d-block'/> */}
                        </div>
                        <div className='textBox'>
                            <div className='heading'>
                                <h5>Emma Williams</h5>
                                <h6>
                                    
                                    {/* {formatDistance(reviews.createdAt, Date.now(), {addSuffix: true})} */}
                                    {/* {format(new Date(reviews.createdAt), 'HH :mm')} minute */}
                                    </h6>
                            </div>
                            <p>{reviews.comment}</p>
                        </div>
                    </div>
                    </>)
                })
                    :
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="text-center">
                            <div className="chat">
                                No Review yet
                            </div>
                        </div>}

             
   </> }       

            
        </>
    );
};

export default Reviews;