import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaGooglePlus, FaTwitterSquare, FaInstagramSquare } from "react-icons/fa";
import { Link } from "react-router-dom";


export const Footer2 = () => {
  return (
    <Wrap>
      <div className="midFooter">

        <a className="iconFoot btn btn-link btn-floating btn-md text-white"
        href="https://www.facebook.com/"
        role="button"><FaFacebook size={36}/></a>

        <a className="iconFoot btn btn-link btn-floating btn-md text-white"
        href="mailto:@gmail.com" role="button"><FaGooglePlus size={36}/></a>

        <a className="iconFoot btn btn-link btn-floating btn-md text-white"
        href="https://www.instagram.com/" role="button"><FaInstagramSquare size={36}/></a>

        <a className="iconFoot btn btn-link btn-floating btn-md text-white"
        href="https://www.twitter.com/" role="button"><FaTwitterSquare size={36}/></a>
      </div>


      <section className="">
        <div className="d-flex align-items-center logo_div">
          <img src="images/logo.png" width="100" className="d-inline-block align-top" alt=""/>
          
          <ul className="">
            {/* <li className={list}>Watch Demo</li> */}
            <li className="">
              <Link to="/Pricing"style={{color:"#fff"}} >Pricing</Link></li>
            <li className=""><Link to="/FAQs"style={{color:"#fff"}} >FAQs</Link></li>
            <li className="">Privacy Policy</li>
            <li className="">Terms &amp; Conditions</li>
          </ul> 
        </div>
        <div className="slogan">
          <div className="">
            <p>Create your live events, seminars, or workshops, <br/>from any part of the world by broadening your reach.</p>
          </div>
          <div className="download d-flex">
            <img src="./images/google.png" className="" alt="playstore"/>
            <img src="./images/ios-footer.png" className="" alt="ios"/>
          </div>
        </div>
      </section>
    <hr className='mx-auto'/>
      <div className="text-center">
        <span className={`copywrite`}> © 2022 All rights reserved elroievent.com</span> 
      </div>
    </Wrap>
  )
}

const Wrap = styled.div `
  background:var(--primaryColor);
  color:#fff;
  padding:15px 45px;

  .midFooter{
    display:flex;
    align-items:center;
    justify-content:flex-end;
    margin:0 auto; 
  }
  .logo_div{
    ul{
      list-style:none;
      margin:0;
      padding:0;
      
      li{
        display:inline-block;
        margin:5px 15px;
        cursor:pointer;
      }
    }
  }

  .slogan{
    display:flex;
    gap:15px;
    align-items:center;
    justify-content:space-between;
    .download{
      gap:15px;
      img{
        width:200px;
        cursor:pointer;
      }
    }
  }
  @media screen and (max-width:640px){
    padding:15px;
   
    .logo_div{
      flex-direction:column;
      align-items:start !important;
      ul{
        li{
          margin:0 5px;
          display:flex;
        }
      }
    }
    .slogan{
      gap:5px;
      flex-direction:column;
      .download{
        gap:5px;
        img{
          width:150px !important;
        }
      }
    }
  }

  @media screen and (max-width:768px){
    padding:15px;
   
    .logo_div{
      width:max-content;
      gap:4px;
      align-items:start !important;
      ul{
        li{
          margin:0 5px;
        }
      }
    }
    .slogan{
      margin-top:15px;
      gap:5px;
      .download{
        gap:10px;
        img{
          width:160px;
        }
      }
    }
  }
`;
