import AgoraRTC from "agora-rtc-sdk-ng";
import React, {useEffect, useState, useRef} from 'react';
import Layout from '../Layout';
import { ThreeDots } from  'react-loader-spinner';
import axios from "axios";
import { Offcanvas, Modal, Row, Col } from 'react-bootstrap';
import EventBoards from './components/EventBoards'
// import { Editor, EditorState } from "draft-js";

import {format} from 'date-fns';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Config from "../../../Config.json";
import { AiFillDelete } from "react-icons/ai";
import { BsPencilSquare, BsFillCloudUploadFill, BsArrowLeftCircleFill, BsFillCameraVideoOffFill, BsFillCameraVideoFill } from "react-icons/bs";
import { IoMdMic, IoMdMicOff } from "react-icons/io";
import Board from './components/Board';
import Comments from '../Events/components/Comments';
import Participants from '../Events/components/Participants';
import Reviews from './components/Reviews';
import Swal from 'sweetalert2';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { RWebShare } from "react-web-share";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../App.css';
import { createMicrophoneAudioTrack, createCameraVideoTrack } from "./streaming/utils/utils";
import { useUrlQuery, useUnMount } from "./streaming/utils/hooks";
import AgoraVideoPlayer from "./streaming/comp/VideoPlayer";
const client = AgoraRTC.createClient({
  mode: "live",
  codec: "vp8"
});
// client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

let role = 'host';


const HostEventDetails = () => {
    let { id, eventId } = useParams();
    const {state} = useLocation();
    const { data } = state;

    // const [editorState, setEditorState] = useState(initialState);

    const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
    );


      // sessionStorage.setItem("id", id);
      // sessionStorage.setItem("eventId", eventId);
    
      const navigate = useNavigate();
      sessionStorage.setItem("eventId", eventId);
      sessionStorage.setItem("id", eventId);
    
    
      const formRef = useRef();
      useUrlQuery(formRef);
      const [joined, setJoined] = useState(false);
      const [videoTrack, setVideoTrack] = useState(null);
      const [audioTrack, setAudioTrack] = useState(null);
      const [remoteUsers, setRemoteUsers] = useState({});
      const [localUid, setLocalUid] = useState("");
      const [appId, setAppId] = useState("c059eb20f7464f66b6eac51beccb9782");
      const [token, setToken] = useState(data?.token);
      const [channel, setChannel] = useState(data?.channelName);
      // const [UID, setUID] = useState(data?.uid);
      const [UID, setUID] = useState(1);
     

    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [typeContent, setTypeContent] = useState('');
    const [content, setContent] = useState('');
    const [createdBoards, setCreatedBoards] = useState([]);
    const [imgfile,setImgFile] = useState('');

    const [today, setToday] = useState(new Date());
    const [showBoard, setShowBoard] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [event, setEvent] = useState([]);
    const [eventIsLive, setEventIsLive] = useState(false);
    const [commentCount, setCommentCount] = useState([]);
    const [likeCount, setLikeCount] = useState();
    const [reviewsCount, setReviewsCount] = useState();
    const [viewsCount, setviewsCount] = useState();
    const [page, setPage] = useState('board');
    const [showMore, setShowMore] = useState(false);
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [removeImage, setremoveImage] = useState(false);
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [displayImage, setDisplayImage] = useState('');
    const [eventBg, setEventBg] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startD, setStartD] = useState('');
    const [endD, setEndD] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [limit, setLimit] = useState();
    const [isPassword, setIsPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categoryName, setCategoryName] = useState();
    const [cat, setCat] = useState([]);
    const [time, setTime] = useState('');



  
    const [showOffcanvas, setShowOffcanvas] = useState(false);
  
    const offCanvasClose = () => setShowOffcanvas(false);
    const offCanvasShow = () => setShowOffcanvas(true);
    const [isPasswordTrue, setIsPasswordTrue] = useState(false);

    const convertToBase64 =(inputFile) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
    
        reader.readAsDataURL(inputFile);
    
        reader.onload = function (event) {
          resolve(event.target.result);
        };
    
        reader.onerror = function (error) {
          reject(error); 
        };
      });
    }
    const choseImageFile = async (e)=>{
      setImgFile(URL.createObjectURL(e.target.files[0]));
      const file = await convertToBase64(e.target.files[0]);
      console.log(file);
      setTypeContent(file)
    }
    const setPasswordState = (value) => {
          if(value === true){ 
            setIsPassword(true);
            setIsPasswordTrue(true);
            return 
          }
          if(value === false){ 
            setIsPassword(false);
            setIsPasswordTrue(false);
            return 
          } 
      }
    
    const getPreferences = async() => {

      return fetch(`${Config.SERVER_URL}/categories`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":  sessionStorage.getItem("token") 
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson)
              if(responseJson.status === "success"){
                setCat(responseJson.data.categories);
                getEvent();
              }
          })
          .catch((error) => {
            console.error(error);
          });

      }

    useEffect(() => {
        getPreferences();
    },[])

    
    const config = {
      headers: { 
        'token': `${sessionStorage.getItem('token')}`, 
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      },
    };
 
    const publishEvent = async() => {

      setMsg("");
      setLoading(true);
      if(title === ""){
        setMsg(`Error: Your event must have a title.`);
        setLoading(false);
        return;
      }
      if(displayImage === ""){
        setMsg(`Error: Upload image for your event.`);
        setLoading(false);
        return;
      }
      if(desc === ""){
        setMsg(`Error: Your event must have a description.`);
        setLoading(false);
        return;
      }
      if(categoryId === ""){
        setMsg(`Error: Select event category.`);
        setLoading(false);
        return;
      }

      if(startDate >= endDate){
        console.log(startDate, endDate)
        setMsg(`Error: Your event start Date should be earlier than it's end Date.`);
        setLoading(false);
        return;
      }
      if(isPassword && password === ""){
        setMsg(`Error: Please input password for your event.`);
        setLoading(false);
        return;
      }
      // setDisableBtn(true);

     const loc = {
        location: {
          coordinates: [
            30,
            40.0
          ]
        },
     }
        const formData = new FormData()
        formData.append('displayImage', displayImage);
        formData.append('title', title);
        formData.append('description', desc);
        formData.append('categoryId', categoryId);
        formData.append('startDate', startD);
        formData.append('endDate', endD);
        formData.append('startTime', startTime);
        formData.append('endTime', endTime);
        // formData.append('location', loc.location.coordinates);
        formData.append('requirePassword', isPassword);
        if(isPassword){
           formData.append('password', password);
          }



     return await axios.patch(`${Config.SERVER_URL}/events/publish/${id}`, formData, config)
      .then((response) => {
        console.log(response);
        if(response.data.status === "success"){
          setDisplayImage(response.data.data.event.displayImage)
          setLoading(false);
          alert("Event updated Successfully");
          Swal.fire({
            icon:'success',
            text: 'Event Published Successfully',
            position:'top-right',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          })
          setShowOffcanvas(false)
        }
      })

      .catch(err => {
        console.log(err)
        if(err){
          // const {data} = err.response.data;
          console.log(err.response.data.error);
          // const {errors} = data;
          setMsg(`Error: ${err.response.data.errors}`)
          setLoading(false);
        }
        
        setTimeout(() => {
          
          setLoading(false);
        }, 1000);
      });

    }
    const editEvent = async() => {

      setMsg("");
      setLoading(true);
      if(title === ""){
        setMsg(`Error: Your event must have a title.`);
        setLoading(false);
        return;
      }
      if(displayImage === ""){
        setMsg(`Error: Upload image for your event.`);
        setLoading(false);
        return;
      }
      if(desc === ""){
        setMsg(`Error: Your event must have a description.`);
        setLoading(false);
        return;
      }
      if(categoryId === ""){
        setMsg(`Error: Select event category.`);
        setLoading(false);
        return;
      }

      if(startDate >= endDate){
        console.log(startDate, endDate)
        setMsg(`Error: Your event start Date should be earlier than it's end Date.`);
        setLoading(false);
        return;
      }
      if(isPassword && password === ""){
        setMsg(`Error: Please input password for your event.`);
        setLoading(false);
        return;
      }
      // setDisableBtn(true);

     const loc = {
        location: {
          coordinates: [
            30,
            40.0
          ]
        },
     }
        const formData = new FormData()
        formData.append('displayImage', displayImage);
        formData.append('title', title);
        formData.append('description', desc);
        formData.append('categoryId', categoryId);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        formData.append('startTime', startTime);
        formData.append('endTime', endTime);
        if(limit !== ""){
          formData.append('attendanceLimit', limit);
        }
        // formData.append('location', loc.location.coordinates);
        formData.append('requirePassword', isPassword);
        if(isPassword){
           formData.append('password', password);
          }



     return await axios.patch(`${Config.SERVER_URL}/events/${id}`, formData, config)
      .then((response) => {
        console.log(response);
            if(response.data.status === "success"){
                setDisplayImage(response.data.data.event.displayImage)
                setLoading(false);
                  Swal.fire({
                    icon:'success',
                    text: 'Event updated Successfully',
                    position:'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                  })
                  setShowOffcanvas(false)
              }
      })

      .catch(err => {
        console.log(err)
        if(err){
          // const {data} = err.response.data;
          console.log(err.response.data.error);
          // const {errors} = data;
          setMsg(`Error: ${err.response.data.errors}`)
          setLoading(false);
        }
        
        setTimeout(() => {
          
          setLoading(false);
        }, 1000);
      });

    }

    const deleteEvent = () => {

      if (!window.confirm("are you sure you want to delete this event?")) {
        return
      }

      return fetch(`${Config.SERVER_URL}/events/${id}`, {
        method: "delete",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":  sessionStorage.getItem("token") 
            },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            if(responseJson.status === "success") {
                alert("Event deleted successfully");
                navigate(`/user/My-Event`);
              }
            if(responseJson.status === "error") {
                alert(responseJson.data.error);
              }
        })
        .catch((error) => {
            console.error(error);
        });

    }

    const [selectedCat, setSelectedCat] = useState([])

   const getEvent = async () => {
    setPageLoading(true);
     return fetch(`${Config.SERVER_URL}/events/${id}`, {
        method: "get",
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "token": sessionStorage.getItem("token")
        },
    })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("Events ", responseJson);
            if(responseJson.status === "success") {
                setEvent(responseJson.data.event);
                setSelectedCat(responseJson?.data.category)
                // setToken(responseJson?.data?.event?.token);
                // setChannel(responseJson?.data?.event?.channelName);
                // setUID(responseJson?.data?.event?.uid);
                setLikeCount(responseJson.data.event.likesCount)
                setviewsCount(responseJson.data.event.viewsCount)
                setReviewsCount(responseJson.data.event.reviewsCount)
                // setCommentCount(responseJson.data.event.liveComments.length)
                setTitle(responseJson.data.event.title)
                setDesc(responseJson.data.event.description)
                setDisplayImage(responseJson.data.event.displayImage)
                setStartDate(responseJson.data.event.startDate)
                setEndDate(responseJson.data.event.endDate)
                setStartD(format(new Date(responseJson.data.event.startDate), 'yyyy-MM-dd'))
                setEndD(format(new Date(responseJson.data.event.endDate), 'yyyy-MM-dd'))
                setStartTime(responseJson.data.event.startTime);
                setEndTime(responseJson.data.event.endTime);
                setLimit(responseJson.data.event.attendanceLimit)
                setIsPassword(responseJson.data.event.requirePassword)
                // setPassword(responseJson.data.event.password)
                
                const eventCategory = cat.filter(c => c._id === responseJson.data.event.categoryId);
                console.log(eventCategory);
                setCategoryName(cat.find(c => c._id === responseJson.data.event.categoryId));
                setCategoryId(responseJson.data.event.categoryId)
                setTime(responseJson.data.event?.time);
                setToday(format(new Date(), 'yyyy-MM-dd'));
            }
            setPageLoading(false);
        })
        .catch((error) => {
        console.error(error);
        // setPageLoading(false);
        });

        
    }
    
    
    
     const [loadBtn, setLoadBtn] = useState(false);
  

      const initTracks = async () => {
        const tracks = await Promise.all([createMicrophoneAudioTrack(), createCameraVideoTrack()]);
        setAudioTrack(tracks[0]);
        setVideoTrack(tracks[1]);
        return tracks;
      };
    
      const subscribe = async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          // ...
        }
        if (mediaType === 'audio') {
          user.audioTrack.play();
        }
      };
    
    const isLiveFunc = async()=> {
      console.log(id)
      return fetch(`${Config.SERVER_URL}/events/live/${id}`, 
      {
        method:"PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem("token")
        },
          body: JSON.stringify({
              eventId: id
          })
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("is live", responseJson);
            if(responseJson.status === "success"){
                // alert("note saved");
            }
            setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false); 
        });
      }
    
    
  const startStreaming = async () => {
      try {
           setLoadBtn(true);
           client.setClientRole(role);

          // Join a channel
          const uid = await client.join(appId, channel, token || null, UID || null);
          setLocalUid(uid);
          const tracks = await initTracks();
          await client.publish(tracks);
         await isLiveFunc()
          setJoined(true);
          setEventIsLive(true);
          setLoadBtn(false);
      }catch (error) {
          // message.error(error.message);
          // setEventIsLive(false);
          setLoadBtn(false);
          console.log("error", error);
          Swal.fire({
            timer: 3000,
            text:"Something went wrong please try again",
            toast:true,
            position:"top-right",
            icon:"error",
            showConfirmButton:false,
            timerProgressBar: true,
          })
        }
      };
    
  const stopStreaming = async () => {
        setLoadBtn(true);
        audioTrack?.close();
        setAudioTrack(null);
        videoTrack?.close();
        setVideoTrack(null);
        setRemoteUsers({});
        await client?.leave();
        await isLiveFunc()
        setJoined(false);
        setTimeout(() => {
          setEventIsLive(false);
          setLoadBtn(false);
        }, 3000);
        Swal.fire({
          timer: 3000,
          text:"Event Ended",
          toast:true,
          position:"top-right",
          icon:"success",
          showConfirmButton:false,
          timerProgressBar: true,
        })
        // const msg = "client leaves channel success!";
        // message.success(msg);
      };
    
      // const handleBgUpload = (e) =>{
      //   setDisplayImage(e.target.files[0]);
      //   setEventBg(URL.createObjectURL(e.target.files[0]));
      // }

      const [mute, setMute] = useState(false);
      const [videoMute, setVideoMute] = useState(false);

      const toggleAudioMute = async ()=> {
        setMute(!mute)
      }
      const toggleVideoMute = async()=>{
        setVideoMute(!videoMute)
      }
    

    const displayTitle = title || '';
    const limitedDisplayName = displayTitle.length > 25 ? `${displayTitle.slice(0, 25)}...` : displayTitle;
    

    useEffect(() => {
      return () => {
        if (eventBg) {
          URL.revokeObjectURL(eventBg);
        }
      };
    }, [eventBg]);
  
    const handleBgUpload = (e) => {
      const file = e.target.files[0];
      console.log()
      if (file) {
        setDisplayImage(file);
        const objectUrl = URL.createObjectURL(file);
        setEventBg(objectUrl);
        console.log(objectUrl);
      } else {
        console.log("No file selected or file selection was cancelled.");
      }
    };


    return (
        
        <Layout>

                    
      <div className="container"> 
          <div className="row">


              <div className="col-lg-7">

         {pageLoading ?<>
                  <div style={{height:"50vh"}}>
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="10"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>
         
           </>
         
           : <>
                <div className="col-lg-11">
                    <div className="titleContainer">
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"10px"}}>
                            <BsArrowLeftCircleFill onClick={()=> navigate(`/user/My-Event`)} className='arrowIcon' style={{color:"#003399", fontSize:"25px"}}/>
                             <h1 className='title'>{limitedDisplayName}</h1>
                        </div>
                        <div className="">
                        {!eventIsLive && <>
                            <BsPencilSquare onClick={offCanvasShow} className='delPenIcon' style={{color:"#003399"}}/>
                            <AiFillDelete onClick={deleteEvent} className='delPenIcon' style={{color:"#D42323"}}/>
                            </> }
                        </div>
                    </div>
                </div>
         

                  <div className='row'>
                      <div className="col-lg-11">
                         <div className='eventBg'>

                         {eventIsLive && (<>
                            <AgoraVideoPlayer videoTrack={videoTrack}></AgoraVideoPlayer>


            <div style={{width: '100%', display: 'flex', justifyContent: 'center', position: 'relative', gap:'20px',  top:'-20%'}}>

                  <div className={mute ? "muteActive" : "hostIconController"} onClick={()=> toggleAudioMute()}>
                    {mute ?
                      <IoMdMicOff className={mute ? "controlIconActive" : "controlIcon"}/>
                        :
                      <IoMdMic className={mute ? "controlIconActive" : "controlIcon"}/>
                    }
                  </div>


                  <div className={videoMute ? "muteActive" : "hostIconController"} onClick={()=> toggleVideoMute()}>
                    {videoMute ?
                      <BsFillCameraVideoOffFill className={videoMute ? "controlIconActive" : "controlIcon"}/>
                        :
                      <BsFillCameraVideoFill className={videoMute ? "controlIconActive" : "controlIcon"}/>
                    }
                  </div>

            </div>
                          </> )}
               {!eventIsLive && (<>
                  <img  src={displayImage}  className="img-fluid d-block eventImg" alt="..."/>
                               </>)}
                         </div>
                      </div>
                      <div className="col-lg-11">
                           
                         <div className='event-details'>

                            <div className='titleContainer'>
                              {/* <h4 className='event-title'><hr style={{width:"50%", color:"#444445", fontWeight:"bold"}}/>{channel} {token}</h4> */}

                              <div className='eventBtn w-100'>

                     {!loadBtn ?<>

                          <div className='w-100 d-flex justify-content-between align-items-center'>
                        {event?.isPublished ?
                           <div className='d-flex'>
                              {!eventIsLive && 
                              <div>
                                 <span onClick={() => startStreaming()} className='btn-elrio btn-p'>Start Event</span>
                              </div>
                              }
                              <div>
                                 <span onClick={() => setShowBoard(true)} className='btn-elrio btn-p'>Create Board</span>
                              </div>
                         
                           </div> 
                          :
                            <div>
                                {/* <span onClick={() => publishEvent()} className='btn-elrio btn-p'>Publish Event</span> */}
                            </div>
                           }

                              {eventIsLive && (
                              <div>
                                 <span onClick={() => stopStreaming()} className='btn-elrio btn-p bg-danger'>End Event</span>
                              </div>
                              )} 

                           </div>
                             </> : <>

                             <ThreeDots 
                                  height="20" 
                                  width="20" 
                                  radius="9"
                                  color="#003399" 
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                                  visible={true}/>
                              </>}

                              </div>
                            </div>

                            <div style={{display:"flex", flexWrap:"wrap", width:"100%"}}>
                            <p>{showMore ? desc : `${desc.substring(0, 220)}`}
                            {desc.length < 220 ? null :<>
                              <span onClick={() => setShowMore(!showMore)} className="btn-a">{showMore ? "Show less" : "... Show more"}</span>
                              </>}
                            </p>
                            </div>

                           <div className="BadagryDate gap-4">
                              <div style={{display:"flex"}}>
                                    <i className="fa-solid fa-calendar-days"  style={{fontSize:"20px"}}></i> 
                                     <p className='dateTime'  style={{fontSize:"20px"}}> {format(new Date(startDate), 'dd MMMM yy')}</p>
                              </div>
                              <div style={{display:"flex"}}>
                                   <i className="fa-solid fa-clock"  style={{fontSize:"20px"}}></i>
                                    <p className='dateTime' style={{fontSize:"20px"}}>{event.startTime}</p>
                              </div>
                          <RWebShare
                            data={{
                              text: `Use the link to join this event ${title}` ,
                              url: `${Config.WEB_URL}/Event/${event._id}`,
                              title: `${title}`,
                            }}
                            onClick={() => console.log("shared successfully!")}>
                              <div style={{display:"flex", cursor: "pointer"}}>
                                   <i className="fa-solid fa-share"></i>
                                    <p className='dateTime'>Share this event</p>
                              </div>
                              </RWebShare>
                           </div>

                           <div className='engagemet'>
                              <h4>Events Engagement</h4>
                                        
                                    <div className='box-parent'>
                                        <div className="box engBox">
                                                <i className="fa-solid fa-heart"></i>
                                                <div>
                                                <p >{likeCount}</p>
                                                <p className="view">Likes</p>
                                                </div>
                                        </div>
                                        
                                        <div className="box engBox">
                                                <i className="fa-solid fa-eye"></i>
                                                <div>
                                                    <p>{viewsCount}</p>
                                                    <p className="view">Views</p>
                                                </div>
                                          </div>
                                        
                                        <div className="box engBox">
                                                    <i className="fa-solid fa-comments"></i>
                                                <div>
                                                    <p>{commentCount}</p>
                                                    <p className="view">Comments</p>
                                                </div>
                                        </div>

                                    </div>
                             </div>

                             <hr style={{color:'#003399'}}/>
                              
                           </div>

                      </div>
                  </div>

                  </>}
              </div>
              

          <div className="col-lg-5">
                
          <div className="sideMenu"> 
                            
                                  <div className=''>                    
                                     <nav className='navlistParent gap-3'>
                                         <span className={(page === "board") ?"nav-list activeSec" : "nav-list inactiveSec"}  onClick={()=> setPage('board')}>Board</span>
                                         <span className={(page === "comments") ?"nav-list activeSec" : "nav-list inactiveSec"} onClick={()=> setPage('comments')}>Chat</span>
                                         <span className={(page === "participants") ?"nav-list activeSec" : "nav-list inactiveSec"}  onClick={()=> setPage('participants')}>Participants</span>
                                         <span className={(page === "review") ?"nav-list activeSec" : "nav-list inactiveSec"}  onClick={()=> setPage('review')}>Review</span>
                                       </nav>
               
                                       <div className='pageNav'>
                                           
                                           {page === "board" ? <>
                                                 <Board data={event}  setShowBoard={setShowBoard}/>
                                                   </> : null}
                                     
                                           {page === "comments" ? <>
                                                     <Comments data={event}/>
                                           </> : null}
               
                                           {page === "participants" ? <>
                                                 <Participants data={event}/>
                                                   </> : null}
                                            {page === "review" ?<>
                                            <Reviews id={event._id}/>
                                                </>: null}
                                                
                                           
                                           </div>
                                   </div>  
                                   
               
                  </div>
                
          </div>




          </div>

      </div>


      <Offcanvas show={showOffcanvas} onHide={offCanvasClose} placement={'end'}>
              <Offcanvas.Body>

              <Row>

                  <Col lg="1"/>
                  <Col lg="10" style={{paddingBottom:"120px"}}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{event?.title}</Offcanvas.Title>
              </Offcanvas.Header>

               {/* <form encType="multipart/form-data"> */}


            {displayImage ? 
                    <div style={{marginTop:"20px", textAlign:'center', height:"300px"}}>
                            {/* <img  src={URL.createObjectURL(displayImage)} style={{}}  className="img-fluid d-block eventImg" alt="..."/> */}
                            <img  src={displayImage} style={{}}  className="img-fluid d-block eventImg" alt="..."/>
                            <span onClick={() => setDisplayImage('')} className='btn-elrio btn-p'>Remove Image</span>
                      </div>
                      :
                   <div style={{marginTop:"20px", textAlign:'center'}}>
                      <div id="event-banner"  className={displayImage === "" ? '' : "d-none"}
                         style={{
                            backgroundColor: "rgb(92 92 92)",
                            padding: "40px 20px",
                            height: "300px",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer" 
                          }}
                      onClick={() => document.getElementById('customFile').click()}
                        >
                      <BsFillCloudUploadFill style={{ fontSize: "25px", color: "#fff" }} />
                      <p style={{ fontSize: "18px", color: "#fff", fontWeight: "bold" }}>Add your image</p>

                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        className="d-none"
                        id="customFile"
                        name="displayImage"
                        onChange={(e) => handleBgUpload(e)}
                        style={{ display: 'none' }} />
                    </div>
                        
                    </div>  
                    }



        <div className="row g-2 mt-4">
          
                  <div className="col">
                    <div className="form-floating mt-3">
                        <input placeholder="Event title" type="text" className="h-auto form-control" id="floatingInput" name="name"
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)}
                        />
                        <label for="floatingInput">Event Title</label>
                      </div>
                      </div>

                  <div className="col">
                    <div className="form-floating mt-3">
                                <select className="form-select" id="floatingSelect" aria-label="select category" name="category" onChange={(e)=> setCategoryId(e.target.value)}>
                                    <option value={selectedCat?._id}>
                                      {selectedCat.name}
                                    </option>
                                    {cat.map((c,index) => (
                                          <option key={index} value={c._id}>{c.name}</option>
                                        ))}
                                    </select>
                                  <label for="floatingSelect">Choose Event Category</label>
                              </div>

                      </div>

          </div>


                     <div className="mt-4 form-floating">

                        <textarea className="form-control" placeholder="let your participants know why they should attend" id="floatingTextarea2" name="description"  style={{height: "200px"}} value={desc} onChange={(e) => setDesc(e.target.value)}/>
                        <label for="floatingTextarea2">Event Description</label>

                       </div>


                    <div className="row g-2 mt-4">
                        <h5>Event Schedule</h5>
                        <p>Schedule date and time of your event.</p>
                          <div className="col">
                            <div className="form-floating mt-3">
                                <input placeholder="Start Date" type="date" className="h-auto form-control" id="floatingInput" name="startDate"
                                min={today}
                                value={startD} 
                                onChange={(e) => setStartD(e.target.value)}
                                />
                                <label for="floatingInput">Start Date</label>
                              </div>
                            </div>
                          <div className="col">
                            <div className="form-floating mt-3">
                                <input placeholder="End Date" type="date" className="h-auto form-control" id="floatingInput" name="endDate"
                                min={startD}
                                value={endD} 
                                onChange={(e) => setEndD(e.target.value)}
                                />
                                <label for="floatingInput">End Date</label>
                              </div>
                            </div>

                      </div>

                      <div className="row g-2 mt-4">

                      <div className="col">
                            <div className="form-floating mt-3">
                                <input placeholder="Start Time" type="time" className="h-auto form-control" id="floatingInput" name="startTime"
                                value={startTime} 
                                onChange={(e) => setStartTime(e.target.value)}
                                />
                                <label htmlFor="floatingInput">Start Time</label>
                              </div>
                            </div>

                            <div className="col">
                                  <div className="form-floating mt-3">
                                      <input placeholder="End Time" type="time" className="h-auto form-control" id="floatingInput" name="endtime"
                                      value={endTime} 
                                      onChange={(e) => setEndTime(e.target.value)}
                                      />
                                      <label htmlFor="floatingInput">End Time</label>
                                    </div>
                                  </div>

                        </div>

                        <div className="row g-2 mt-4">

                            <div className="col">
                              
                               <p>{isPassword === true ? "This event is passworded, will you like to change event password?" : "This event is not passworded, will you like to add a password?"}</p>

                              <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio1" onClick={()=> setPasswordState(true)}/>
                                  <label className="form-check-label" for="radio1">
                                  Yes
                                  </label>
                              </div>
                              {isPasswordTrue ?
                              <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio2" onClick={()=> setPasswordState(false)}/>
                                  <label className="form-check-label" for="radio2">
                                 No
                                  </label>
                             </div>
                             : null}


                              {isPasswordTrue ?

                                <div className="form-floating mt-3">
                                    <input placeholder="E.g 2000" type="password" className="h-auto form-control" id="floatingInput" name="password"
                                    value={password} onChange={(e) => setPassword(e.target.value)}/>
                                    <label for="floatingInput">Event Password</label>
                                  </div>

                                : null}


                               </div>

                          </div>
                            
                          {msg !== "" ?   
                          <span style={{color:"#D42323"}}>
                              <strong>{msg}</strong> 
                          </span> 
                                : null}



                        <div className="row g-2 mt-4">
                            <div className="col"></div>
                            <div className="col">  
                            {loading === false ?
                              <div className='d-flex justify-content-between'> 
                              
                        {/* {!event?.isPublished &&  */}
                                <button type="submit" className='btn btn-primary btn-elrio me-4' onClick={() => publishEvent()} disabled={loading}>Publish Event</button>
                          {/* }

                        {event?.isPublished &&  */}
                                <button type="submit" className='btn btn-primary btn-elrio ' onClick={() => editEvent()} disabled={loading}>Save to Draft</button>
                           {/* } */}
                                
                                </div>  : <>
                           

                                <ThreeDots 
                                    height="40" 
                                    width="40" 
                                    radius="10"
                                    color="#003399" 
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    visible={true}/>
                                    
                                </>}
                            </div>
                        </div>
{/* 
                    </form> */}
                                                            
                    </Col>  
                    <Col lg="1"/>
                </Row>
                
            </Offcanvas.Body>
        </Offcanvas>


    <Modal
      show={showBoard}
      onHide={() => setShowBoard(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <Row>
            
           <Col lg="1"/>

        <Col lg="10" style={{paddingBottom:"120px"}}>

    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
           Create Board
        </Modal.Title>
      </Modal.Header>

 
      <EventBoards data={data}/>
                  
      </Col>
           <Col lg="1"/>


        </Row>
      </Modal.Body>
    </Modal>


            
        </Layout>
    );
};

export default HostEventDetails;