import React, {useState} from 'react';
import Config from "../../../../Config.json";

const Report = (props) => {
    const [isLoading, setLoading] = useState(false)
    const [reason, setReason] = useState("");
  
    const {id} = props;


    const sendReport = async () => {
        if(reason === ""){
          alert("Select a reason from the list");
          return;
        }
    
        setLoading(true)
        
        return fetch(`${Config.SERVER_URL}/events/${id}/reviews`, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":  sessionStorage.getItem("token")
          },
            body: JSON.stringify({
              reason: reason
            })
        })
          .then((response) => response.json())
          .then((responseJson) => {
              console.log("reports", responseJson);
              if(responseJson.status === "success"){
                alert("Report sent successfully");
              }
              setLoading(false)
          })
          .catch((error) => {
            console.error(error);
            setLoading(false)
          });
        
      }



    return (
        
        <>

      <p>What do you wish to report about this host?</p>
      <p>Tick as many that applies: </p>
        <div className="issues">

               <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio1" onClick={()=> setReason('Profane language')}/>
                    <label className="form-check-label" for="radio1">
                    Profane language
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio2" onClick={()=> setReason('Selected scam')}/>
                    <label className="form-check-label" for="radio2">
                    Selected scam
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio3" onClick={()=> setReason('Cyber bullying')}/>
                    <label className="form-check-label" for="radio3">
                    Cyber bullying
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio4" onClick={()=> setReason('Discrimination of race.')}/>
                    <label className="form-check-label" for="radio4">
                    Discrimination of race.
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio5" onClick={()=> setReason('Discrimination of gender.')}/>
                    <label className="form-check-label" for="radio5">
                    Discrimination of gender.
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio6" onClick={()=> setReason('Discrimination of marital status.')}/>
                    <label className="form-check-label" for="radio6">
                    Discrimination of marital status.
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio7" onClick={()=> setReason('Discrimination of religion.')}/>
                    <label className="form-check-label" for="radio7">
                    Discrimination of religion.
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio8" onClick={()=> setReason('Information tend to compromise the safety or security of the public or public systems.')}/>
                    <label className="form-check-label" for="radio8">
                    Information tend to compromise the safety or security of the public or public systems.
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio9" onClick={()=> setReason('Conduct of encouragement of illegal activity.')}/>
                    <label className="form-check-label" for="radio9">
                    Conduct of encouragement of illegal activity.
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio10" onClick={()=> setReason('Inappropraite sexual content or links to inapproapriate sexual content.')}/>
                    <label className="form-check-label" for="radio10">
                    Inappropraite sexual content or links to inapproapriate sexual content.
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio11" onClick={()=> setReason('Contents not topically related to the purpose of the event.')}/>
                    <label className="form-check-label" for="radio11">
                    Contents not topically related to the purpose of the event.
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="radio12" onClick={()=> setReason('Disclosure of private and confidential information.')}/>
                    <label className="form-check-label" for="radio12">
                    Disclosure of private and confidential information.
                    </label>
                </div>
                <button type="submit" className='btn btn-primary btn-elrio float-end' onClick={sendReport} disabled={isLoading}>{isLoading === false ? 'Report' : 'Sending Report...'}</button>
      </div>
        </>
    );
};

export default Report;