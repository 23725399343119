import React, {useState} from 'react';
import { FiCheck } from "react-icons/fi";
import Config from "../../../../Config.json";
import { ThreeDots } from  'react-loader-spinner'

const Note = (props) => {
    const {data} = props;
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);


    const sendNote = async() => {

        if(content === "") return;
      
  
      setLoading(true)
      
      return fetch(`${Config.SERVER_URL}/notes`, 
      {
        method:"post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem("token")
        },
          body: JSON.stringify({
            title: data.title,
            content: content
          })
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("note", responseJson);
            setLoading(false);
            if(responseJson.status === "success"){
                alert("note saved");
            }
            setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          
          setLoading(false);
        });
      
    }







    return (
        <>

            
                     <div className="mb-3">{loading ?
                    
                      <ThreeDots 
                          height="40" 
                          width="40" 
                          radius="9"
                          color="#003399" 
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                          visible={true}/>
                      :

                  <div onClick={()=> sendNote()} style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"20px"}}>
                      <span className='goodIcon'>Save Note</span> 
                      {/* <FiCheck className='goodIcon'/> */}
                    </div>
                    
                        }
                           <textarea className="form-control" placeholder='Write note here' id="exampleFormControlTextarea1" style={{height:'50vh'}} value={content} 
                                      onChange={(e) => setContent(e.target.value)}></textarea>
                    
                     </div>
            
        </>
    );
};

export default Note;