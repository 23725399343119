import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Layout from '../Layout';
import EventSide from './components/EventSide';
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import {format} from 'date-fns';
import Config from "../../../Config.json";
import { ThreeDots } from  'react-loader-spinner';
import { BsArrowLeftCircleFill } from "react-icons/bs";
// import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { RWebShare } from "react-web-share";


const EventDetails = () => {
    const navigate = useNavigate();
    const {state} = useLocation();
    let { id } = useParams();
    const [data, setData] = useState([]);



    const [numbOfFollowers, setnumbOfFollowers] = useState(0);
    const [pageLoading, setPageLoading] = useState(true);
    const [host, setHost] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [showTwoMore, setShowTwoMore] = useState(false);

    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState("");
    const [password, setPassword] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleTwoClose = () => setShowTwoMore(false);
  const handleTwoShow = () => setShowTwoMore(true);
  const [btnDisabled, setBtnDisabled] = useState(false);


  const followers = async () => {


        return fetch(`${Config.SERVER_URL}/users/${data.userId}/followers`, {
            method: "get",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("token")
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
                if(responseJson.status === "success") {
                    setnumbOfFollowers(responseJson.data.followers);
                }
            })
            .catch((error) => {
            console.error(error);
            });

        
        }

   const getUser = async () => {
    setPageLoading(true)

     return fetch(`${Config.SERVER_URL}/users/${data.userId}`, {
        method: "get",
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "token": sessionStorage.getItem("token")
        },
    })
        .then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.status === "success") {
                setHost(responseJson.data.user);
            }
            setPageLoading(false)
        })
        .catch((error) => {
        console.error(error);
        setPageLoading(false);
        });


    }

    const regEvent = async() =>{
        setBtnDisabled(true);
        return fetch(`${Config.SERVER_URL}/events/${data._id}/register`, {
            method: "post",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("token")
            },
            body: JSON.stringify({
                firstName:firstName,
                lastName:lastName,
                email:email
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if(responseJson.status === "success") {
                    alert("Registration Successfull");
                    setBtnDisabled(false);
                    handleClose();
                }
                if(responseJson.status === "error"){
                    setBtnDisabled(false);
                    alert(responseJson.error);
                }
            })
            .catch((error) => {
            console.error(error);
            });
    }


  const myEvents = async () => {

    setPageLoading(true);
          
    return fetch(`${Config.SERVER_URL}/events/${id}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":   sessionStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
            console.log("Events", responseJson)
          if(responseJson.status === "success") {
              setData(responseJson.data.event);
            //    setEvent(responseJson.data.event);
            //    setToken(responseJson?.data?.event?.token);
            //    setChannel(responseJson?.data?.event?.channelName);
            //    setUID(responseJson?.data?.event?.uid);
                setPageLoading(false)
            }
        })
        .catch((error) => {
          console.error(error);
        });

   
    }

    useEffect(()=>{
        myEvents();
        followers();
        getUser();
    },[])

    const viewStream = () => {
        if(data.requirePassword) return handleTwoShow();
        navigate(`/user/Streaming/${data.title}/${data._id}`, { state: { data: data} });
    }

    const accessStream = () => {
        navigate(`/user/Streaming/${data.title}/${data._id}`, { state: { keys: data} });
    }



    const joinPassEvent = async() => {

        setBtnDisabled(true);

        
        return fetch(`${Config.SERVER_URL}/events/${data._id}/attenders`, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":  sessionStorage.getItem("token") 
          },
          body: JSON.stringify({
              password:password
          })
        })
          .then((response) => response.json())
          .then((responseJson) => {
              console.log(responseJson);
              if(responseJson.status === "success"){
                  navigate(`/user/Streaming/${data.title}/${data._id}`, { state: { keys: data} });
              }
              setMsg(responseJson.errors);
              setBtnDisabled(false)
          })
          .catch((error) => {
            console.error(error);
            setBtnDisabled(false)
          });
  
      }
  

      const displayTitle = data?.title || '';
      const limitedDisplayName = displayTitle.length > 25 ? `${displayTitle.slice(0, 25)}...` : displayTitle;
      

    return (
        
        <Layout>
                    
      <div className="container"> 
          <div className="row">

              <div className="col-lg-8">

          {pageLoading === true ?
                <>
 
                  <div style={{height:"50vh"}}>
                    <ThreeDots 
                        height="40" 
                        width="40" 
                        radius="10"
                        color="#003399" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                        visible={true}/>
                    </div>
                </> 
                : <>
            <div className="w-100 titleJoin">
                <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", marginBottom:"10px"}}>
                    <BsArrowLeftCircleFill onClick={()=> navigate(`/user/Events`)} className='arrowIcon' style={{color:"#003399", fontSize:"25px"}}/>
                   <h1 className='title'>{limitedDisplayName}</h1>
                </div>

                {data?.isLive ? 
                <button type="button" onClick={viewStream} className="botton btn btn-outline-primary btn-md">Join Event</button>
                                 :null
                            }
            </div>

            <div className='eventABg'>
                <img src={data?.displayImage} className="img-fluid block eventImg" alt="..."/>
            </div>
            
            <div className='row'>

                <div className="col-lg-11">
                    
                    <div className='event-details'>
                        
                    <div style={{display:"flex", flexWrap:"wrap", width:"100%"}}>

                    <p>{data?.description
                            ? showMore
                            ? data.description
                            : `${data.description.substring(0, 120)}`
                            : 'Loading...'}
                        {data?.description ? data?.description.length < 220 ? null :<>
                                    <span onClick={() => setShowMore(!showMore)} className="btn-a">{showMore ? "Show less" : "... Show more"}</span>
                            </> : null} </p>

                    </div>

                    <div className="BadagryDate gap-4">
                        <div style={{display:"flex"}}>
                            <i className="fa-solid fa-calendar-days" style={{fontSize:"20px"}}></i> 
                                <p style={{marginLeft:"5px", fontSize:"20px"}}> 
                                  {data.startDate ? format(new Date(data?.startDate), 'dd MMMM yy') : null}
                                </p> 
                        </div>
                        <div style={{display:"flex"}}>
                            <i className="fa-solid fa-clock" style={{fontSize:"20px"}}></i>
                            <p style={{marginLeft:"5px", fontSize:"20px"}}>{data?.startTime}</p>
                        </div>
                    <RWebShare
                    data={{
                        text: `Use the link to join this event ${data?.title}` ,
                        url: `${Config.WEB_URL}/Event/${data?._id}`,
                        title: `${data?.title}`,
                    }}
                    onClick={() => console.log("shared successfully!")}>
                        <div style={{display:"flex", cursor: "pointer"}}>
                            <i className="fa-solid fa-share"  style={{fontSize:"20px"}}></i>
                            <p className='dateTime' style={{fontSize:"20px"}}>Share this event</p>
                        </div>
                        </RWebShare>
                    </div>

                    <div className='engagemet'>
                        <h4>Events Engagements</h4>
                                
                            <div className='box-parent'>
                                <div className="box engBox">
                                        <i className="fa-solid fa-heart"></i>
                                        <div>
                                        <p >{data?.likesCount}</p>
                                        <p className="view">Likes</p>
                                        </div>
                                </div>
                                
                                <div className="box engBox">
                                        <i className="fa-solid fa-eye"></i>
                                        <div>
                                            <p>{numbOfFollowers.length}</p>
                                            <p className="view">Followers</p>
                                        </div>
                                </div>
                                
                                <div className="box engBox">
                                            <i className="fa-solid fa-comments"></i>
                                        <div>
                                            <p>{data?.reviewsCount}</p>
                                            <p className="view">Review</p>
                                        </div>
                                </div>

                            </div>
                        </div>

                        <hr style={{color:'#003399'}}/>

                        <div className='bottom'>
                        {(format(new Date(), 'yyyy-MM-dd') > data.startDate ? format(new Date(data?.endDate), 'yyyy-MM-dd'): null ) ? 
                            <p>Event Closed</p>
                            : null}
                        
                        {data?.isLive &&
                            <div className='live-span' style={{}}>
                                    <span>live</span>
                                </div>
                            }


                 {(format(new Date(), 'yyyy-MM-dd') < data?.startDate ? format(new Date(data?.startDate), 'yyyy-MM-dd') : null ) ? 
                    <button type="button" className=" botton btn btn-primary btn-elrio btn-md" onClick={handleShow}>Register for Event</button>: null}
                        

                {(format(new Date(), 'yyyy-MM-dd') > data?.startDate ? format(new Date(data?.endDate), 'yyyy-MM-dd') : null ) ? 
                <button type="button" onClick={accessStream} className="botton btn btn-outline-primary btn-md">Access Event</button>
                                :null
                            }
 
                        </div>
                        
                    </div>

                </div>
            </div>

                     </>
                   }


              </div>
              
      {!pageLoading &&
          <div className="col-lg-4">
                        <div className="col-12 ">
                        <h1 className='title text-white'>Following</h1>
                        </div>
                <div className="sideMenu">
                        <EventSide catId={data?.categoryId}/>
                    </div>
           </div>
        }




          </div>

      </div>


      <Modal show={showTwoMore} onHide={handleTwoClose}>
        
        <Modal.Body>
            
        <div className="row">
                  <div className="col-1"></div>

                  <div className="col-10">
                            <div className="row py-5">
                                <div className="col-12 text-center">

                                      {/* <p>The Believers’ Tabernacle </p> */}
                                      <h4>JOIN EVENT</h4>

                                </div>
                                <div className="col-12 text-center">

                                      <h5>This event requires password</h5>


                          {msg !== "" ?   
                          <span style={{color:"#D42323"}}>
                          <strong>{msg}</strong> <br/>
                          </span> 
                                : null}

                                </div>


                                    <div className="col-12 mb-2 mb-4">
                                        <div className="form-floating mt-3 mb-4">
                                            <input placeholder="Event Password" type="password" className="h-auto form-control" id="floatingInput" name="password"
                                              value={password} 
                                              onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <label for="floatingInput">Event password</label>
                                        </div>
                                    </div> 

                                <div className="col-12">  
                                   { btnDisabled !== true ? <>
                                   
                                        <button onClick={joinPassEvent} type="button" className="w-100 botton btn btn-primary btn-elrio btn-md"> Join Event</button>
                                               
                                           </> :
                                                <ThreeDots 
                                                height="40" 
                                                width="40" 
                                                radius="10"
                                                    color="#fff" 
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#003399"}}
                                                    visible={true}/>
                                            }
                                      
                                    </div>
                                <div className="col-12 mt-2" style={{display:"flex", justifyContent:"center", cursor:"pointer", alignItems:"center"}}>
                                      <span className="w-100 text-center" onClick={handleTwoClose}>Cancel</span>
                                    </div>




                            </div>

                       </div>

                  <div className="col-1"></div>

                </div>
            
            
        </Modal.Body>
      </Modal>
        



      <Modal show={show} onHide={handleClose}>
        
        <Modal.Body>
            
        <div className="row">
                  <div className="col-1"></div>

                  <div className="col-10">
                            <div className="row py-5">
                                <div className="col-12 text-center">

                                      {/* <p>The Believers’ Tabernacle </p> */}
                                      <h4>{data?.title}</h4>
                                      {/* <p className='time'>{data.startDate ? format(new Date(data?.startDate), 'dd-MMM-yyyy')}   {data?.startTime} </p> */}

                                </div>
                                <div className="col-12 text-center">

                                      <h5>Event Registration</h5>

                                </div>
     
                                <div className="col-12">
                                    <div className="form-floating mt-3">
                                        <input placeholder="First Name" type="text" className="h-auto form-control" id="floatingInput" name="firstName"
                                          value={firstName} 
                                          onChange={(e) => setfirstName(e.target.value)}
                                        />
                                        <label for="floatingInput">First Name</label>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="form-floating mt-3">
                                        <input placeholder="Last Name" type="text" className="h-auto form-control" id="floatingInput" name="lastName"
                                          value={lastName} 
                                          onChange={(e) => setlastName(e.target.value)}
                                        />
                                        <label for="floatingInput">Last Name</label>
                                    </div>
                                </div>



                                <div className="col-12"> 
                                <div className="mt-3 form-floating">
                                    <input placeholder="Email" type="email" className="h-auto form-control" id="floatingInput" name="email"
                                              value={email} 
                                              onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label for="floatingInput">Email Address</label>
                                    </div>
                                </div>



                                <div className="col-12">

                                      <p className='time mt-4'>The information you provide when registering will be shared with the host in accordance with ElRoi <a href=''>Terms and Privacy Policy</a>.</p>

                                </div>
                                <div className="col-12">  
                                   { btnDisabled !== true ?
                                            <button type="button" className="w-100 botton btn btn-primary btn-elrio btn-md" onClick={regEvent}> Confirm Registration</button>
                                            :
                                                <ThreeDots 
                                                    height="40" 
                                                    width="40" 
                                                    radius="10"
                                                    color="#fff" 
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#003399"}}
                                                    visible={true}/>
                                            }
                                      
                                    </div>
                                <div className="col-12 mt-2" style={{display:"flex", justifyContent:"center", cursor:"pointer", alignItems:"center"}}>
                                      <span className="w-100 text-center" onClick={handleClose}>Cancel</span>
                                    </div>




                            </div>

                       </div>

                  <div className="col-1"></div>

                </div>
            
            
        </Modal.Body>
      </Modal>
            
            
        </Layout>
    );
};

export default EventDetails;