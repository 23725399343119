import React from 'react';
import Sidebar from './components/Sidebar';
import GoToTop from '../GoToTop';

const Layout = ({children}) => {


    
    return (
        
        <div className='contentParent'>

            <Sidebar/>

            <div className='content'>

                            
                 {children}

             </div>

          {/* <GoToTop/> */}

        </div>

             
            
    );
};

export default Layout;