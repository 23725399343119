import React, {useState,} from 'react';
import { useNavigate } from "react-router-dom";
import {format} from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import { ThreeDots } from  'react-loader-spinner';
import Config from "../../../Config.json";



const EventCard = (props) => {
    const navigate = useNavigate();

    const {data} = props;

    const userId = sessionStorage.getItem('userId');
            

    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState("");
    const [password, setPassword] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const regEvent = async() =>{
    setBtnDisabled(true);
    return fetch(`${Config.SERVER_URL}/events/${data._id}/register`, {
        method: "post",
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "token": sessionStorage.getItem("token")
        },
        body: JSON.stringify({
            firstName:firstName,
            lastName:lastName,
            email:email
        })
    })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            if(responseJson.status === "success") {
                alert("Registration Successfull");
                setBtnDisabled(false);
                handleClose();
            }
            if(responseJson.status === "error"){
                setBtnDisabled(false);
                alert(responseJson.error);
            }
        })
        .catch((error) => {
        console.error(error);
        });
   }

    const eventDetails = (item) => {
      // console.log(item.userId, userId)
      // return
       if(item.userId === userId){
          navigate(`/user/Details/${item.title}/${item._id}`, { state: { data: item} });
          return
       }
        navigate(`/Event/${item._id}`);
     }
     
    return (<>
    


    {data && data.length > 0
          ? data.map((data, index) => {
            return <>

                      
                <div key={index} className="col-lg-4 col-md-6">
                  <div onClick={()=> eventDetails(data)} className="card mt-3">
                        <img src={data?.displayImage} draggable="false"   className="card-img-top mh-50 " alt="..."/>
                    <div className="card-body p-4">
                          <h5 className='fw-bold'>{data?.title}</h5>
                          {/* <Editor editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(data?.description)))} readOnly={true}/> */}
                          <p>{data?.description}</p>
                        <div className="BadagryDate gap-4">
                                <div className="d-flex gap-4 w-100">  
                                  <span className='datTime'>
                                      <i className="fas fa-calendar-alt"></i> {" "}
                                      {format(new Date(data?.startDate), 'dd MMMM yy')}
                                    </span>
                                  <span className='datTime'> 
                                      <i className="fas fa-clock"></i> {" "}
                                      {data?.startTime}
                                    </span>
                                </div>
                        {data?.isLive &&
                            <div className='live-span' style={{position:"absolute", left:'5%', top:'5%'}}>
                                  <span>live</span>
                                </div>
                          }
                          
                       <div>
                                        {/* <p>{data?.location}</p> */}
                                </div>
                        </div>
                    </div>
                  </div>
                </div>

                                         </> })
                                : <div style={{marginTop:"10%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"center", width:"100%", alignItems:'center'}}>
                                        <img src='/images/notFound/eventPub.png' className={`${'img-fluid'} ${"notFound"}`}/>
                                          <h4 style={{fontSize:"20px", fontWeight:'bold'}}>No Event Found</h4>
                                      </div>}

                

      <Modal show={show} onHide={handleClose}>
        
        <Modal.Body>
            
        <div className="row">
                  <div className="col-1"></div>

                  <div className="col-10">
                            <div className="row py-5">
                                <div className="col-12 text-center">

                                      {/* <p>The Believers’ Tabernacle </p> */}
                                      <h4>{data?.title}</h4>
                                      {/* <p className='time'>{format(new Date(data?.startDate), 'dd-MMM-yyyy')}   {data?.startTime} </p> */}

                                </div>
                                <div className="col-12 text-center">

                                      <h5>Event Registration</h5>

                                </div>
     
                                <div className="col-12">
                                    <div className="form-floating mt-3">
                                        <input placeholder="First Name" type="text" className="h-auto form-control" id="floatingInput" name="firstName"
                                          value={firstName} 
                                          onChange={(e) => setfirstName(e.target.value)}
                                        />
                                        <label for="floatingInput">First Name</label>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="form-floating mt-3">
                                        <input placeholder="Last Name" type="text" className="h-auto form-control" id="floatingInput" name="lastName"
                                          value={lastName} 
                                          onChange={(e) => setlastName(e.target.value)}
                                        />
                                        <label for="floatingInput">Last Name</label>
                                    </div>
                                </div>



                                <div className="col-12"> 
                                <div className="mt-3 form-floating">
                                    <input placeholder="Email" type="email" className="h-auto form-control" id="floatingInput" name="email"
                                              value={email} 
                                              onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label for="floatingInput">Email Address</label>
                                    </div>
                                </div>



                                <div className="col-12">

                                      <p className='time mt-4'>The information you provide when registering will be shared with the host in accordance with ElRoi <a href=''>Terms and Privacy Policy</a>.</p>

                                </div>
                                <div className="col-12">  
                                   { btnDisabled !== true ?
                                            <button type="button" className="w-100 botton btn btn-primary btn-elrio btn-md" onClick={regEvent}> Confirm Registration</button>
                                            :
                                                <ThreeDots 
                                                    height="40" 
                                                    width="40" 
                                                    radius="10"
                                                    color="#fff" 
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#003399"}}
                                                    visible={true}/>
                                            }
                                      
                                    </div>
                                <div className="col-12 mt-2" style={{display:"flex", justifyContent:"center", cursor:"pointer", alignItems:"center"}}>
                                      <span className="w-100 text-center" onClick={handleClose}>Cancel</span>
                                    </div>




                            </div>

                       </div>

                  <div className="col-1"></div>

                </div>
            
            
        </Modal.Body>
      </Modal>
      


       
   </> );
};

export default EventCard;