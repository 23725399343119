import React, {useState, useCallback} from "react";
import styles from "./Sign.module.css"
import Axios from "axios";
import Config from "../../Config.json"
import { useNavigate } from 'react-router-dom';

import {
    LoginSocialGoogle,
    LoginSocialFacebook,
  } from 'reactjs-social-login';
  import { FacebookLoginButton } from "react-social-login-buttons";
const SocialAuth = () => {
    
    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('')
    const [email, setemail] = useState('')
    const [phoneNumber, setphoneNumber] = useState('')
    const [profilePhotoUrl, setprofilePhotoUrl] = useState('')
    const [errorMsg, setErrorMsg] = useState("");
    
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState();

  const onLoginStart = useCallback(() => {
    console.log('login start');
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider('');
    alert('logout success');
  }, []);

  const onLogout = useCallback(() => {}, []);

    let navigate = useNavigate();

    
    const loginUser = (data) => {
                
        setErrorMsg('');
        let details={
            email:data.email, 
            password:data.accessToken
         };
        setTimeout(() => {
        
        return Axios.post(`${Config.SERVER_URL}/auth/login`, details)
            .then(async (res) =>{
            console.log(res.data);
            if(res.data){
                const {data} = await res.data;
                sessionStorage.setItem("token", data.token);
                sessionStorage.setItem("firstName",`${data.user.firstName}`)
                sessionStorage.setItem("lastName",`${data.user.lastName}`)
                sessionStorage.setItem("userId",`${data.user._id}`)
                navigate("/user/Events");
            }
            })
            .catch(err =>{
                console.log(err.message);
                setErrorMsg("Invalid Email or Password.");
            })
        }, 2000);
      };
    
    const registerUser = (data) => {


        let userDetails ={
            "profilePhotoUrl": data.picture.data.url,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "email": data.email,
            "password": data.accessToken,
            "phoneNumber":data.phoneNumber
          }

            console.log(userDetails);
  
                            
                return fetch(`${Config.SERVER_URL}/auth/signup`, {
                    method: "post",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                    },
                      body: JSON.stringify({
                        firstName:data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        password: data.password,
                        phoneNumber: data.phoneNumber
                      })
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                    console.log(responseJson);
                    if(responseJson.status ==="success"){
                                sessionStorage.setItem("token", responseJson.data.token);
                                sessionStorage.setItem("profilePhotoUrl",`${data.picture.data.url}`)
                                sessionStorage.setItem("firstName",`${responseJson.data.user.firstName}`)
                                sessionStorage.setItem("lastName",`${responseJson.data.user.lastName}`)
                                sessionStorage.setItem("userId",`${responseJson.data.user._id}`)
                                navigate("/Preference");
                    }
                    if(responseJson.status ==="error"){
                        console.error(responseJson.error);
                        setErrorMsg(responseJson.error)
                    }
                    })
                    .catch((error) => {
                    console.error(error);
                    setErrorMsg("Unable to Create Account. Try again later.")
                    });
    }

    //google signin setup
    const handleGoogleSignupSuccess = (userData) => {
      // Handle successful Google signup here
      console.log('Google signup success:', userData);
    };
  
    const handleGoogleSignupFailure = (error) => {
      // Handle Google signup failure here
      console.error('Google signup error:', error);
    };

    const REDIRECT_URI = 'http://localhost:3000/user/events'




    return(

        <>
            
   

            <div className={`${"form-group"} ${styles.socialMedia}`}>
                    
                    <div className={styles.facebook}>
                    {/* <LoginSocialFacebook 
                       appId="707416340736814"
                       onResolve={(response)=> {
                         loginUser(response.data)
                         console.log(response.data)
                       }}
                       onReject={(error)=> {
                         console.log(error)
                       }}> */}
                 <LoginSocialFacebook
                        appId={'707416340736814'}
                        fieldsProfile={
                            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                        }
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        redirect_uri={REDIRECT_URI}
                        onResolve={(response) => {
                            setProvider(response.provider);
                            setProfile(response.data);
                            console.log(response.data);
                        }}
                        onReject={err => {
                            console.log(err);
                        }}>
                        {/* <FacebookLoginButton/> */}
                      <button type="submit" className={`${styles.lineOne} ${"btn"}`}>
                            <img src="images/icons/facebook.png" className={`${styles.mx-4} ${styles.facebookspng}`} alt="google"/>
                            <span className={styles.stress}>Facebook</span>    
                        </button>
                    </LoginSocialFacebook>
                    </div>

                    <div className={styles.google}>
                         
                        <LoginSocialGoogle
                          client_id="1024164678686-hfdkj2ev2j1d6jd0vt2ullbpmv5k60su.apps.googleusercontent.com"
                          onLoginSuccess={handleGoogleSignupSuccess}
                          onLoginFailure={handleGoogleSignupFailure}
                          redirect_uri="https://www.elroievent.com/google-authentication"
                          >

                          <button type="submit" className={`${styles.lineOne} ${"btn"}`}>
                            <img src="images/icons/google.png" className={`${styles.mx-4} ${styles.facebookspng}`} alt="google"/>
                            <span className={styles.stress}>Google</span>
                          </button>
                        </LoginSocialGoogle>
                    </div>

                </div>

                                

           
                                
        
        </>

    );
};

export default SocialAuth;
