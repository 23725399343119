import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Layout from './Layout';
import { useNavigate } from "react-router-dom";
import EventCard from "./components/EventCard";
import Config from "../../Config.json";
import { ThreeDots } from  'react-loader-spinner'


// import "./components/styles.css";

const Feeds = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState('Top');

  const [show, setShow] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [pageLoading, setPageLoading] = useState(true);
  const [events, setEvents] = useState([]);
  // const [status, setStatus] = useState('');
  // const categoryIds =["610cc75e3d0bf006207402d7", "610cc75e3d0bf006207402d7"];


  const myEvents = async () => {
      setPageLoading(true);

    //  return console.log([`${categoryIds}`])
            
      return fetch(`${Config.SERVER_URL}/events/top/10`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "token":   sessionStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log("responseJson", responseJson)
            if(responseJson.status === "success") {
                 setEvents(responseJson.data.events);
              }
              setPageLoading(false)
          })
          .catch((error) => {
            console.error(error);
            setPageLoading(false)
          });

     
  }

  useEffect(() => {
      myEvents();
  },[])

  const newPage = (status) => {
    if(status === "Top"){
      console.log("top"); 
      setPage(status); 
      myEvents();
      return
    }
    if(status === "past"){
      console.log("past"); 
      setPage(status); 
      prevEvents();
      return
    }
    if(status === "live"){
      console.log("live"); 
      setPage(status); 
      liveEvents();
      return
    }
    if(status === "registered"){
      console.log("registered"); 
      setPage(status);
      registeredEvents();
      return
    }
    setPage(status); 
    upcomingEvents(status)
  }

  const prevEvents = async() => {
     setPageLoading(true);

    return fetch(`${Config.SERVER_URL}/events/attended/10`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":   sessionStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if(responseJson.status === "success") {
               setEvents(responseJson.data.events);
            }
            setPageLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false)
        });

  }


  const upcomingEvents = async (status) => {
    setPageLoading(true);
    console.log(status);

    return fetch(`${Config.SERVER_URL}/events/future/5`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":   sessionStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson)
          if(responseJson.status === "success") {
               setEvents(responseJson.data.events);
            }
            setPageLoading(false)
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false)
        });
   }
 

  const searchEvents = async() => {

    return fetch(`${Config.SERVER_URL}/events?searchKeyword=${searchKeyword}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "token":  sessionStorage.getItem("token") 
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson)
      if(responseJson.status === "success") {
           setEvents(responseJson.data.events);
        }
        setPageLoading(false)
    })
    .catch((error) => {
      console.error(error);
      setPageLoading(false)
    });


  }

  const regEvents = async () => {
    setPageLoading(true);
    setPage("registered"); 
    return fetch(`${Config.SERVER_URL}/events/register/?count=5`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":   sessionStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson)
          if(responseJson.status === "success") {
               setEvents(responseJson.data.events);
            }
            setPageLoading(false)
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false)
        });
  }
  
  const liveEvents = async () => {
    setPageLoading(true);
    setPage("live"); 
    return fetch(`${Config.SERVER_URL}/events/live/10`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":   sessionStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson)
          if(responseJson.status === "success") {
              setEvents(responseJson.data.events);
            }
            setPageLoading(false)
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false)
        });
  }

  const registeredEvents = async () => {
    setPageLoading(true);
    setPage("registered"); 
    return fetch(`${Config.SERVER_URL}/events/register/?count=5`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":   sessionStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson)
          if(responseJson.status === "success") {
              setEvents(responseJson.data.events);
            }
            setPageLoading(false)
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false)
        });
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

 



    return (
        
           <Layout>
            
            
      <div className="container"> 
          <div className="row">
              <div className="col-lg-5">
                  <h1 className='title'>Events</h1>
              </div>
              <div className="col-lg-6">
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                  <input type="search" className="search" placeholder="Search for event" 
                  value={searchKeyword}  
                  onChange={(e) => setSearchKeyword(e.target.value)}/>
                  <span onClick={searchEvents} className='searchBtn btn btn-elrio text-white'>Search</span>
                </div>
              </div>
          </div>

      </div>

      <div className="col-lg-12">
        <nav className='navlistParent gap-4'>
            <span className={page === "Top" ? "nav-list activeList" : "nav-list" }  onClick={()=> newPage('Top')} aria-current="page">Top events</span>
            <span className={page === "future" ? "nav-list activeList" : "nav-list" }  onClick={()=> newPage("future")}>Upcoming events</span>
            <span className={page === "live" ? "nav-list activeList" : "nav-list" }  onClick={()=> newPage('live')}>Live events</span>
            <span className={page === "past" ? "nav-list activeList" : "nav-list" }  onClick={()=> newPage('past')}>Previously Attended Events</span>
            <span className={page === "registered" ? "nav-list activeList" : "nav-list" }  onClick={()=> regEvents()}>Registered Events</span>
        </nav>

          {pageLoading === true ?
            <>
              <div style={{height:"50vh"}}>
                <ThreeDots 
                    height="40" 
                    width="40" 
                    radius="10"
                    color="#003399" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}
                    visible={true}/>
                </div>
            </> 
            : <>
                    
                    <div className="row">

                            <EventCard  data={events}/>

                    </div>
                  </>
                }
                
        
      </div>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Event Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
        <div className="row">
                  <div className="col-1"></div>

                  <div className="col-10">
                            <div className="row">
     
                                <div className="col-12">
                                    <div className="form-floating mt-3">
                                        <input placeholder="Meeting ID" type="text" className="h-auto form-control" id="floatingInput" name="Meeting ID"
                                        //   value={name} 
                                        //   onChange={(e) => setName(e.target.value)}
                                        />
                                        <label for="floatingInput">Meeting ID</label>
                                    </div>
                                </div>



                                <div className="col-12"> 
                                <div className="mt-3 form-floating">
                                    <input placeholder="Confirm Password" type="password" className="h-auto form-control" id="floatingInput" name="confirmPassword"
                                            //   value={name} 
                                            //   onChange={(e) => setName(e.target.value)}
                                            />
                                            <label for="floatingInput">Meeting Password</label>
                                    </div>
                                </div>




                            </div>

                       </div>

                  <div className="col-1"></div>

                </div>
            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Note
          </Button>
        </Modal.Footer>
      </Modal>
            
            


           </Layout>

    );
};

export default Feeds;