
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";


import Home from './Pages/Home';
import About from './Pages/About';
import Solutions from './Pages/Solutions';
import Pricing from './Pages/Pricing';
import FAQs from './Pages/FAQs';
import LogIn from './Pages/Auth/LogIn'
import SignUp from './Pages/Auth/SignUp'
import GoogleSign from './Pages/Auth/GoogleSign'
import Preference from './Pages/User/Preference'
import Feeds from './Pages/User/Feeds';
import Analytics from './Pages/User/Analytics';
import MyEvent from './Pages/User/MyEvent';
import Profile from './Pages/User/Profile';
import EventDetails from './Pages/User/Events/EventDetails';
import Details from './Pages/User/Events/Details';
import Streaming from './Pages/User/Events/Streaming'
import WaitList from './Pages/WaitList';
import CreateEvent from "./Pages/User/Host/CreateEvent"
import HostEventDetails from './Pages/User/Host/HostEventDetails';
import OpenEvent from "./Pages/User/Events/OpenEvent";
import { ForgotPassword } from './Pages/Auth/ForgotPassword';
import { ResetPassword } from './Pages/Auth/ResetPassword';
import { LiveStream } from './Pages/User/Host/streaming/LiveStream';


function App() {
  return (

    <BrowserRouter>
       <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/Wait-List" element={<WaitList />}/>
          <Route path="/About-us" element={<About />} exact/>
          <Route path="/Solutions" element={<Solutions />} exact/>
          <Route path="/Pricing" element={<Pricing />} exact/>
          <Route path="/FAQs" element={<FAQs />} exact/>
          <Route path="/LogIn" element={<LogIn />} exact/>
          <Route path="/google-authentication" element={<GoogleSign />} exact/>
          <Route path="/forgot-password" element={<ForgotPassword/>} exact/>
          <Route path="/reset-password" element={<ResetPassword/>} exact/>
          <Route path="/SignUp" element={<SignUp />} exact/>
          <Route path="/Preference" element={<Preference />} exact/>
          <Route path="/user/Events" element={<Feeds />} exact/>
          <Route path="/user/Create-Event" element={<CreateEvent />} exact/>
          <Route path="/user/Analytics" element={<Analytics />} exact/>
          <Route path="/user/My-Event" element={<MyEvent />} exact/>
          <Route path="/user/Details/:name/:id" element={<HostEventDetails />} exact/>
          <Route path="/user/Event/:name/:id" element={<OpenEvent />} exact/>
          <Route path="/user/Profile" element={<Profile />} exact/>
          <Route path="/Event/:id" element={<EventDetails />} exact/>
          <Route path="/user/Join-Event/:id" element={<Details />} exact/>
          <Route path="/user/Streaming/:name/:id" element={<Streaming />} exact/>
          <Route path="/Streaming/:eventId" element={<LiveStream/>} exact/>
        </Routes>
    </BrowserRouter>
    
  );
}

export default App;
