import React, {useState} from 'react';
import Emoji from "./Emoji";
import Config from "../../../../Config.json";

const Review = (props) => {
    const [msg, setMsg] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");
  
    const {data} = props;
  
    const sendReview = async () => {
      if(rating === 0 || comment === ""){
        setMsg("Select a Rating and drop your thought");
        return;
      }
  
      setLoading(true)
      
      return fetch(`${Config.SERVER_URL}/events/${data._id}/reviews`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "token":  sessionStorage.getItem("token")
        },
          body: JSON.stringify({
            title: data.title,
            comment: comment,
            rating: rating
          })
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("Review", responseJson);
            if(responseJson.status === "success"){
              setMsg("Review sent successfully");
              alert("Review sent successfully");
            }
            setComment("");
            setRating(0);
            setLoading(false)
        })
        .catch((error) => {
          console.error(error);
          setLoading(false)
        });
      
    }
  
    return (
        <>
                <p>{msg === "" ? "What do you think about this event?" : msg}</p>
                <span style={{fontSize:"12px", color:"#f59090", position:"relative", top:-20}}>Only event host will see your review </span>
                    <div className="reaction  mt-4">
                        <img src={Emoji.angry} className={rating === 1 ? "border border-primary border-4 rounded-circle" : null }alt="emoji" onClick={() => setRating(1)}/>
                        <img src={Emoji.sad} alt="emoji" className={rating === 2 ? "border border-primary border-4 rounded-circle" : null }  onClick={() => setRating(2)}/>
                        <img src={Emoji.confused} alt="emoji"  className={rating === 3 ? "border border-primary border-4 rounded-circle" : null }  onClick={() => setRating(3)}/>
                        <img src={Emoji.happy} alt="emoji" className={rating === 4 ? "border border-primary border-4 rounded-circle" : null }  onClick={() => setRating(4)}/>
                        <img src={Emoji.love} alt="emoji" className={rating === 5 ? "border border-primary border-4 rounded-circle" : null }  onClick={() => setRating(5)}/>
                    </div>

                 <div className="mt-4">
                           <textarea className="form-control" placeholder="your thoughts..."  value={comment} onChange={(e) => setComment(e.target.value)} style={{height:'20vh'}}></textarea>
                           <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"30px"}}>
                           </div>
                    </div>

                  <button type="submit" className='btn btn-primary btn-elrio float-end' onClick={() => sendReview()} disabled={isLoading}>{isLoading === false ? 'Send' : 'Sending Review...'}</button>
            
        </>
    );
};

export default Review;